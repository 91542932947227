/*
버튼 누르면 select 된거  model result hit ratio 제공

*/

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Spinner from 'react-bootstrap/Spinner'
import {API_URL_MODELCOMP} from './config'
import './App.css';
import axios from 'axios';

import ChInput from './ChInput';
import ChButton from './ChButton';
import Select from 'react-select';
import Chart_StockLine from './Chart_StockLine';
import {columns} from './tickerModelsTableColumns';
import {columns2} from './tickerModelsTableColumns2';
import { modelOptions } from './modelList2';
import { hitratioOptions } from './hitratioList';
import { confidenceOptions } from './confidenceList';
import { periodOptions } from './periodList';
import Chart_Column_bar2 from './Chart_Column_bar2';
import Chart_Scatter_Jitter from './Chart_Scatter_Jitter';
// import Chart_Donut from './Chart_Donut';
// import Chart_Donut2 from './Chart_Donut2';
// import Chart_Column_bar from './Chart_Column_bar';
// import Chart_Horizon_bar_sort from './Chart_Horizon_bar_sort';
// import Chart_Horizon_bar_multi from './Chart_Horizon_bar_multi';
// import Chart_Scatter from './Chart_Scatter';

import BootstrapTable from 'react-bootstrap-table-next';

import Footer from './Footer';

const defaultSorted = [{
    dataField: 'name',
    order: 'desc'
  }];

class ModelsComp extends React.Component {
    constructor(props) {
        super(props);
        // var options = {
        //     chart: {
        //         type: "pie"
        //     },
        //     series: [
        //         {
        //             name : "sector",
        //             data : this.state.data
        //         }
        //     ]
        // },

        this.state = {
            is_updated: false,
            loading: 0, // 0 : not started, 1: loading, 2: done
            data: null,
            valid_ticker: true,
            selectedOptions:null ,
            selectedModel: "ARIMA",
            selectedPeriod: "mth",
            selectedHitratio: "0.7",
            selectedConfidence: "0.8",
            predData: [
                {'ticker': "BTC", 'prev_price': 1000, 'pred_price': 1100, 'ret': '10%'},
                {'ticker': "ETH", 'prev_price': 1000, 'pred_price': 1100, 'ret': '10%'},
            ],
            categories: null,
            scatter_jitter_data: null,
            ticker_names: null,
            period: 'H',
            num_of_period: 1,
            prev_date : null,
            pred_date : null,
            model_names: null,
            is_505_error: false
        };

        console.log("this.state.is_updated: ", this.state.is_updated);

        this.handleClick = this.handleClick.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.handleInputStartDate = this.handleInputStartDate.bind(this);
        this.handleInputEndDate = this.handleInputEndDate.bind(this);
        // this.handleSelect = this.handleSelect.bind(this);
        // this.handleStateChange = this.handleStateChange.bind(this);

    }
    

    handleClick(e) {
        e.preventDefault(); 
        // if (!this.state.valid_ticker || !this.state.valid_enddate || !this.state.valid_startdate) {
        //     return;
        // }
        // console.log(this.state.selectedOption);
        this.setState( 
            prevState => ( {
                loading: 1,
            })
        );
        // axios.get function will lose the context of 'this'. So, copy it into
        // var self == this. then use self when update the state lilke 
        // "self.setState( {  .... } );". this properly point this component.
        //axios.post("http://100.1.58.77:5000/api/strategy1", {
        axios.post(`${API_URL_MODELCOMP}`, {
                models: this.state.selectedModel,
                period: this.state.selectedPeriod,
                confidence: this.state.selectedConfidence,
                hitratio: this.state.selectedHitratio,

            })
            //.then(function(response) {
            .then( (response) => {
                this.setState (
                    prevState => ( {
                        loading: 2, // done. received response
                        //groupname: response.data[0]['name'],
                        //data: response.data
                        
                        lookback: response.data['lookback'],
                        ps  : response.data['ps'],
                        qs  : response.data['qs'],
                        total: response.data['total'],
                        categories: response.data['categories'],
                        scatter_jitter_data: response.data['scatter_jitter_json'],
                        is_505_error: false

                    }),
                    () => console.log(this.state)
                );
                console.log("inside call back");
            })
            .catch(function (error) {
                // console.log(error);
                console.log("EEEEEEEERRRRRRORRR");
                this.setState (
                    prevState => ( {
                        loading: 2,
                        is_505_error: true
                    }),
                    () => console.log(this.state.is_505_error)
                );
            })
            .then(function() {
                console.log('Fetch done');
            });
    }

    handleInput(e) {
        let value = e.target.value;
        this.setState (
            prevState => ( {
                ticker_r: value,
                valid_ticker: value.trim().split(' ').length > 0
            }),
            () => console.log(this.state.ticker_r)
        );
        //e.preventDefault();
    }

    handleInputStartDate(e) {
        let value = e.target.value;
        
        this.setState( 
            prevState => ( {
                start_date: value,
                valid_startdate: (Date.parse(value) >= Date.parse("2010-01-01")) && (Date.parse(value) <= Date.parse("2021-2-2") ),
                date_prob_tockers: []
            }),
            () => console.log(this.state.start_date)
        );
    }

    handleInputEndDate(e) {
        let value = e.target.value;
        
        this.setState( 
            prevState => ( {
                end_date: value,
                valid_enddate: ( Date.parse(value) > Date.parse(this.state.start_date)) && ( Date.parse(value) <= Date.parse("2021-01-29") ),
                date_prob_tockers: []
            }),
            () => console.log(this.state.end_date)
        );
    }

    handleStrat = selectedOption =>{
        this.setState(
            {selectedOption: selectedOption},
            () => console.log('Options selected:', this.state.selectedOption)

        );
    };
    handleModel = selectedModel=>{
        this.setState(
            {selectedModel: selectedModel},
            () => console.log('Options selected:', this.state.selectedModel)

        );
    };
    handlePeriod = selectedPeriod =>{
        this.setState(
            {selectedPeriod: selectedPeriod},
            () => console.log('Options selected:', this.state.selectedPeriod)

        );
    };
    handleConfidence = selectedConfidence =>{
        this.setState(
            {selectedConfidence: selectedConfidence},
            () => console.log('Options selected:', this.state.selectedConfidence)

        );
    };
    handleHitratio = selectedHitratio =>{
        this.setState(
            {selectedHitratio: selectedHitratio},
            () => console.log('Options selected:', this.state.selectedHitratio)

        );
    };
    render() {
        const is_505_error =this.state.is_505_error;
        console.log("is_505_error:", is_505_error);

        //const {showChart} = this.state.data;
        if (this.state.loading === 2) {  // data ready and display
            if (is_505_error){
                return ( 
                    <div id="pf1"><h1>Model Comparison</h1>
                        {/* <a href="http://100.1.58.77:3000/#/crypto1/" target="_blank">open new window</a> */}
                        <div class="alert alert-primary" role="alert">
                        <h5>Overview</h5>
                            <p>Based on the prediction models implemented in the platform with various paremeters, the past performances (weekly and monthly - 2011 ~ present) are collected with respect to:</p>
                            <ul>
                                <li>Hit ratio</li>
                                {/* <li>MSE (to be added)</li> */}
                            </ul>
                            <h5>Universe</h5>
                            <p>Weekly: 2500+ stocks in U.S. Market </p>
                            <p>Daily : 500+ stocks in U.S. Market </p>

                        <h5>Methodology</h5>
                        <p>Compare the confidence of the prediction model of difference probability per sectors and different parameters by aggregating all performances available.</p>
                            <ul>
                                <li>Bayesian Inference</li>
                                <li>Based on hit ratio</li>
                                <li>Likelihood: Past hit ratio as Beta Distribution</li>
                                <li>Prior: Non-informative</li>
                                <li>Based on Posterior Distribution, there is a better than the probability of [ ] that the chance of the correct prediction is greater than the probability of [ ]</li>
                            </ul>
                            {/* <p>From 2021-1-22</p> */}
                        </div>
                        <div className="form1_input">
                            <form onSubmit={this.handleClick}>
                            <label>Select Model</label>
                                <Select
                                    // defaultValue={modelOptions[0]}
                                    // defaultValue= {[ modelOptions[0], modelOptions[1],
                                    //         modelOptions[2], modelOptions[3],
                                    //         modelOptions[4], modelOptions[5] ]}
                                    isMulti
                                    name="models"
                                    options={modelOptions}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    allowSelectAll={true}
                                    isSearchable
                                    onChange = {this.handleModel}
                                    value = {this.state.selectedModel}
                                    // value = {[ modelOptions[0], modelOptions[1],
                                    //     modelOptions[2], modelOptions[3],
                                    //     modelOptions[4], modelOptions[5] , modelOptions[6]]}
                                />
                                <label>Prediction Period</label>
                                <Select
                                    // defaultValue={modelOptions[0]}
                                    // defaultValue= {[ modelOptions[0], modelOptions[1],
                                    //         modelOptions[2], modelOptions[3],
                                    //         modelOptions[4], modelOptions[5] ]}
                                    
                                    name="period"
                                    options={periodOptions}
                                    classNamePrefix="select"
                                    allowSelectAll={true}
                                    isSearchable
                                    onChange = {this.handlePeriod}
                                    value = {this.state.selectedPeriod}
                                    // value = {[ modelOptions[0], modelOptions[1],
                                    //     modelOptions[2], modelOptions[3],
                                    //     modelOptions[4], modelOptions[5] , modelOptions[6]]}
                                />
                                <label>Better than the probability of (%) </label>
                                <Select
                                    // defaultValue={modelOptions[0]}
                                    // defaultValue= {[ modelOptions[0], modelOptions[1],
                                    //         modelOptions[2], modelOptions[3],
                                    //         modelOptions[4], modelOptions[5] ]}
                                    
                                    name="confidence"
                                    options={confidenceOptions}
                                    classNamePrefix="select"
                                    allowSelectAll={true}
                                    isSearchable
                                    onChange = {this.handleConfidence}
                                    value = {this.state.selectedConfidence}
                                    // value = {[ modelOptions[0], modelOptions[1],
                                    //     modelOptions[2], modelOptions[3],
                                    //     modelOptions[4], modelOptions[5] , modelOptions[6]]}
                                />
                                <label>the chance of hit ratio greater than (%)</label>
                                <Select
                                    // defaultValue={modelOptions[0]}
                                    // defaultValue= {[ modelOptions[0], modelOptions[1],
                                    //         modelOptions[2], modelOptions[3],
                                    //         modelOptions[4], modelOptions[5] ]}
                                    
                                    name="hitratio"
                                    options={hitratioOptions}
                                    
                                    classNamePrefix="select"
                                    allowSelectAll={true}
                                    isSearchable
                                    onChange = {this.handleHitratio}
                                    value = {this.state.selectedHitratio}
                                    // value = {[ modelOptions[0], modelOptions[1],
                                    //     modelOptions[2], modelOptions[3],
                                    //     modelOptions[4], modelOptions[5] , modelOptions[6]]}
                                />

                                <ChButton
                                    action={this.handleClick}
                                    type={"btn-outline-primary"}
                                    title={"Run"}
                                    style={buttonStyle}
                                />{" "}
                            </form>

   


                        </div>
                        
                        <div>
                            {/* {this.state.data.map((dd, index) =>
                                
                                //<Chart_StockLine key={index} title_text={"ticker : " + index} 
                                // <Chart_StockLine key={index} title_text={"ticker : " + this.state.ticker_r} 
                                <Chart_StockLine key={index} title_text={" " + this.state.ticker_names[index]} 
                                data = {dd}/>,
                                // <Chart_StockLine  title_text={"title_text"} 
                                // data = {dd[0]}/>
                                )
                                
                            } */}

                            {/* <Chart_StockLine  title_text={this.state.groupname} 
                                    data = {this.state.data}/>  */}
                        </div>
                        <div>
                            {/* <iframe src="error500.html" frameborder="0" scrolling="no" onload="resizeIframe(this)" ></iframe> */}
                            <iframe src="error500.html" frameborder="0" scrolling="no" height="600" width="800" ></iframe>

                        </div>
                        <Footer />
                        {/* <footer class="mastfoot mt-auto">
                            <div class="inner">
                                <p>© 2023 Copyright Chris IJ Hwang</p>
                            </div>
                            <p>DISCLAIMER: The information in this site is for the purpose of research and backtest only. We don't promise any future profits and don't take responsibility for any trading losses.
                            </p>
                        </footer> */}
                        
                    </div>
                );
            }
            else {
                return ( 
                    <div id="pf1"><h1>Model Comparison</h1>

                        {/* <a href="http://100.1.58.77:3000/#/crypto1/" target="_blank">open new window</a> */}
                        <div class="alert alert-primary" role="alert">
                        <h5>Overview</h5>
                            <p>Based on the prediction models implemented in the platform with various paremeters, the past performances (weekly and monthly - 2011 ~ present) are collected with respect to:</p>
                            <ul>
                                <li>Hit ratio</li>
                                {/* <li>MSE (to be added)</li> */}
                            </ul>
                            <h5>Universe</h5>
                            <p>Weekly: 2500+ stocks in U.S. Market </p>
                            <p>Daily : 500+ stocks in U.S. Market </p>

                        <h5>Methodology</h5>
                        <p>Compare the confidence of the prediction model of difference probability per sectors and different parameters by aggregating all performances available.</p>
                            <ul>
                                <li>Bayesian Inference</li>
                                <li>Based on hit ratio</li>
                                <li>Likelihood: Past hit ratio as Beta Distribution</li>
                                <li>Prior: Non-informative</li>
                                <li>Based on Posterior Distribution, there is a better than the probability of [ ] that the chance of the correct prediction is greater than the probability of [ ]</li>
                            </ul>
                            {/* <p>From 2021-1-22</p> */}
                        </div>
                        <div className="form1_input">
                            <form onSubmit={this.handleClick}>
                            <label>Select Model</label>
                                <Select
                                    // defaultValue={modelOptions[0]}
                                    // defaultValue= {[ modelOptions[0], modelOptions[1],
                                    //         modelOptions[2], modelOptions[3],
                                    //         modelOptions[4], modelOptions[5] ]}
                                    isMulti
                                    name="models"
                                    options={modelOptions}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    allowSelectAll={true}
                                    isSearchable
                                    onChange = {this.handleModel}
                                    value = {this.state.selectedModel}
                                    // value = {[ modelOptions[0], modelOptions[1],
                                    //     modelOptions[2], modelOptions[3],
                                    //     modelOptions[4], modelOptions[5] , modelOptions[6]]}
                                />
                                <label>Prediction Period</label>
                                <Select
                                    // defaultValue={modelOptions[0]}
                                    // defaultValue= {[ modelOptions[0], modelOptions[1],
                                    //         modelOptions[2], modelOptions[3],
                                    //         modelOptions[4], modelOptions[5] ]}
                                    
                                    name="period"
                                    options={periodOptions}
                                    classNamePrefix="select"
                                    allowSelectAll={true}
                                    isSearchable
                                    onChange = {this.handlePeriod}
                                    value = {this.state.selectedPeriod}
                                    // value = {[ modelOptions[0], modelOptions[1],
                                    //     modelOptions[2], modelOptions[3],
                                    //     modelOptions[4], modelOptions[5] , modelOptions[6]]}
                                />
                                <label>Better than the probability of (%) </label>
                                <Select
                                    // defaultValue={modelOptions[0]}
                                    // defaultValue= {[ modelOptions[0], modelOptions[1],
                                    //         modelOptions[2], modelOptions[3],
                                    //         modelOptions[4], modelOptions[5] ]}
                                    
                                    name="confidence"
                                    options={confidenceOptions}
                                    classNamePrefix="select"
                                    allowSelectAll={true}
                                    isSearchable
                                    onChange = {this.handleConfidence}
                                    value = {this.state.selectedConfidence}
                                    // value = {[ modelOptions[0], modelOptions[1],
                                    //     modelOptions[2], modelOptions[3],
                                    //     modelOptions[4], modelOptions[5] , modelOptions[6]]}
                                />
                                <label>the chance of hit ratio greater than (%)</label>
                                <Select
                                    // defaultValue={modelOptions[0]}
                                    // defaultValue= {[ modelOptions[0], modelOptions[1],
                                    //         modelOptions[2], modelOptions[3],
                                    //         modelOptions[4], modelOptions[5] ]}
                                    
                                    name="hitratio"
                                    options={hitratioOptions}
                                    
                                    classNamePrefix="select"
                                    allowSelectAll={true}
                                    isSearchable
                                    onChange = {this.handleHitratio}
                                    value = {this.state.selectedHitratio}
                                    // value = {[ modelOptions[0], modelOptions[1],
                                    //     modelOptions[2], modelOptions[3],
                                    //     modelOptions[4], modelOptions[5] , modelOptions[6]]}
                                />

                                <ChButton
                                    action={this.handleClick}
                                    type={"btn-outline-primary"}
                                    title={"Run"}
                                    style={buttonStyle}
                                />{" "}
                            </form>

                            <div className="row">
                                <div  className="col" id="chart1">
                                    <Chart_Column_bar2  title_text='lookback' 
                                        data = {this.state.lookback}/> 
                                </div>

                                <div  className="col" id="chart2">
                                    <Chart_Column_bar2 renderTo='chart2' title_text='ps' 
                                        data = {this.state.ps}/> 
                                </div>

                                <div  className="col" id="chart3">
                                    <Chart_Column_bar2 renderTo='chart3' title_text='qs' 
                                        data = {this.state.qs}/> 
                                </div>
                            
                            </div>
                            <div className="row">
                                <div  className="col" id="chart4">
                                    <Chart_Column_bar2 renderTo='chart4' title_text='Sectors' 
                                        data = {this.state.total}/> 
                                </div>
                            </div>
                            <div className="row">
                                <div  className="col" id="chart5">

                                    <Chart_Scatter_Jitter renderTo='chart5'
                                        categories={this.state.categories} 
                                        
                                        title_text={'The Probability that the hit ratio is greater than '+ this.state.selectedHitratio.value} x_title='Vol' y_title='Prob'
                                    series = {this.state.scatter_jitter_data}/> 
                            
                                </div>
                            </div>


                        </div>
                        
                        <div>
                            {/* {this.state.data.map((dd, index) =>
                                
                                //<Chart_StockLine key={index} title_text={"ticker : " + index} 
                                // <Chart_StockLine key={index} title_text={"ticker : " + this.state.ticker_r} 
                                <Chart_StockLine key={index} title_text={" " + this.state.ticker_names[index]} 
                                data = {dd}/>,
                                // <Chart_StockLine  title_text={"title_text"} 
                                // data = {dd[0]}/>
                                )
                                
                            } */}

                            {/* <Chart_StockLine  title_text={this.state.groupname} 
                                    data = {this.state.data}/>  */}
                        </div>
                        <Footer />
                        {/* <footer class="mastfoot mt-auto">
                            <div class="inner">
                                <p>© 2023 Copyright Chris IJ Hwang</p>
                            </div>
                            <p>DISCLAIMER: The information in this site is for the purpose of research and backtest only. We don't promise any future profits and don't take responsibility for any trading losses.
                            </p>
                        </footer> */}
                        
                    </div>
                );


            }

        } else if(this.state.loading === 1) { // loading
            return (
                <div id="pf1"><h1>Ticker Models Analysis</h1>
                    {/* <a href="http://100.1.58.77:3000/#/crypto1/" target="_blank">open new window</a> */}

                    <div class="alert alert-secondary" role="alert">
                    <h5>Overview</h5>
                            <p>Based on the prediction models implemented in the platform with various paremeters, the past performances (weekly and monthly - 2011 ~ present) are collected with respect to:</p>
                            <ul>
                                <li>Hit ratio</li>
                                {/* <li>MSE (to be added)</li> */}
                            </ul>
                            <h5>Universe</h5>
                            <p>Weekly: 2500+ stocks in U.S. Market </p>
                            <p>Daily : 500+ stocks in U.S. Market </p>

                        <h5>Methodology</h5>
                        <p>Compare the confidence of the prediction model of difference probability per sectors and different parameters by aggregating all performances available.</p>
                            <ul>
                                <li>Bayesian Inference</li>
                                <li>Based on hit ratio</li>
                                <li>Likelihood: Past hit ratio as Beta Distribution</li>
                                <li>Prior: Non-informative</li>
                                <li>Based on Posterior Distribution, there is a better than the probability of [ ] that the chance of the correct prediction is greater than the probability of [ ]</li>
                            </ul>
                        {/* <p>From 2021-1-22</p> */}
                    </div>
                    <div className="form1_input">
                        <form onSubmit={this.handleClick}>
                            <label>Select Model</label>
                            <Select
                                // defaultValue={modelOptions[0]}
                                // defaultValue= {[ modelOptions[0], modelOptions[1],
                                //         modelOptions[2], modelOptions[3],
                                //         modelOptions[4], modelOptions[5] ]}
                                isMulti
                                name="models"
                                options={modelOptions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                allowSelectAll={true}
                                isSearchable
                                onChange = {this.handleStrat}
                                value = {this.state.selectedOption}
                                // value = {[ modelOptions[0], modelOptions[1],
                                //     modelOptions[2], modelOptions[3],
                                //     modelOptions[4], modelOptions[5] , modelOptions[6]]}
                            />
                            <label>Prediction Period</label>
                            <Select
                                // defaultValue={modelOptions[0]}
                                // defaultValue= {[ modelOptions[0], modelOptions[1],
                                //         modelOptions[2], modelOptions[3],
                                //         modelOptions[4], modelOptions[5] ]}
                                
                                name="period"
                                options={periodOptions}
                                classNamePrefix="select"
                                allowSelectAll={true}
                                isSearchable
                                onChange = {this.handlePeriod}
                                // value = {[ modelOptions[0], modelOptions[1],
                                //     modelOptions[2], modelOptions[3],
                                //     modelOptions[4], modelOptions[5] , modelOptions[6]]}
                            />
                            <label>Better than the probability of (%) </label>
                            <Select
                                // defaultValue={modelOptions[0]}
                                // defaultValue= {[ modelOptions[0], modelOptions[1],
                                //         modelOptions[2], modelOptions[3],
                                //         modelOptions[4], modelOptions[5] ]}
                                
                                name="confidence"
                                options={confidenceOptions}
                                classNamePrefix="select"
                                allowSelectAll={true}
                                isSearchable
                                onChange = {this.handleConfidence}
                                // value = {[ modelOptions[0], modelOptions[1],
                                //     modelOptions[2], modelOptions[3],
                                //     modelOptions[4], modelOptions[5] , modelOptions[6]]}
                            />
                            <label>the chance of hit ratio greater than (%)</label>
                            <Select
                                // defaultValue={modelOptions[0]}
                                // defaultValue= {[ modelOptions[0], modelOptions[1],
                                //         modelOptions[2], modelOptions[3],
                                //         modelOptions[4], modelOptions[5] ]}
                                
                                name="hitratio"
                                options={hitratioOptions}
                                
                                classNamePrefix="select"
                                allowSelectAll={true}
                                isSearchable
                                onChange = {this.handleHitratio}
                                // value = {[ modelOptions[0], modelOptions[1],
                                //     modelOptions[2], modelOptions[3],
                                //     modelOptions[4], modelOptions[5] , modelOptions[6]]}
                            />
                            {/* <ChInput  
                                inputType={"text"}
                                title={"Tickers (separated by space)"}
                                name={"ticker_name"}
                                value = {this.state.ticker_r}
                                placeholder="Enter ticker" 
                                handleChange={this.handleInput}
                                //handlechange={this.handleInput}
                            />{" "} */}
                            <ChButton
                                action={this.handleClick}
                                type={"btn-outline-primary"}
                                title={"Run"}
                                style={buttonStyle}
                            />{" "}

                            {/* <ChButton
                                action={this.handleClick}
                                type={"primary"}
                                title={"Start Predict"}
                                style={buttonStyle}
                            />{" "} */}
                            {/* <div class="alert alert-primary" role="alert">
                            {this.state.msg}
                            </div> */}
                        </form>
                    </div>
                    <div align="center">
                        <br/>
                        
                        <Spinner animation="border" variant="success" >
                            
                        </Spinner>
                        <br/>
                        <br/>
                        <br/>
                        <br/>

                    </div>
                    <Footer />
                    {/* <footer class="mastfoot mt-auto">
                            <div class="inner">
                                <p>© 2023 Copyright Chris IJ Hwang</p>
                            </div>
                            <p>DISCLAIMER: The information in this site is for the purpose of research and backtest only. We don't promise any future profits and don't take responsibility for any trading losses.
                            </p>
                    </footer> */}
                </div>
                
            );
        }
         else { // not started
            return ( 
                <div id="pf1"><h1>Models Comparison</h1>
                    {/* <a href="http://100.1.58.77:3000/#/crypto1/" target="_blank">open new window</a> */}

                    <div class="alert alert-primary" role="alert">
                    <h5>Overview</h5>
                            <p>Based on the prediction models implemented in the platform with various paremeters, the past performances (weekly and monthly - 2011 ~ present) are collected with respect to:</p>
                            <ul>
                                <li>Hit ratio</li>
                                {/* <li>MSE (to be added)</li> */}
                            </ul>
                        <h5>Universe</h5>
                            <p>Weekly: 2500+ stocks in U.S. Market </p>
                            <p>Daily : 500+ stocks in U.S. Market </p>

                        <h5>Methodology</h5>
                        <p>Compare the confidence of the prediction model of difference probability per sectors and different parameters by aggregating all performances available.</p>
                            <ul>
                                <li>Bayesian Inference</li>
                                <li>Based on hit ratio</li>
                                <li>Likelihood: Past hit ratio as Beta Distribution</li>
                                <li>Prior: Non-informative</li>
                                <li>Based on Posterior Distribution, there is a better than the probability of [ ] that the chance of the correct prediction is greater than the probability of [ ]</li>
                            </ul>

                        {/* <p>From 2021-1-22</p> */}
                    </div>
                    <div className="form1_input">

                        <form onSubmit={this.handleClick}>
                            <label>Select Model</label>
                            <Select
                                // defaultValue={this.state.selectedModel}
                                // defaultValue= {[ modelOptions[0], modelOptions[1],
                                //         modelOptions[2], modelOptions[3],
                                //         modelOptions[4], modelOptions[5] ]}
                                isMulti
                                name="models"
                                options={modelOptions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                allowSelectAll={true}
                                isSearchable
                                onChange = {this.handleModel}
                                // value = {this.state.selectedModel}
                                // value = {[ modelOptions[0], modelOptions[1],
                                //     modelOptions[2], modelOptions[3],
                                //     modelOptions[4], modelOptions[5] , modelOptions[6]]}
                            />
                            <label>Prediction Period</label>
                            <Select
                                // defaultValue={this.state.selectedPeriod}
                                // defaultValue= {[ modelOptions[0], modelOptions[1],
                                //         modelOptions[2], modelOptions[3],
                                //         modelOptions[4], modelOptions[5] ]}
                                
                                name="period"
                                options={periodOptions}
                                classNamePrefix="select"
                                allowSelectAll={true}
                                isSearchable
                                onChange = {this.handlePeriod}
                                // value = {this.state.selectedPeriod}
                                // value = {[ modelOptions[0], modelOptions[1],
                                //     modelOptions[2], modelOptions[3],
                                //     modelOptions[4], modelOptions[5] , modelOptions[6]]}
                            />
                            <label>Better than the probability of (%) </label>
                            <Select
                                // defaultValue={this.state.selectedConfidence}
                                // defaultValue= {[ modelOptions[0], modelOptions[1],
                                //         modelOptions[2], modelOptions[3],
                                //         modelOptions[4], modelOptions[5] ]}
                                
                                name="confidence"
                                options={confidenceOptions}
                                classNamePrefix="select"
                                allowSelectAll={true}
                                isSearchable
                                onChange = {this.handleConfidence}
                                // value = {this.state.selectedConfidence}
                                // value = {[ modelOptions[0], modelOptions[1],
                                //     modelOptions[2], modelOptions[3],
                                //     modelOptions[4], modelOptions[5] , modelOptions[6]]}
                            />
                            <label>the chance of hit ratio greater than (%)</label>
                            <Select
                                // defaultValue={this.state.selectedHitratio}
                                // defaultValue= {[ modelOptions[0], modelOptions[1],
                                //         modelOptions[2], modelOptions[3],
                                //         modelOptions[4], modelOptions[5] ]}
                                
                                name="hitratio"
                                options={hitratioOptions}
                                
                                classNamePrefix="select"
                                allowSelectAll={true}
                                isSearchable
                                onChange = {this.handleHitratio}
                                // value = {this.state.selectedHitratio}
                                // value = {[ modelOptions[0], modelOptions[1],
                                //     modelOptions[2], modelOptions[3],
                                //     modelOptions[4], modelOptions[5] , modelOptions[6]]}
                            />

                            {/* <ChInput  
                                inputType={"text"}
                                title={"Tickers (separated by space)"}
                                name={"ticker_name"}
                                value = {this.state.ticker_r}
                                placeholder="Enter ticker" 
                                handleChange={this.handleInput}
                                //handlechange={this.handleInput}
                            />{" "} */}
                            <ChButton
                                action={this.handleClick}
                                type={"btn-outline-primary"}
                                title={"Run"}
                                style={buttonStyle}
                            />{" "}
                            
                            {/* <div class="alert alert-primary" role="alert">
                            {this.state.msg}
                            </div> */}
                            
                        </form>
                        {/* <BootstrapTable 

                            keyField='model' 
                            data={ products } 
                            columns={ columns } 
                            striped
                            hover
                            defaultSorted={ defaultSorted } 
                        /> */}
                    </div>
                    <Footer />
                    {/* <footer class="mastfoot mt-auto">
                            <div class="inner">
                                <p>© 2023 Copyright Chris IJ Hwang</p>
                            </div>
                            <p>DISCLAIMER: The information in this site is for the purpose of research and backtest only. We don't promise any future profits and don't take responsibility for any trading losses.
                            </p>
                    </footer> */}
                </div>
            );
        }
    }
}

const buttonStyle = {
    margin: "10px 10px 10px 10px"
 };

export default ModelsComp 