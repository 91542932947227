export const columns = [
    { dataField: 'ticker',  text: 'Ticker' , sort:true }, 
    { dataField: 'model',  text: 'Stratagy' , sort:true }, 
    { dataField: 'totret', text: 'Total Return', sort:true }, 
    { dataField: 'std', text: 'Std', sort:true }, 
    { dataField: 'sharpe', text: 'Risk Adj Return', sort:true }, 
    // { dataField: 'hitratio', text: 'Hit Ratio', sort:true }, 
    { dataField: 'maxdrawdown', text: 'Max Drawdown', sort:true }, 
    { dataField: 'cummax', text: 'Cumulative Max', sort:true }, 
];

export const products = [ 
    {'ticker': 'TSLA', 'model': 'mom 3 day', 'totret': 1.2, 'std': 0.34,
        'sharpe': 1.0, 'maxdrawdown': 0.4, 
        'cummax': 1.9} ,
    {'ticker': 'SQ', 'model': 'mom 5 day', 'totret': 1.5, 'std': 0.24,
        'sharpe': 1.3, 'maxdrawdown': 0.5, 
        'cummax': 1.4}];