import React from 'react';

function Footer() {
    return (
        <footer class="mastfoot mt-auto">

            <div class="inner">
                <p>© 2024 Copyright Chris IJ Hwang</p>            
            </div>
            <p>DISCLAIMER: The information in this site is for the purpose of research and backtest only. We don't promise any future profits and don't take responsibility for any trading losses.
            </p>
        </footer>
    )
}

export default Footer;