import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Spinner from 'react-bootstrap/Spinner'
import {API_URL_STRATEGY1} from './config'
import './App.css';
import axios from 'axios';

import ChInput from './ChInput';
import ChButton from './ChButton';

// import Chart_Donut from './Chart_Donut';
// import Chart_Donut2 from './Chart_Donut2';
// import Chart_Column_bar from './Chart_Column_bar';
// import Chart_Horizon_bar_sort from './Chart_Horizon_bar_sort';
// import Chart_Horizon_bar_multi from './Chart_Horizon_bar_multi';
// import Chart_Scatter from './Chart_Scatter';
import Chart_StockLine from './Chart_StockLine';


class Strategy1 extends React.Component {
    constructor(props) {
        super(props);
        // var options = {
        //     chart: {
        //         type: "pie"
        //     },
        //     series: [
        //         {
        //             name : "sector",
        //             data : this.state.data
        //         }
        //     ]
        // }

        this.state = {
            msg: "sma",
            strat: "sma",
            sma1 : 10,
            sma2 : 30,
            amount : 10000,
            is_feasible: true,
            not_feasible_msg : "",
            ticker_r: '',            
            start_date: '2020-01-01',
            end_date: '2020-12-28',
            is_updated: false,
            loading: 0, // 0 : not started, 1: loading, 2: done
            groupname: null,
            data: null,
            valid_ticker: true,
            valid_enddate: true,
            valid_startdate: true,
            no_data_tickers: [],
            date_prob_tockers: []
        };

        console.log("this.state.is_updated: ", this.state.is_updated);

        this.handleClick = this.handleClick.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.handleInputStartDate = this.handleInputStartDate.bind(this);
        this.handleInputEndDate = this.handleInputEndDate.bind(this);
        // this.handleSelect = this.handleSelect.bind(this);
        // this.handleStateChange = this.handleStateChange.bind(this);
        


    }

 
    // shouldComponentUpdate(newProps, newState) {
    //     console.log("About to shouldComponent Updadte");
    //     if (this.state.is_updated === false) {
    //         return true;
    //     }
    //     else {
    //         this.setState( {
    //             is_updated: true
    //         })
    //         console.log("just updated is_update to true and update comp");
    //         return false;
    //     }
    // }

    // handleStateChange(e) {
    //     this.setState( {
    //         data: e
    //     })
    //     console.log("insde handleStateChange");
    //     console.log(this.state.data);

        

    // }


    handleClick(e) {
        
        e.preventDefault(); 
        if (!this.state.valid_ticker || !this.state.valid_enddate || !this.state.valid_startdate) {
            
            return;
        }
        
        // axios.get function will lose the context of 'this'. So, copy it into
        // var self == this. then use self when update the state lilke 
        // "self.setState( {  .... } );". this properly point this component.
        //axios.post("http://100.1.58.77:5000/api/strategy1", {
        axios.post(`${API_URL_STRATEGY1}`, {
                strat : this.state.strat,
                ticker: this.state.ticker_r,
                start_date: this.state.start_date,
                end_date: this.state.end_date,
                sma1 : this.state.sma1,
                sma2 : this.state.sma2,
                amount: this.state.amount

            })
            //.then(function(response) {
            .then( (response) => {
                console.log(response);
                

                this.setState (
                    prevState => ( {
                        loading: 2, // done. received response
                        //groupname: response.data[0]['name'],
                        data: response.data
                        // categories2: response.data[1][0]['categories'],
                        // data2: response.data[1][1]['data'],
                        // categories3: response.data[2][0]['categories'],
                        // data3: response.data[2][1]['data'],
                        // categories4: response.data[3][0]['categories'],
                        // data4: response.data[3][1]['data'],
                        // categories5: response.data[4][0]['categories'],
                        // data5: response.data[4][1]['data'],
                        // categories6: response.data[5][0]['categories'],
                        // data6: response.data[5][1]['data'],
                        // categories7: response.data[6][0]['categories'],
                        // data7: response.data[6][1]['data'],
                        // categories8: response.data[7][0]['categories'],
                        // data8: response.data[7][1]['data'],
                        // series_for_scatter: response.data[8],
                        // is_feasible: response.data[9]['is_feasible'],
                        // categories_weight: response.data[10][0]['categories'],
                        // data_weight: response.data[10][1]['data'],
                        

                    }),
                    () => console.log(this.state)
                );

                // if (this.state.is_feable == 'infeasible') {
                //     this.setState (
                //         prevState => ( {
                //             is_feasible: false,
                //             not_feasible_msg: "Optimization is not feasible"
                //         }),
                //         () => console.log(this.state)
                //     );
                // }


                
                //console.log(self.options)

                // self.setState( {
                //     data : response.data,
                //     is_updated: true
                // });
                console.log("inside call back");
                //console.log(this.state.data);
                console.log(this.state.data[0][0]['name']);
                //console.log(self.state.is_updated);

                // self.forceUpdate(); // not working
                
                //self.handleStateChange(response.data);
                    
            })
            .catch(function (error) {
                console.log(error);
            })
            .then(function() {
                console.log('Fetch done');
            });
    }

    handleInput(e) {
        let value = e.target.value;

        this.setState (
            prevState => ( {
                ticker_r: value,
                valid_ticker: value.trim().split(' ').length > 0
            }),
            () => console.log(this.state.ticker_r)
        );

        //e.preventDefault();
    }

    handleInputStartDate(e) {
        let value = e.target.value;
        
        this.setState( 
            prevState => ( {
                start_date: value,
                valid_startdate: (Date.parse(value) >= Date.parse("2010-01-01")) && (Date.parse(value) <= Date.parse("2020-12-31") ),
                date_prob_tockers: []
            }),
            () => console.log(this.state.start_date)
        );
    }

    handleInputEndDate(e) {
        let value = e.target.value;
        
        this.setState( 
            prevState => ( {
                end_date: value,
                valid_enddate: ( Date.parse(value) > Date.parse(this.state.start_date)) && ( Date.parse(value) <= Date.parse("2021-01-29") ),
                date_prob_tockers: []
            }),
            () => console.log(this.state.end_date)
        );
    }



    render() {
        //const {showChart} = this.state.data;
        if (this.state.loading === 2) {  // data ready and display

            return ( 
                <div id="pf1"><h1>Model Backtest</h1>
                    <div class="alert alert-secondary" role="alert">
                        <h5>Select model(s)</h5>
                        <p>From 2020-8-17</p>
                    </div>
                    <div className="form1_input">
                        <form onSubmit={this.handleClick}>
                            <ChInput  
                                inputType={"text"}
                                title={"Tickers (separated by space)"}
                                name={"ticker_name"}
                                value = {this.state.ticker_r}
                                placeholder="Enter ticker" 
                                handleChange={this.handleInput}
                            />{" "}
                            {this.state.valid_ticker ? null : <div class="alert alert-danger" role="alert">
                                                            {"Enter the valid three or more tickers from SP500 "}
                                                            </div>
                            }
                            <ChInput  
                                inputType={"date"}
                                title={"Start Date"}
                                name={"start_name"}
                                value = {this.state.start_date}
                                placeholder="Input date" 
                                min = {"2011-01-02"}
                                max = {"2020-12-20"}
                                handleChange={this.handleInputStartDate}
                            />{" "}
                            {this.state.valid_startdate ? null : <div class="alert alert-danger" role="alert">
                                                            {"Enter the valid Start Date"}
                                                            </div>
                            }
                            {this.state.date_prob_tockers==0 ? null : <div class="alert alert-danger" role="alert">
                                                            {"Data for " + this.state.date_prob_tockers +  " Not Available on the date you selected. Please select different start and/or end date."}
                                                            </div>
                            }
                            <ChInput  
                                inputType={"date"}
                                title={"End Date"}
                                name={"end_name"}
                                value = {this.state.end_date}
                                placeholder="Input date" 
                                handleChange={this.handleInputEndDate}
                            />{" "}
                            {this.state.valid_enddate ? null : <div class="alert alert-danger" role="alert">
                                                            {"Enter the valid End Date"}
                                                            </div>
                            }
                            
                            

                            {/* <label>
                            Select Optimization:
                            <select value={this.state.optimizer} onChange={this.handleSelect}>
                                <option value="EW">Equal Weight</option>
                                <option value="MV_FN">MV w/ Factor Neutral</option>
                            </select>
                            </label> */}
                            <ChButton
                                action={this.handleClick}
                                type={"primary"}
                                title={"Run"}
                                style={buttonStyle}
                            />{" "}
                            {/* <div class="alert alert-primary" role="alert">
                            {this.state.msg}
                            </div>
                            {this.state.is_feasible ? null : <div class="alert alert-danger" role="alert">
                                                            {"The optimization is not feasible!"}
                                                            </div> 
                            } */}
                        </form>

                        {/* <button className='button' onClick={this.handleClick}>Test button</button> */}
                        {/* <div>{this.state.data}</div> */}
                        {/* <div>{this.state.data}</div> */}
                        {/* {this.state.is_updated ? "not updated yet" : "updated>>>>>>" } */}

                    </div>
                    
                    <div>
                        {this.state.data.map((dd, index) =>
                            
                            <Chart_StockLine key={index} title_text={"ticker : " + index} 
                            data = {dd}/>,
                            // <Chart_StockLine  title_text={"title_text"} 
                            // data = {dd[0]}/>
                            )
                            
                        }

                         {/* <Chart_StockLine  title_text={this.state.groupname} 
                                 data = {this.state.data}/>  */}
                    </div>
                    
                </div>
            );

        } else if(this.state.loading === 1) { // loading
            return (
                <div id="pf1"><h1>Model Backtest</h1>
                    <div class="alert alert-secondary" role="alert">
                        <h5>Select model(s)</h5>
                        <p>From 2020-8-17</p>
                    </div>
                    <div className="form1_input">
                        <form onSubmit={this.handleClick}>
                            <ChInput  
                                inputType={"text"}
                                title={"Tickers (separated by space)"}
                                name={"ticker_name"}
                                value = {this.state.ticker_r}
                                placeholder="Enter ticker" 
                                handleChange={this.handleInput}
                            />{" "}
                            
                            <ChInput  
                                inputType={"date"}
                                title={"Start Date"}
                                name={"start_name"}
                                value = {this.state.start_date}
                                placeholder="Input date" 
                                min = {"2011-01-02"}
                                max = {"2020-12-20"}
                                handleChange={this.handleInputStartDate}
                            />{" "}
                            <ChInput  
                                inputType={"date"}
                                title={"End Date"}
                                name={"end_name"}
                                value = {this.state.end_date}
                                placeholder="Input date" 

                                handleChange={this.handleInputEndDate}
                            />{" "}
                            
                            {/* <label>
                            Select Optimization:
                            <select value={this.state.optimizer} onChange={this.handleSelect}>
                                <option value="EW">Equal Weight</option>
                                <option value="MV_FN">EW w/ Factor Neutral</option>
                            </select>
                            </label> */}
                            <ChButton
                                action={this.handleClick}
                                type={"primary"}
                                title={"Run"}
                                style={buttonStyle}
                            />{" "}
                            {/* <div class="alert alert-primary" role="alert">
                            {this.state.msg}
                            </div> */}
                        </form>
                    </div>
                    <div align="center">
                        <br/>
                        <br/>
                        <br/>
                        <br/>

                        <Spinner animation="border" variant="success" >
                            
                        </Spinner>
                        <br/>
                        <br/>
                        <br/>
                        <br/>

                    </div>
                </div>
                
            );
        }
         else { // not started
            return ( 
                <div id="pf1"><h1>Model Backtest</h1>
                    <div class="alert alert-secondary" role="alert">
                        <h5>Select model(s)</h5>
                    </div>
                    <div className="form1_input">
                        <form onSubmit={this.handleClick}>
                            <ChInput  
                                inputType={"text"}
                                title={"Tickers (separated by space)"}
                                name={"ticker_name"}
                                value = {this.state.ticker_r}
                                placeholder="Enter ticker" 
                                handleChange={this.handleInput}
                            />{" "}
                            {this.state.valid_ticker ? null : <div class="alert alert-danger" role="alert">
                                                            {"Enter the valid three or more tickers from SP500 "}
                                                            </div>
                            }
                            {this.state.no_data_tickers.length==0 ? null : <div class="alert alert-danger" role="alert">
                                                            {"Data Not available for " + this.state.no_data_tickers + ". Please use different Tickers."}
                                                            </div>
                            }
                            <ChInput  
                                inputType={"date"}
                                title={"Start Date"}
                                name={"start_name"}
                                value = {this.state.start_date}
                                placeholder="Input date" 
                                min = {"2010-01-01"}
                                max = {"2020-12-20"}
                                handleChange={this.handleInputStartDate}
                            />{" "}
                            {this.state.valid_startdate ? null : <div class="alert alert-danger" role="alert">
                                                            {"Enter the valid Start Date"}
                                                            </div>
                            }
                            {this.state.date_prob_tockers==0 ? null : <div class="alert alert-danger" role="alert">
                                                            {"Data for " + this.state.date_prob_tockers +  " Not Available on the date you selected. Please select different start and/or end date."}
                                                            </div>
                            }
                            <ChInput  
                                inputType={"date"}
                                title={"End Date"}
                                name={"end_name"}
                                value = {this.state.end_date}
                                placeholder="Input date" 
                                handleChange={this.handleInputEndDate}
                            />{" "}
                            {this.state.valid_enddate ? null : <div class="alert alert-danger" role="alert">
                                                            {"Enter the valid End Date"}
                                                            </div>
                            }
                            
                            {/* <label>
                            Select Optimization:
                            <select value={this.state.optimizer} onChange={this.handleSelect}>
                                <option value="EW">Equal Weight</option>
                                <option value="MV_FN">MV w/ Factor Neutral</option>
                            </select>
                            </label> */}
                            {this.state.valid_enddate ? null : <div class="alert alert-danger" role="alert">
                                                            {"Enter the valid End Date"}
                                                            </div>
                            }
                            <ChButton
                                action={this.handleClick}
                                type={"primary"}
                                title={"Run"}
                                style={buttonStyle}
                            />{" "}

                            {/* <div class="alert alert-primary" role="alert">
                            {this.state.msg}
                            </div> */}
                            
                        </form>
                    </div>
                </div>
            );
        }
    }
}

const buttonStyle = {
    margin: "10px 10px 10px 10px"
 };

export default Strategy1;