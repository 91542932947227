import React, { Component } from 'react';

import './App.css';
import axios from 'axios';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

class Chart_Scatter extends React.Component {

    constructor(props) {
        super(props);
        

        this.state = {
            chart: {
                renderTo: this.props.renderTo,
                type: "scatter",
                zoomType: 'xy'
                
            },
            title: {
                text: this.props.title_text
            },
            xAxis: {
                title: {
                    enabled: true,
                    text: this.props.x_title
                },
                startOnTick: true,
                endOnTick: true,
                showLastLabel: true

            },
            yAxis: {
                title: {
                    text: this.props.y_title
                }
            },
            plotOptions: {
                scatter: {
                    marker: {
                        radius: 5,
                        states: {
                            hover: {
                                enabled: true,
                                lineColor: 'rgb(100,100,100)'
                            }
                        }
                    },
                    states: {
                        hover: {
                            marker: {
                                enabled: false
                            }
                        }
                    },
                    tooltip: {
                        headerFormat: '<b>{series.name}</b><br>',
                        pointFormat: 'vol {point.x:,.4f} , ret {point.y:,.4f} '
                    }
                }
            },
            // series: [
            //     {
            //         data : this.props.data
            //         // [ [4, 5], [2, 8] ....] 
            //     }
            // ]
            series: this.props.series
        }
        console.log(this.props.series);

    }

    

    // shouldComponentUpdate(newProps, newState) {
    //     console.log("shouldComponentUpdate: Should component update?");
    //     console.log(this.state.series);
    //     console.log(newState.series);
    //     if (this.state.series !== newState.series) {
    //       console.log("shouldComponentUpdate: Component should update!");
    //       return true;
    //     } else {
    //       //ReactDOM.unmountComponentAtNode(destination);
    //       console.log("shouldComponentUpdate: Component should not update!");
    //       return false;
    //     }
    // }

    render() {
        return (
            <div >
                <HighchartsReact 
                highcharts={Highcharts} 
                options={this.state} />
            </div>

        )
    } 
}
export default Chart_Scatter;