import React from 'react'

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {columnDefs} from './gridTestList';
class UsingAgGrid extends React.Component {

  render() {
    return (
      <div className="ag-theme-alpine" style={{height: 200, width: 600}}>
        <AgGridReact
          rowData={[
            {make: "Toyota", model: "Celica", price: 35000},
            {make: "Ford", model: "Mondeo", price: 32000},
            {make: "Porsche", model: "Boxster", price: 72000}
          ]}
          columnDefs={columnDefs}>
        </AgGridReact>
      </div>

    );
  }
}


export default UsingAgGrid;