export const columns = [
    { dataField: 'year',  text: 'year' , sort:true }, 
    { dataField: 'Large Value',  text: 'Large Value %' , sort:true }, 
    { dataField: 'Large Growth',  text: 'Large Growth %' , sort:true }, 
    { dataField: 'Mid Value', text: 'Mid Value %', sort:true }, 
    { dataField: 'Mid Growth', text: 'Mid Growth %', sort:true }, 
    { dataField: 'Opt Val', text: 'Opt Val %', sort:true }, 
    { dataField: 'Residual', text: 'Residual %', sort:true }, 


];
