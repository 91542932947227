import React, { Component } from 'react';
import { Link,  Route,   HashRouter } from 'react-router-dom';
import { Container, Dropdown, NavDropdown} from 'react-bootstrap';
import './index.css';
import Home from "./Home";
import PortFolioForm1 from "./PortfolioForm1";
import PortFolioForm1Analysis from "./PortfolioForm1Analysis";
import PortFolioStyleAttr from "./PortfolioStyleAttribution";
import HCAForm1 from "./HCAForm1";
import REA from "./REA";
import Dashboard from "./Dashboard";
import Strategy1 from "./Strategy1";
import Strategy2 from "./Strategy2";
import Crypto1 from "./Crypto1";
import Crypto2 from "./Crypto2";
import CryptoOne from "./CryptoOne";
import TickerModels from "./TickerModels";
import TickerModels2 from "./TickerModels2";
import UsingReactPlotly from "./UsingReactPlotly";
import UsingPlotly from "./UsingPlotly";
import VolTrading from "./VolTrading";
import ModelsComp from "./ModelsComp";
import Dlfm from "./Dlfm";
import TickerBacktesting from "./TickerBacktesting";
import UsingAgGrid from "./UsingAgGrid";
import SectorRotationWeekly from './SectorRotationWeekly';
import SectorRotationDaily from './SectorRotationDaily';
import RegimeDetection from './RegimeDetection';


class Navbar extends Component {
    render() {
        return (
            <HashRouter>
                <Container>
                    {/* <nav class="navbar navbar-expand-lg navbar-dark bg-secondary"> */}
                    <nav class="navbar navbar-expand-lg navbar-light bg-light">
                        <span class="navbar-brand mb-0 h1">{/*<img src="images/cedge_logo2_150_50.png" alt=""/>/*/}</span>
                        <button class="navbar-toggler" type="button" 
                                data-toggle="collapse" data-target="#navbarText" 
                                aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarText">
                            {/* <ul class="navbar-nav mr-auto" id="header"> */}
                            <ul class="navbar-nav mr-auto " id="header">
                                <li class="nav-item">
                                    <Link class="nav-link" 
                                        to="/">Home</Link>
                                </li>
                                {/*<li class="nav-item">
                                    <Link  class="nav-link" 
                                        to="/portfolioform1">Portfolio</Link>
                                </li> 
                                <li class="nav-item">
                                    <Link  class="nav-link" 
                                        to="/dlfm">Factor Models </Link>
                                </li> */}
                                {/* <li class="nav-item">
                                    <Link class="nav-link" 
                                        to="/cryptoone">Crypto</Link>
                                </li> */}
                                {/* <li class="nav-item">
                                    <Link  class="nav-link" 
                                        to="/dashboard">All Cryptos </Link>
                                </li> */}
                                {/* <li class="nav-item">
                                    <Link  class="nav-link" 
                                        to="/tickerModels">Forecast</Link>
                                </li> 

                                <li class="nav-item">
                                    <Link  class="nav-link" 
                                        to="/modelsComp">Models Comp</Link>
                                </li> */}
                                {/* <li class="nav-item">
                                    <Link  class="nav-link" 
                                        to="/volTrading">Vol Trading </Link>
                                </li> */}
                                {/* <li class="nav-item">
                                    <Link  class="nav-link" 
                                        to="/hcaform1">Clustering</Link>
                                </li> */}
                                {/* <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic">
                                    Portfolio
                                </Dropdown.Toggle>
                                    <Dropdown.Menu >
                                        <Dropdown.Item href="#/portfolioform1">MV</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic">
                                    Forecasting Performance
                                </Dropdown.Toggle>
                                    <Dropdown.Menu >
                                        <Dropdown.Item href="#/tickerModels">Arima</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                                <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic">
                                    Research
                                </Dropdown.Toggle>
                                    <Dropdown.Menu >
                                        <Dropdown.Item href="#/dlfm">Deep Learning Factor Model</Dropdown.Item>
                                        <Dropdown.Item href="#/hcaform1">Hierarchical Clustering</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown> */}
                                <NavDropdown title="Market" id="basic-nav-dropdown">
                                    {/* <NavDropdown.Item href="#/SectorRotationWeekly">Sector Rotation - Weekly</NavDropdown.Item> */}
                                    <NavDropdown.Item href="#/SectorRotationDaily">Sector Rotation (Streamlit)</NavDropdown.Item>
                                    {/* <NavDropdown.Item href="#/RegimeDetection">Regime Detection (Dash)</NavDropdown.Item> */}
                                </NavDropdown>
                                <NavDropdown title="Portfolio Analysis" id="basic-nav-dropdown">
                                    <NavDropdown.Item href="#/portfolioform1analysis">Porfolio Status</NavDropdown.Item>
                                    <NavDropdown.Item href="#/portfolioform1">Optimize: Factor Neutal</NavDropdown.Item>
                                    <NavDropdown.Item href="#/portfoliostyleattribution">Style Attribution Analysis</NavDropdown.Item>
                                    {/* <NavDropdown.Item href="#action/3.2">
                                        Another action
                                    </NavDropdown.Item>
                                    <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item href="#action/3.4">
                                        Separated link
                                    </NavDropdown.Item> */}
                                </NavDropdown>
                                <NavDropdown title="Predictive Analytics" id="basic-nav-dropdown">
                                    <NavDropdown.Item href="#/modelsComp">Performance Comparison</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item href="#/tickerModels">Predictions</NavDropdown.Item>
                                    <NavDropdown.Item href="#/tickerModels2">Return Analysis (Streamlit)</NavDropdown.Item>
                                    <NavDropdown.Item href="#/tickerBacktesting">Backtesting</NavDropdown.Item>
                                </NavDropdown>
                                <NavDropdown title="Research" id="basic-nav-dropdown">
                                    {/* <NavDropdown.Item href="#/dlfm">Deep Learning Factor Model</NavDropdown.Item> */}
                                    <NavDropdown.Item href="#/hcaform1">Hierarchical Clustering</NavDropdown.Item>
                                </NavDropdown>

                                {/* <li class="nav-item">
                                    <Link  class="nav-link" 
                                        to="/UsingReactPlotly">RPlotly</Link>
                                </li>
                                <li class="nav-item">
                                    <Link  class="nav-link" 
                                        to="/UsingAgGrid">AgGrid</Link>
                                </li> */}
                                {/* <li class="nav-item">
                                    <Link  class="nav-link" 
                                        to="/strategy1">Strategy 1</Link>
                                </li> 
                                <li class="nav-item">
                                    <Link  class="nav-link" 
                                        to="/strategy2">Strategy 2</Link>
                                </li> 
                                <li class="nav-item">
                                    <Link  class="nav-link" 
                                        to="/crypto1">Crypto 1</Link>
                                </li>
                                <li class="nav-item">
                                    <Link  class="nav-link" 
                                        to="/crypto2">Crypto All</Link>
                                </li> 
                                 <li class="nav-item">
                                    <Link  class="nav-link" 
                                        to="/rea">REA</Link>
                                </li> */}

                            </ul>
                        </div>
                    </nav>
                </Container>
                <div  class="container" id='frontdoor'>
                    {/* <Route  exact path="/" component={Home} />  */}
                    <Route  exact path="/" component={Home} />
                    <Route  path="/dashboard/" component={Dashboard} />
                    <Route  path="/portfolioform1" component={PortFolioForm1} />
                    <Route  path="/portfolioform1analysis" component={PortFolioForm1Analysis} />
                    <Route  path="/portfoliostyleattribution" component={PortFolioStyleAttr} />
                    <Route  path="/dlfm" component={Dlfm} />
                    <Route  path="/hcaform1" component={HCAForm1} />
                    <Route  path="/tickerModels" component={TickerModels} />
                    <Route  path="/tickerModels2" component={TickerModels2} />
                    <Route  path="/tickerBacktesting" component={TickerBacktesting} />
                    <Route  path="/modelsComp" component={ModelsComp} />
                    <Route  path="/rea" component={REA} />
                    <Route  path="/strategy1" component={Strategy1} />
                    <Route  path="/strategy2" component={Strategy2} />
                    <Route  path="/crypto1" component={Crypto1} />
                    <Route  path="/crypto2" component={Crypto2} />
                    <Route  path="/cryptoone" component={CryptoOne} />
                    <Route  path="/UsingReactPlotly/" component={UsingReactPlotly} />
                    <Route  path="/SectorRotationWeekly/" component={SectorRotationWeekly} />
                    <Route  path="/SectorRotationDaily/" component={SectorRotationDaily} />
                    <Route  path="/RegimeDetection/" component={RegimeDetection} />
                    {/* <Route  path="/UsingAgGrid/" component={UsingAgGrid} /> */}

                    <Route  path="/UsingPlotly/" component={UsingPlotly} />
                    <Route  path="/voltrading/" component={VolTrading} />
                    {/* <Route  path="/contact" component={Contact} /> */}
                </div>
            </HashRouter>
        )
    }
}

export default Navbar;