import React, {Component} from 'react';
import Spinner from 'react-bootstrap/Spinner';
import {API_URL_CRYPTO1} from './config';
import axios from 'axios';
import {columns} from './predTableColumns';

import Chart_StockLine from './Chart_StockLine_intraday';
import BootstrapTable from 'react-bootstrap-table-next';

const defaultSorted = [{
    dataField : 'name',
    order: 'desc'
}];


class Crypto2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: 1, // 1: loading, 2: done
            data : null,
            predData: null,
            ticker_names: ["btc", "eth", "etc", "bch", "bsv", "ltc", "doge"],
            period: 'H',
            num_of_period: 1,
            prev_date : null,
            pred_date : null,
        }
    }
    componentDidMount() {
        axios.post(`${API_URL_CRYPTO1}`, {
            tickers: ["btc", "eth", "etc", "bch", "bsv", "ltc", "doge"],
            period: this.state.period,
            num_of_period: this.state.num_of_period
        })
        .then( (response) => {
            console.log(response);
            this.setState(
                prevState => ( {
                    loading: 2,
                    data: response.data['resdata'],
                    ticker_names:response.data['ticker_names'],
                    predData: response.data['predData'],
                    pred_price_date: response.data['pred_price_date']
                }),
                () => console.log(this.state)
            );
        })
        .catch( function(error) {
            console.log(error);
        })
        .then( function() {
            console.log("Fetch done");
        });
    }
    
    render() {
        if (this.state.loading == 2) {

            return (
                <div id="pf1"><h1>Crypto Price Predictions</h1>
                    <div class="alert alert-secondary" role="alert">
                        <h5>Available Cryptos</h5>
                        <p>BTC, ETH, ETC, BCH, BSV, LTC, DOGE</p>

                        <p>From 2021-1-22</p>
                    </div>
                    <h4>Prediction at : {this.state.pred_price_date}</h4>
                        <BootstrapTable 
                            keyField='model' 
                            data={ this.state.predData } 
                            columns={ columns } 
                            striped
                            hover
                            defaultSorted={ defaultSorted } 
                        />
                    <div>
                        {this.state.data.map((dd, index) =>
                            
                            //<Chart_StockLine key={index} title_text={"ticker : " + index} 
                            // <Chart_StockLine key={index} title_text={"ticker : " + this.state.ticker_r} 
                            <Chart_StockLine key={index} title_text={" " + this.state.ticker_names[index]} 
                            data = {dd}/>,
                            // <Chart_StockLine  title_text={"title_text"} 
                            // data = {dd[0]}/>
                            )
                            
                        }

                         {/* <Chart_StockLine  title_text={this.state.groupname} 
                                 data = {this.state.data}/>  */}
                    </div>
                    
                </div>

            );
        }else {//loading
            return (
                <div id="pf1"><h1>Crypto Price Predictions</h1>

                    <div class="alert alert-secondary" role="alert">
                    <h5>Available Cryptos</h5>
                        <p>BTC, ETH, ETC, BCH, BSV, LTC, DOGE</p>
                        <p>From 2021-1-22</p>
                    </div>
                    <div align="center">
                        <br/>
                        
                        <Spinner animation="border" variant="success" >
                            
                        </Spinner>
                        <br/>
                        <br/>

                    </div>
                </div>

            );

        }
    }
}


export default Crypto2 
