export const columns = [
    { dataField: 'ps',  text: 'P' , sort:true }, 
    { dataField: 'ds',  text: 'D' , sort:true }, 
    { dataField: 'qs',  text: 'Q' , sort:true }, 
    { dataField: 'lookback', text: 'lookback', sort:true }, 
    { dataField: 'wk_4~', text: 'wk4', sort:true }, 
    { dataField: 'wk_8~4', text: 'wk8-4', sort:true }, 
    { dataField: 'wk_12~8', text: 'wk12-8', sort:true }, 
    { dataField: 'wk_16~12', text: 'wk16-12', sort:true }, 
    { dataField: 'wk_12~', text: 'month3', sort:true }, 
    { dataField: 'wk_24~20', text: 'month6-3', sort:true }, 
    { dataField: 'pred_ret', text: 'Prediction', sort:true }, 

];
