import React, { Component } from 'react';

import './App.css';
import axios from 'axios';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

class Chart_Horizon_bar_sort extends React.Component {

    constructor(props) {
        super(props);
        

        this.state = {
            chart: {
                renderTo: this.props.renderTo,
                type: "bar",
                events: {
                    load: function() {
                        var points = this.series[0].points,
                        chart = this,
                        newPoints = [];

                    }
                }
                
            },
            title: {
                text: this.props.title_text
            },
            xAxis: {
                categories: this.props.categories
            },
            yAxis: {
                title: {
                    text: 'Values'
                }
            },
            tooltip: {
                valueSuffix: '%'
            },
            plotOptions: {
                series: {
                    //stacking: 'normal',
                    dataLabels: {
                                enabled:true,
                                format: '{point.name}: {point.percentage:.4f} %'
                    }
                }
                // bar: {
                //     dataLabels: {
                //         enabled:true
                //         //format: '{point.name}: {point.percentage:.2f} %'
                //     }
                // }
              },
            series: [
                {
                    name : this.props.title_text,
                    data : this.props.data
                    //  [
                    //       {
                    //         name: 'Argon',
                    //         y: 0.9,
                    //         // color: '#3498db'
                    //       },
                    //       {
                    //         name: 'Nitrogen',
                    //         y: 78.1,
                    //         // color: '#9b59b6'
                    //       },
                    //       {
                    //         name: 'Oxygen',
                    //         y: 20.9,
                    //         // color: '#2ecc71'
                    //       },
                    //       {
                    //         name: 'Trace Gases',
                    //         y: 0.1,
                    //         // color: '#f1c40f'
                    //       }
                    //     ]
                }
            ]
        }
        console.log(this.props.data);

                
    }

    

    // shouldComponentUpdate(newProps, newState) {
    //     console.log("shouldComponentUpdate: Should component update?");
    //     console.log(this.state.series);
    //     console.log(newState.series);
    //     if (this.state.series !== newState.series) {
    //       console.log("shouldComponentUpdate: Component should update!");
    //       return true;
    //     } else {
    //       //ReactDOM.unmountComponentAtNode(destination);
    //       console.log("shouldComponentUpdate: Component should not update!");
    //       return false;
    //     }
    // }

    render() {
        return (
            <div >
                <HighchartsReact 
                highcharts={Highcharts} 
                options={this.state} />
            </div>

        )
    } 
}
export default Chart_Horizon_bar_sort;