import React, { Component } from 'react';

import './App.css';
import axios from 'axios';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

class Chart_Horizon_bar_multi extends React.Component {

    constructor(props) {
        super(props);
        

        this.state = {
            chart: {
                renderTo: this.props.renderTo,
                type: "bar"
                
            },
            title: {
                text: this.props.all_title_text
            },
            xAxis: {
                categories: this.props.categories
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled:false,
                        //format: '{point.name}: {point.percentage:.4f} %'
                        format: '{point.name}: {point.y:.4f} %' 
                    }
                }
            },
            tooltip: {
                headerFormat: '<b>{series.name}</b><br>',
                pointFormat: '{point.y:,.4f} '
            },
            // tooltip: {
            //     // formatter: function () {
            //     //     return this.points.reduce(function (s, point) {
            //     //         return s + '<br/>' + point.series.name + ': ' +
            //     //             point.y + 'm';
            //     //     }, '<b>' + this.x + '</b>');
            //     // },
            //     // shared: true
               
            // },
            series: [
                {
                    name : this.props.title_text,
                    data : this.props.data
                },
                {
                    name : this.props.title_text2,
                    data : this.props.data2
                },
                {
                    name : this.props.title_text3,
                    data : this.props.data3
                }

            ]
        }
        console.log(this.props.data);

    }

    

    // shouldComponentUpdate(newProps, newState) {
    //     console.log("shouldComponentUpdate: Should component update?");
    //     console.log(this.state.series);
    //     console.log(newState.series);
    //     if (this.state.series !== newState.series) {
    //       console.log("shouldComponentUpdate: Component should update!");
    //       return true;
    //     } else {
    //       //ReactDOM.unmountComponentAtNode(destination);
    //       console.log("shouldComponentUpdate: Component should not update!");
    //       return false;
    //     }
    // }

    render() {
        return (
            <div >
                <HighchartsReact 
                highcharts={Highcharts} 
                options={this.state} />
            </div>

        )
    } 
}
export default Chart_Horizon_bar_multi;