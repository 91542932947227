import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Spinner from 'react-bootstrap/Spinner'
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import './App.css';
import axios from 'axios';

import ChInput from './ChInput';
import ChButton from './ChButton';

import Chart_StockLine from './Chart_StockLine';
import { numberFormat } from 'highcharts';

class REA extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            county: 'union',
            city: 'summit',
            loading: 0, // 0 : not started, 1: loading, 2: done
            listings: [],
            columns: [
                {
                dataField: 'state',
                text: 'State'
                },
                {
                dataField: 'county',
                text: 'County'
                }, 
                {
                dataField: 'city',
                text: 'City',
                },
                {
                dataField: 'owner_address',
                text: 'Address',
                },
                {
                dataField: 'block',
                text: 'Block',
                },
                {
                dataField: 'lot',
                text: 'Lot',
                },
                {
                dataField: 'qual',
                text: 'qual',
                },
                {
                dataField: 'owner_name',
                text: 'Owner',
                formatter: (cell, row) => {
                    var u ="/#/dashboard/?block=" + row.block + "&state=" + row.state 
                        + "&county=" + row.county + "&city=" + row.city 
                        + "&lot=" + row.lot + "&qual=" + row.qual; 
                    return <p><a href={u} target="_blank"> {cell}</a></p>;
                  },
                filter: textFilter()

                },
                {
                dataField: 'amount',
                text: 'Tax Amount Owed',
                formatter: (cell, row) => {
                    return <p>${cell}</p>;
                  },
                sort: true,
                // sortValue: (cell, row) => this.state.listings[cell]
                }
            ]
        };
        this.handleClick = this.handleClick.bind(this);
        this.handleSelectCounty = this.handleSelectCounty.bind(this);
        this.handleSelectCity = this.handleSelectCity.bind(this);

        // When row is clicked, it works but, is this good behavior????
        // this.rowEvents = {
        //     onClick: (e, row, rowIndex) => {
        //         window.open('http://www.chris-h.site');
        //     }
        // };
 
    }
    handleSelectCounty(e) {
        let value = e.target.value;
        
        this.setState( 
            prevState => ( {
                county: value
            }),
            () => console.log(this.state.county)
        );
        if (value === "union") {
            this.setState( 
                prevState => ( {
                    county: "union",
                }),
                () => console.log(this.state.county)
            );
        } 

    }
    handleSelectCity(e) {
        let value = e.target.value;
        
        this.setState( 
            prevState => ( {
                city: value
            }),
            () => console.log(this.state.city)
        );
        if (value === "summit") {
            this.setState( 
                prevState => ( {
                    city: "summit"
                }),
                () => console.log(this.state.city)
            );
        } 

    }
    handleClick(e) {
       
        e.preventDefault(); 
        // if (!this.state.valid_ticker || !this.state.valid_date) {
        
        
        this.setState( 
            prevState => ( {
                loading: 1,
            })
        );
        //var self = this;
        // axios.get function will lose the context of 'this'. So, copy it into
        // var self == this. then use self when update the state lilke 
        // "self.setState( {  .... } );". this properly point this component.
        axios.post("http://localhost:5000/api/rea1", {
                county: this.state.county,
                city: this.state.city
            })
            //.then(function(response) {
            .then( (response) => {
                console.log(response);
                console.log("here is the  data");
                console.log(response.data);
                
                if  (response.data.length === 2){
                    this.setState (
                        prevState => ( {
                            loading: 2
                        }),
                        () => console.log(this.state)
                    );
                }
                else {
                    this.setState (
                        prevState => ( {
                            loading: 2,
                            listings: response.data
                        }),
                        () => console.log(this.state)
                    );
                }

                console.log("inside call back");
                console.log(this.state.data);
                    
            })
            .catch(function (error) {
                
                console.log(error);
            })
            .then(function() {
                console.log('Fetch done');
            });
    }

    

    render() {
        //const {showChart} = this.state.data;
        if (this.state.loading === 2) {  // data ready and display

            return ( 
                <div id="pf1">
                    <h1>REA 1</h1>
                    <div class="alert alert-secondary" role="alert">
                        <h5>Universe</h5>
                        <p>New Jersey</p>
                    </div>
                    <div className="container" style={{ marginTop: 50 }}>
                        <div className="form1_input">
                            <form onSubmit={this.handleClick}>
                                <label>
                                    County:
                                    <select value={this.state.county} onChange={this.handleSelectCounty}>
                                        <option value="union">Union</option>
                                    </select>
                                </label>
                                <br></br>
                                <label>
                                    City:
                                    <select value={this.state.city} onChange={this.handleSelectCity}>
                                        <option value="summit">Summit</option>
                                    </select>
                                </label>

                                <ChButton
                                    action={this.handleClick}
                                    type={"primary"}
                                    title={"Submit"}
                                    style={buttonStyle}
                                />{" "}
                                
                            </form>

                        </div>

                        
                        {/* const rowEvents = {
                            //window.location = url;
                            onClick: (e, row, rowIndex) => {
                                window.open('http://www.chris-h.site');
                            }
                        };  */}

                        <BootstrapTable 
                        striped
                        hover
                        keyField='_id' 
                        data={ this.state.listings } 
                        columns={ this.state.columns } 
                        filter={ filterFactory() }
                        pagination={ paginationFactory() }
                        rowEvents={ this.rowEvents }/>
                    </div>

                    
                </div>

                
                    
            );

        } else if(this.state.loading === 1) { // loading
            return (
                <div id="pf1">
                    <h1>REA 1</h1>
                    <div class="alert alert-secondary" role="alert">
                        <h5>Universe</h5>
                        <p>New Jersey</p>
                    </div>
                    <div className="form1_input">
                        <form onSubmit={this.handleClick}>
                            <label>
                                County:
                                <select value={this.state.county} onChange={this.handleSelectCounty}>
                                    <option value="union">Union</option>
                                </select>
                            </label>
                            <br></br>
                            <label>
                                City:
                                <select value={this.state.city} onChange={this.handleSelectCity}>
                                    <option value="summit">Summit</option>
                                </select>
                            </label>

                            <ChButton
                                action={this.handleClick}
                                type={"primary"}
                                title={"Submit"}
                                style={buttonStyle}
                            />{" "}
                            
                        </form>

                    </div>
                    
                </div>
                
            );
        }
        else { // not started
            return ( 
                <div id="pf1">
                    <h1>REA 1</h1>
                    <div class="alert alert-secondary" role="alert">
                    <h5>Universe</h5>
                        <p>New Jersey</p>
                    </div>

                    <div className="form1_input">
                        <form onSubmit={this.handleClick}>
                            <label>
                                County:
                                <select value={this.state.county} onChange={this.handleSelectCounty}>
                                    <option value="union">Union</option>
                                    <option value="bergen">Bergen</option>
                                </select>
                            </label>
                            <br></br>
                            <label>
                                City:
                                <select value={this.state.city} onChange={this.handleSelectCity}>
                                    <option value="summit">Summit</option>
                                    <option value="fort lee">Fort Lee</option>
                                </select>
                            </label>

                            <ChButton
                                action={this.handleClick}
                                type={"primary"}
                                title={"Submit"}
                                style={buttonStyle}
                            />{" "}
                            
                        </form>

                    </div>

                   
                    
                </div>
            );
        }
    }
}

const buttonStyle = {
    margin: "10px 10px 10px 10px"
 };

export default REA;