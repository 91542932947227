import React, { Component } from "react";
import Footer from "./Footer";
 
class Home extends Component {
    render() {
        return (
        <div class="container" id='frontdoor'>
            <div class="jumbotron">
                {/* <h1 class="display-4">Chris Hwang </h1>  */}
                <h1 class="disply-4">Quantitative Investment Research Platform</h1>
                <p class="lead">This website serves as a preliminary version of a platform for 
                quantitative investment research built by Chris IJ Hwang [<a href="http://www.linkedin.com/in/chrisijh" target="_blank">
      <i class="fa fa-linkedin"></i></a>]. It features three main sections - an application section 
                that focuses on Portfolio and Predictive Analytics and a research section that deals 
                with the application of various academic papers. 

                </p>
                
               {/* <p class="lead">I am a Quantitative Analyst/Developer and Data Scientist with experience in Finance, IT, and Education industry. 
                This site contains some of the demo apps that I have worked on. 
                Some other works can be found at my github page <a href="http://chrisijh.github.io" target="_blank">here.</a> </p> */}

                
                <hr class="my-4"/>
                {/* <p>If you have any question, feel free to contact me at ih138 at columbia dot edu</p> */}
                {/* <a class="btn btn-primary btn-lg" href="#" role="button">Learn more</a> */}
            </div>

            <div >
                {/* <h1>Architecture</h1>
                    <img src="https://www.evernote.com/shard/s484/sh/57a9dc1b-35d7-42bd-9c31-d1acb8a74057/VXlvVUHKIaZDg7X8u0ftDQSm-MyouyNJYGQrw5PjyntuORKgOf6IXS_omg/deep/0/image.png" alt="Platform" style={{width: "100%", height:  "auto" }}/> */}
                <h1>Universe</h1>
                    
                    <li>Over 2500 tickers in U.S. Stock Market</li>
                        <ul><strong>Data Sources</strong>
                            <li>Yahoo Finance</li>
                            <li>EOD historical data</li>
                        </ul>

                    <ul><strong>Factors</strong>
                    <li>Time Series Factors</li>
                        <ul>
                        <li>Fama-French Factors</li>
                            <ul>
                                <li>SMB (Small Minus Big)</li>
                                <li>HML (High Minus Low)</li>
                                <li>Rm-Rf, the excess return on the market</li>
                                <li>RMW (Robust Minus Weak)</li>
                                <li>CMA (Conservative Minus Aggressive)</li>
                            </ul>
                        <li>Russell indexes</li>
                            <ul>
                                <li>Russell 1000 value: Mid Cap, Value (RLV)</li>
                                <li>Russell 1000 growth: Mid Cap, Growth (RLG)</li>
                                <li>Russell 2000 value: Small Cap, Value (RUT)</li>
                                <li>Russell 2000 growth: Small Cap, Growth (RUO)</li>
                            </ul>
                        </ul>
                    <li>Cross-Sectional Factors</li>
                        <ul>

                        <li>Custom Fundamental Factors(to be added)</li>
                        </ul>
                    </ul>
                
                <br></br>
                <hr></hr>
                <h1>Content</h1>
                <br></br>
                    <hr></hr>
                    <h2>Market Summary</h2>
                    <br></br>
                        <h4>Sector Rotation</h4><a href="http://cedge.us/#/SectorRotationDaily" target="_blank">Link</a>
                             
                            <p>Information of Sector performance per</p>
                            <ul>
                                <li>GicSector</li>
                                <li>GicGroup</li>
                                <li>GicIndustry</li>
                                <li>GicSubIndustry</li>
                            </ul>
                            <p>Equal-Weight vs. Market-Cap-Weight</p>



                    <hr></hr>
                    <h2>Portfolio Analysis</h2>
                    <br></br>
                        <h4>Portfolio Status</h4><a href="http://cedge.us/#/portfolioform1analysis" target="_blank">Link</a>

                            <p>Portfolio Optimization displaying the current factor/sector exposures and the past performances.</p>

                            
                        <h4>Portfolio Optimization</h4><a href="http://cedge.us/#/portfolioform1" target="_blank">Link</a>
                            <p>Once the current status of the portfoilo is displayed, the portfolio will be optimized in the optimization page.
                                Currently Factor Neutral optimizaion is available. More optimization will be provided.</p>
                        <h4>Style Attribution Analysis</h4><a href="http://cedge.us/#/portfoliostyleattribution" target="_blank">Link</a>
                            <p>Identify the styles that can be associated with the major risk factors in a specific mutual fund.</p>
                            <p>Curently Available Mutual Fund are :</p>
                            <p>ABALX, AGTHX, FXAIX, VBTLX, VFFSX, VFIAX, VGTSX, VIIIX, VINIX, VMFXX, VSMPX, VTBIX, VTBNX, VTSAX</p>
                    <hr></hr> 
                    <h2>Predictive Analytics</h2>
                    <br></br>
                        <h4>Model Comparison</h4><a href="http://cedge.us/#/modelsComp" target="_blank">Link</a>
                            {/* <p>With various parameters per model, collect historic hit ratio. Based on Beta distribution, 
                                compute the probability of the hit ratio is over certain probability.
                            </p> */}
                            <p>Compare the confidence of the prediction model of difference probability per sectors and different parameters by aggregating all performances available.</p>
                            <p>Methodology</p> 
                            <ul>
                                <li>Bayesian Inference</li>
                                <li>Based on hit ratio</li>
                                <li>Likelihood: Past hit ratio as Beta Distribution</li>
                                <li>Prior: Non-informative</li>
                                <li>Based on Posterior Distribution, there is a better than the probability of [ ] that the chance of the correct prediction is greater than the probability of [ ]</li>
                            </ul>


                        <h4>Predictions</h4><a href="http://cedge.us/#/tickerModels" target="_blank">Link</a>
                            <p>Display the latest prediction(weekly and daily) per model</p>
                            <ul>
                                <li>ARIMA</li>
                                {/* <li>Long Short-Term Memory Networks(LSTM)</li> */}
                            </ul>

                    <hr></hr>
                    <h2>Research</h2>
                    <br></br>
                        {/* <p>Deep Fundamental Factor Models - Matthew F. Dixon, Nicholas G. Polson : <span><a href="http://cedge.us/#/dlfm" target="_blank">Link</a></span></p> */}
                        
                        <p>Building Diversified Portfolios that Outperform Out-of-Sample - Marcos Lopez de Prado : <a href="http://cedge.us/#/hcaform1" target="_blank">Link</a></p>
                        

            </div> 
            <hr></hr>
            <Footer />
            {/* <footer class="mastfoot mt-auto">
                            <div class="inner">
                                <p>© 2023 Copyright Chris IJ Hwang</p>
                            </div>
                            <p>DISCLAIMER: The information in this site is for the purpose of research and backtest only. We don't promise any future profits and don't take responsibility for any trading losses.
                            </p>
            </footer> */}
        </div>
        );
    }


}

export default Home;