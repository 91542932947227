/*
버튼 누르면 select 된거  model result hit ratio 제공

*/


import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Spinner from 'react-bootstrap/Spinner'
import {API_URL_MARKET} from './config'
import './App.css';
import axios from 'axios';

import ChInput from './ChInput';
import ChButton from './ChButton';
import Select from 'react-select';
import { modelOptions } from './cryptoList';

// import {columns} from './tickerModelsTableColumns';
import {table_columns} from './MarketTableColumns';
import {lstmcolumns} from './tickerModelsTableLSTMColumns';
import {columnsMth} from './tickerModelsTableColumnsMth';
import {lstmcolumnsMth} from './tickerModelsTableLSTMColumnsMth';
import {columns2} from './tickerModelsTableColumns2';

// import Chart_Donut from './Chart_Donut';
// import Chart_Donut2 from './Chart_Donut2';
// import Chart_Column_bar from './Chart_Column_bar';
// import Chart_Horizon_bar_sort from './Chart_Horizon_bar_sort';
// import Chart_Horizon_bar_multi from './Chart_Horizon_bar_multi';
// import Chart_Scatter from './Chart_Scatter';
// import Chart_StockLine from './Chart_StockLine';
import Chart_StockLine from './Chart_StockLine_intraday';

import BootstrapTable from 'react-bootstrap-table-next';

const defaultSorted = [{
    dataField: 'name',
    order: 'desc'
  }];

class SectorRotationWeekly extends React.Component {
    constructor(props) {
        super(props);
        // var options = {
        //     chart: {
        //         type: "pie"
        //     },
        //     series: [
        //         {
        //             name : "sector",
        //             data : this.state.data
        //         }
        //     ]
        // },

        this.state = {
            is_updated: false,
            loading: 0, // 0 : not started, 1: loading, 2: done
            data: null,
            valid_ticker: true,
            selectedOption: null,
            predData: [
                {'ticker': "BTC", 'prev_price': 1000, 'pred_price': 1100, 'ret': '10%'},
                {'ticker': "ETH", 'prev_price': 1000, 'pred_price': 1100, 'ret': '10%'},
            ],
            market_rotation: null,
            market_columns: null,
            // gic: "GicGroup",
            gic: "GicSector",
            table_column: [],

            period: 'H',
            num_of_period: 1,
            prev_date : null,
            pred_date : null,
            is_505_error: false
        };

        console.log("this.state.is_updated: ", this.state.is_updated);

        this.handleClick = this.handleClick.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.handleInputStartDate = this.handleInputStartDate.bind(this);
        this.handleInputEndDate = this.handleInputEndDate.bind(this);
        this.handleGicSelect = this.handleGicSelect.bind(this);
        // this.handleSelect = this.handleSelect.bind(this);
        // this.handleStateChange = this.handleStateChange.bind(this);

    }
    

    handleClick(e) {
        e.preventDefault(); 
        // if (!this.state.valid_ticker || !this.state.valid_enddate || !this.state.valid_startdate) {
        //     return;
        // }
        console.log(this.state.strat_ar);
        this.setState( 
            prevState => ( {
                loading: 1,
            })
        );
        // axios.get function will lose the context of 'this'. So, copy it into
        // var self == this. then use self when update the state lilke 
        // "self.setState( {  .... } );". this properly point this component.
        //axios.post("http://100.1.58.77:5000/api/strategy1", {
        axios.post(`${API_URL_MARKET}`, {
                gic: this.state.gic
                // period: this.state.period,
                // num_of_period: this.state.num_of_period
            })
            //.then(function(response) {
            .then( (response) => {
                console.log(response);
                this.setState (
                    prevState => ( {
                        loading: 2, // done. received response
                        //groupname: response.data[0]['name'],
                        //data: response.data
                        
                        market_rotation : response.data['market_rotation'],
                        market_columns : response.data['market_columns'],
                        is_505_error: false
                        // predData2 : response.data['predData2'],
                        // prev_price_date2: response.data['prev_price_date2'],
                    }),
                    () => console.log(this.state)
                );
                console.log("inside call back");
                var columns = [];
                for (var key in this.state.market_rotation[0]) {
                    columns.push({dataField: key, text: key, sort: true});
                };  

                // this.state.table_column.unshift(...this.state.table_column.splice(-1));

            })
            // .catch(function (error) {
            .catch(err => {
                // console.log(error);
                console.log("EEEEEEEERRRRRRORRR");
                this.setState (
                    prevState => ( {
                        loading: 2,
                        is_505_error: true
                    }),
                    () => console.log(this.state.is_505_error)
                );
            })
            .then(function() {
                console.log('Fetch done');
            });
    }

    handleInput(e) {
        let value = e.target.value;
        this.setState (
            prevState => ( {
                ticker_r: value,
                valid_ticker: value.trim().split(' ').length > 0
            }),
            () => console.log(this.state.ticker_r)
        );

        //e.preventDefault();
    }

    handleInputStartDate(e) {
        let value = e.target.value;
        
        this.setState( 
            prevState => ( {
                start_date: value,
                valid_startdate: (Date.parse(value) >= Date.parse("2010-01-01")) && (Date.parse(value) <= Date.parse("2021-2-2") ),
                date_prob_tockers: []
            }),
            () => console.log(this.state.start_date)
        );
    }

    handleInputEndDate(e) {
        let value = e.target.value;
        
        this.setState( 
            prevState => ( {
                end_date: value,
                valid_enddate: ( Date.parse(value) > Date.parse(this.state.start_date)) && ( Date.parse(value) <= Date.parse("2021-01-29") ),
                date_prob_tockers: []
            }),
            () => console.log(this.state.end_date)
        );
    }

    handleStrat = selectedOption =>{
        this.setState(
            {selectedOption: selectedOption},
            () => console.log('Options selected:', this.state.selectedOption)

        );
    };
    
    handleGicSelect(e){
        let value = e.target.value;
        this.setState(
            prevState => ({
                gic: value
            }),
            () => console.log(this.state.gic)
        );
        if (value==='GicGroup'){
            this.setState(
                prevState => ({
                    msg1: "GicGroup"
                }),
                () => console.log(this.state.msg1)
            );
        }

    }
    render() {
        const is_505_error =this.state.is_505_error;
        console.log("is_505_error:", is_505_error);

        //const {showChart} = this.state.data;
        if (this.state.loading === 2) {  // data ready and display
            if (is_505_error){

                return (
                        <div id="pf1"><h1>Market Summary</h1>

                        {/* <a href="http://100.1.58.77:3000/#/crypto1/" target="_blank">open new window</a> */}
                        <div class="alert alert-primary" role="alert">
                            <h5>Overview</h5>
                           

                        </div>
                        <div className="form1_input">
                            <form onSubmit={this.handleClick}>
                                <label>
                                    GIC: 
                                    <select value={this.state.gic} onChange={this.handleGicSelect}>
                                        <option value="GicGroup">GicGroup</option>

                                    </select>
                                </label> 

                                <ChButton
                                    action={this.handleClick}
                                    type={"btn-outline-primary"}
                                    title={"Run"}
                                    style={buttonStyle}
                                />{" "}
                                {/* <div class="alert alert-primary" role="alert">
                                {this.state.msg}
                                </div>
                                {this.state.is_feasible ? null : <div class="alert alert-danger" role="alert">
                                                                {"The optimization is not feasible!"}
                                                                </div> 
                                } */}
                            </form>
                        </div>
                        
                        <div>
                            {/* <iframe src="error500.html" frameborder="0" scrolling="no" onload="resizeIframe(this)" ></iframe> */}
                            <iframe src="error500.html" frameborder="0" scrolling="no" height="600" width="800" ></iframe>

                        </div>
                        <footer class="mastfoot mt-auto">
                                <div class="inner">
                                    <p>© 2023 Copyright Chris IJ Hwang</p>
                                </div>
                                <p>DISCLAIMER: The information in this site is for the purpose of research and backtest only. I don't promise any future profits and don't take responsibility for any trading losses.
                                </p>
                        </footer>

                        </div>
                );

            } // 505 error if end
            else { // 505 error else


                return ( 
                    <div id="pf1"><h1>Market Summary</h1>

                        {/* <a href="http://100.1.58.77:3000/#/crypto1/" target="_blank">open new window</a> */}
                        <div class="alert alert-primary" role="alert">
                            <h5>Overview</h5>
                           

                        </div>
                        <div className="form1_input">
                            <form onSubmit={this.handleClick}>
                                <label>
                                    GIC: 
                                    <select value={this.state.gic} onChange={this.handleGicSelect}>
                                        <option value="GicGroup">GicGroup</option>

                                    </select>
                                </label> 

                                <ChButton
                                    action={this.handleClick}
                                    type={"btn-outline-primary"}
                                    title={"Run"}
                                    style={buttonStyle}
                                />{" "}
                                {/* <div class="alert alert-primary" role="alert">
                                {this.state.msg}
                                </div>
                                {this.state.is_feasible ? null : <div class="alert alert-danger" role="alert">
                                                                {"The optimization is not feasible!"}
                                                                </div> 
                                } */}
                            </form>

                            <h3>Market Rotation</h3>

                            <BootstrapTable 
                                keyField='model' 
                                data={ this.state.market_rotation} 
                                // columns={ table_columns} 
                                columns={this.state.market_columns}
                                // columns={ columns} 
                                striped
                                hover
                                defaultSorted={ defaultSorted } 
                            />

                        </div>
                        
                        <div>
                            {/* {this.state.data.map((dd, index) =>
                                
                                //<Chart_StockLine key={index} title_text={"ticker : " + index} 
                                // <Chart_StockLine key={index} title_text={"ticker : " + this.state.ticker_r} 
                                <Chart_StockLine key={index} title_text={" " + this.state.ticker_names[index]} 
                                data = {dd}/>,
                                // <Chart_StockLine  title_text={"title_text"} 
                                // data = {dd[0]}/>
                                )
                                
                            } */}

                            {/* <Chart_StockLine  title_text={this.state.groupname} 
                                    data = {this.state.data}/>  */}
                        </div>
                        <footer class="mastfoot mt-auto">
                        <div class="inner">
                                    <p>© 2023 Copyright Chris IJ Hwang</p>
                                </div>
                                <p>DISCLAIMER: The information in this site is for the purpose of research and backtest only. I don't promise any future profits and don't take responsibility for any trading losses.
                                </p>
                            </footer>
                        
                    </div>
                );
            } // 505 error else

        } else if(this.state.loading === 1) { // loading
            return (
                <div id="pf1"><h1>Prediction</h1>
                    {/* <a href="http://100.1.58.77:3000/#/crypto1/" target="_blank">open new window</a> */}

                    <div class="alert alert-primary" role="alert">
                        <h5>Overview</h5>
                           
                    </div>
                    <div className="form1_input">
                        <form onSubmit={this.handleClick}>

                            <label>
                                GIC: 
                                <select value={this.state.gic} onChange={this.handleGicSelect}>
                                    <option value="GicGroup">GicGroup</option>

                                </select>
                            </label> 

                            {/* <ChButton
                                action={this.handleClick}
                                type={"primary"}
                                title={"Start Predict"}
                                style={buttonStyle}
                            />{" "} */}
                            {/* <div class="alert alert-primary" role="alert">
                            {this.state.msg}
                            </div> */}
                        </form>
                    </div>
                    <div align="center">
                        <br/>
                        
                        <Spinner animation="border" variant="success" >
                            
                        </Spinner>
                        <br/>
                        <br/>
                        <br/>
                        <br/>

                    </div>
                    <footer class="mastfoot mt-auto">
                    <div class="inner">
                                <p>© 2023 Copyright Chris IJ Hwang</p>
                            </div>
                            <p>DISCLAIMER: The information in this site is for the purpose of research and backtest only. I don't promise any future profits and don't take responsibility for any trading losses.
                            </p>
                        </footer>
                </div>
                
            );
        }
         else { // not started
            return ( 
                <div id="pf1"><h1>Market Summary</h1>
                    {/* <a href="http://100.1.58.77:3000/#/crypto1/" target="_blank">open new window</a> */}

                    <div class="alert alert-primary" role="alert">
                        <h5>Overview</h5>
                           
                    </div>
                    <div className="form1_input">
                        {/* <form onSubmit={this.handleClick}>

                            <label>
                                GIC: 
                                <select value={this.state.gic} onChange={this.handleGicSelect}>
                                    <option value="GicGroup">GicGroup</option>

                                </select>
                            </label> 
                            <ChButton
                                action={this.handleClick}
                                type={"btn-outline-primary"}
                                title={"Run"}
                                style={buttonStyle}
                            />{" "}
                            
                            {/* <div class="alert alert-primary" role="alert">
                            {this.state.msg}
                            </div> */}
                            
                        {/* </form> */}
                        {/* <BootstrapTable 

                            keyField='model' 
                            data={ products } 
                            columns={ columns } 
                            striped
                            hover
                            defaultSorted={ defaultSorted } 
                        /> */}
                    </div>

                    <div>

                    {/* <iframe src="http://68.173.160.106:8050/sector-rotation-state" width={1100} height={1700} loading='eager'/> */}
                    <iframe src="http://68.173.160.106:8050" width={1100} height={1700} loading='eager'/>
                    </div>
                    <footer class="mastfoot mt-auto">
                            <div class="inner">
                                <p>© 2023 Copyright Chris IJ Hwang</p>
                            </div>
                            <p>DISCLAIMER: The information in this site is for the purpose of research and backtest only. I don't promise any future profits and don't take responsibility for any trading losses.
                            </p>
                    </footer>
                </div>
            );
        }
    }
}

const buttonStyle = {
    margin: "10px 10px 10px 10px"
 };

export default  SectorRotationWeekly;