import React, { Component } from 'react';

import './App.css';
;


const ChButton = props => {

    return (
        <button 
            style={props.style}
            className={
                props.type=="primary" ? "btn btn-primary" : "btn btn-secondary"
            }
            onClick={props.action}
        >
            {props.title}
        </button>
    );
};



export default ChButton;