/*
버튼 누르면 7개 모두 hourly pred 제공

*/


import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Spinner from 'react-bootstrap/Spinner'
import {API_URL_CRYPTO1} from './config'
import './App.css';
import axios from 'axios';

import ChInput from './ChInput';
import ChButton from './ChButton';
import Select from 'react-select';
import { modelOptions } from './cryptoList';

import {columns} from './predTableColumns';

// import Chart_Donut from './Chart_Donut';
// import Chart_Donut2 from './Chart_Donut2';
// import Chart_Column_bar from './Chart_Column_bar';
// import Chart_Horizon_bar_sort from './Chart_Horizon_bar_sort';
// import Chart_Horizon_bar_multi from './Chart_Horizon_bar_multi';
// import Chart_Scatter from './Chart_Scatter';
// import Chart_StockLine from './Chart_StockLine';
import Chart_StockLine from './Chart_StockLine_intraday';

import BootstrapTable from 'react-bootstrap-table-next';

const defaultSorted = [{
    dataField: 'name',
    order: 'desc'
  }];

class Crypto1 extends React.Component {
    constructor(props) {
        super(props);
        // var options = {
        //     chart: {
        //         type: "pie"
        //     },
        //     series: [
        //         {
        //             name : "sector",
        //             data : this.state.data
        //         }
        //     ]
        // },


        this.state = {
            is_updated: false,
            loading: 0, // 0 : not started, 1: loading, 2: done
            data: null,
            valid_ticker: true,
            selectedOption: null,
            predData: [
                {'ticker': "BTC", 'prev_price': 1000, 'pred_price': 1100, 'ret': '10%'},
                {'ticker': "ETH", 'prev_price': 1000, 'pred_price': 1100, 'ret': '10%'},
            ],
            ticker_names: null,
            period: 'H',
            num_of_period: 1,
            prev_date : null,
            pred_date : null,

        };

        console.log("this.state.is_updated: ", this.state.is_updated);

        this.handleClick = this.handleClick.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.handleInputStartDate = this.handleInputStartDate.bind(this);
        this.handleInputEndDate = this.handleInputEndDate.bind(this);
        // this.handleSelect = this.handleSelect.bind(this);
        // this.handleStateChange = this.handleStateChange.bind(this);

    }
    

    handleClick(e) {
        e.preventDefault(); 
        // if (!this.state.valid_ticker || !this.state.valid_enddate || !this.state.valid_startdate) {
        //     return;
        // }
        console.log(this.state.strat_ar);
        this.setState( 
            prevState => ( {
                loading: 1,
            })
        );
        // axios.get function will lose the context of 'this'. So, copy it into
        // var self == this. then use self when update the state lilke 
        // "self.setState( {  .... } );". this properly point this component.
        //axios.post("http://100.1.58.77:5000/api/strategy1", {
        axios.post(`${API_URL_CRYPTO1}`, {
                tickers: this.state.selectedOption,
                period: this.state.period,
                num_of_period: this.state.num_of_period
            })
            //.then(function(response) {
            .then( (response) => {
                console.log(response);
                this.setState (
                    prevState => ( {
                        loading: 2, // done. received response
                        //groupname: response.data[0]['name'],
                        //data: response.data
                        data: response.data['resdata'],
                        ticker_names : response.data['ticker_names'],
                        predData : response.data['predData'],
                        pred_price_date: response.data['pred_price_date']
                    }),
                    () => console.log(this.state)
                );
                console.log("inside call back");
            })
            .catch(function (error) {
                console.log(error);
            })
            .then(function() {
                console.log('Fetch done');
            });
    }

    handleInput(e) {
        let value = e.target.value;
        this.setState (
            prevState => ( {
                ticker_r: value,
                valid_ticker: value.trim().split(' ').length > 0
            }),
            () => console.log(this.state.ticker_r)
        );

        //e.preventDefault();
    }

    handleInputStartDate(e) {
        let value = e.target.value;
        
        this.setState( 
            prevState => ( {
                start_date: value,
                valid_startdate: (Date.parse(value) >= Date.parse("2010-01-01")) && (Date.parse(value) <= Date.parse("2021-2-2") ),
                date_prob_tockers: []
            }),
            () => console.log(this.state.start_date)
        );
    }

    handleInputEndDate(e) {
        let value = e.target.value;
        
        this.setState( 
            prevState => ( {
                end_date: value,
                valid_enddate: ( Date.parse(value) > Date.parse(this.state.start_date)) && ( Date.parse(value) <= Date.parse("2021-01-29") ),
                date_prob_tockers: []
            }),
            () => console.log(this.state.end_date)
        );
    }

    handleStrat = selectedOption =>{
        this.setState(
            {selectedOption: selectedOption},
            () => console.log('Options selected:', this.state.selectedOption)

        );
    };

    render() {
        //const {showChart} = this.state.data;
        if (this.state.loading === 2) {  // data ready and display

            return ( 
                <div id="pf1"><h1>Crypto Prediction Models</h1>
                    <a href="http://100.1.58.77:3000/#/crypto1/" target="_blank">open new window</a>
                    <div class="alert alert-secondary" role="alert">
                        <h5>Available Cryptos</h5>
                        <p>BTC, ETH, ETC, BCH, BSV, LTC, DOGE</p>

                        <p>From 2021-1-22</p>
                    </div>
                    <div className="form1_input">
                        <form onSubmit={this.handleClick}>
                            <label>Select crypto(s)</label>
                            <Select
                                //defaultValue={[modelOptions[0], modelOptions[1]]}
                                defaultValue={[modelOptions[1] ]}
                                isMulti
                                name="models"
                                options={modelOptions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                allowSelectAll={true}
                                isSearchable
                                onChange = {this.handleStrat}
                                // value = {this.state.selectedOption}

                            />

                            <ChButton
                                action={this.handleClick}
                                type={"primary"}
                                title={"Run"}
                                style={buttonStyle}
                            />{" "}
                            {/* <div class="alert alert-primary" role="alert">
                            {this.state.msg}
                            </div>
                            {this.state.is_feasible ? null : <div class="alert alert-danger" role="alert">
                                                            {"The optimization is not feasible!"}
                                                            </div> 
                            } */}
                        </form>
                        <h4>Prediction at : {this.state.pred_price_date}</h4>
                        <BootstrapTable 
                            keyField='model' 
                            data={ this.state.predData } 
                            columns={ columns } 
                            striped
                            hover
                            defaultSorted={ defaultSorted } 
                        />
                    </div>
                    
                    <div>
                        {this.state.data.map((dd, index) =>
                            
                            //<Chart_StockLine key={index} title_text={"ticker : " + index} 
                            // <Chart_StockLine key={index} title_text={"ticker : " + this.state.ticker_r} 
                            <Chart_StockLine key={index} title_text={" " + this.state.ticker_names[index]} 
                            data = {dd}/>,
                            // <Chart_StockLine  title_text={"title_text"} 
                            // data = {dd[0]}/>
                            )
                            
                        }

                         {/* <Chart_StockLine  title_text={this.state.groupname} 
                                 data = {this.state.data}/>  */}
                    </div>
                    
                </div>
            );

        } else if(this.state.loading === 1) { // loading
            return (
                <div id="pf1"><h1>Crypto Prediction Models</h1>
                                    <a href="http://100.1.58.77:3000/#/crypto1/" target="_blank">open new window</a>

                    <div class="alert alert-secondary" role="alert">
                    <h5>Available Cryptos</h5>
                        <p>BTC, ETH, ETC, BCH, BSV, LTC, DOGE</p>
                        <p>From 2021-1-22</p>
                    </div>
                    <div className="form1_input">
                        <form onSubmit={this.handleClick}>
                            <label>Select crypto(s)</label>

                            <Select
                                //defaultValue={[modelOptions[0], modelOptions[1]]}
                                // defaultValue={[ modelOptions[1] ]}
                                isMulti
                                name="models"
                                options={modelOptions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                allowSelectAll={true}
                                isSearchable
                                onChange = {this.handleStrat}
                                value = {this.state.selectedOption}
                            />

                            {/* <ChButton
                                action={this.handleClick}
                                type={"primary"}
                                title={"Start Predict"}
                                style={buttonStyle}
                            />{" "} */}
                            {/* <div class="alert alert-primary" role="alert">
                            {this.state.msg}
                            </div> */}
                        </form>
                    </div>
                    <div align="center">
                        <br/>
                        
                        <Spinner animation="border" variant="success" >
                            
                        </Spinner>
                        <br/>
                        <br/>
                        <br/>
                        <br/>

                    </div>
                </div>
                
            );
        }
         else { // not started
            return ( 
                <div id="pf1"><h1>Crypto Prediction Models</h1>
                                    <a href="http://100.1.58.77:3000/#/crypto1/" target="_blank">open new window</a>

                    <div class="alert alert-secondary" role="alert">
                    <h5>Available Cryptos</h5>
                    <p>BTC, ETH, ETC, BCH, BSV, LTC, DOGE</p>

                        <p>From 2021-1-22</p>
                    </div>
                    <div className="form1_input">
                        <form onSubmit={this.handleClick}>
                            <label>Select crypto(s)</label>
                            <Select
                                //defaultValue={[modelOptions[0], modelOptions[1]]}
                                defaultValue= {[ modelOptions[0], modelOptions[1],
                                        modelOptions[2], modelOptions[3],
                                        modelOptions[4], modelOptions[5] ]}
                                isMulti
                                name="models"
                                options={modelOptions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                allowSelectAll={true}
                                isSearchable
                                onChange = {this.handleStrat}
                                // value = {[ modelOptions[0], modelOptions[1],
                                //     modelOptions[2], modelOptions[3],
                                //     modelOptions[4], modelOptions[5] , modelOptions[6]]}
                            />

                            <ChButton
                                action={this.handleClick}
                                type={"primary"}
                                title={"Run"}
                                style={buttonStyle}
                            />{" "}
                            
                            {/* <div class="alert alert-primary" role="alert">
                            {this.state.msg}
                            </div> */}
                            
                        </form>
                        {/* <BootstrapTable 

                            keyField='model' 
                            data={ products } 
                            columns={ columns } 
                            striped
                            hover
                            defaultSorted={ defaultSorted } 
                        /> */}
                    </div>
                </div>
            );
        }
    }
}

const buttonStyle = {
    margin: "10px 10px 10px 10px"
 };

export default  Crypto1