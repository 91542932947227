export const hitratioOptions = [
    { value: '0.5', label: '50', color: '#00B8D9'},
    { value: '0.6', label: '60', color: '#00B8D9'},
    { value: '0.7', label: '70', color: '#00B8D9'}
    // { value: 'XGBoost', label: 'XGBoost', color: '#00B8D9'}
    // { value: 'mom', label: 'Momentum', color: '#0052CC'  },
    // { value: 'meanrev', label: 'Mean Reversion', color: '#5243AA' },
    // { value: 'red', label: 'Red', color: '#FF5630', isFixed: true },
    // { value: 'orange', label: 'Orange', color: '#FF8B00', isDisabled: true},
    // { value: 'yellow', label: 'Yellow', color: '#FFC400' , isFixed: true }
  ];