import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom';
import './index.css';

class Sidebar extends Component {
    render() {
        return (
            <div className="sidebar">
                <div className="sidebar-wrapper">
                    <div className="logo">
                        <Link to='/' className="simple-text">
                        Simple Dashboard
                        </Link>
                    </div>
                    <ul className="nav">
                        <li className="nav-item">
                        <NavLink className="nav-link" to='/cryptoone'>
                            <i className="nc-icon nc-chart-pie-35"></i>
                            <p>Crypto</p>
                        </NavLink>
                        </li>
                        <li className="nav-item">
                        <NavLink className="nav-link" to='/market'>
                            <i className="nc-icon nc-circle-09"></i>
                            <p>Market</p>
                        </NavLink>
                        </li>

                    </ul> 
                </div>
            </div>
        )
    }
}


export default Sidebar