export const columns = [
    { dataField: 'model',  text: 'model' , sort:true }, 
    { dataField: 'Annual return',  text: 'Annual ret' , sort:true }, 
    { dataField: 'Cumulative returns',  text: 'Cum ret' , sort:true }, 
    { dataField: 'Annual volatility',  text: 'Annual vol' , sort:true }, 
    { dataField: 'Sharpe ratio', text: 'Sharpe', sort:true }, 
    { dataField: 'Calmar ratio', text: 'Calmar', sort:true }, 
    { dataField: 'Stability', text: 'Stability', sort:true }, 
    { dataField: 'Max drawdown', text: 'M. drawdown', sort:true }, 
    { dataField: 'Omega ratio', text: 'Omega', sort:true }, 
    { dataField: 'Sortino ratio', text: 'Sortino', sort:true }, 
    { dataField: 'Skew', text: 'Skew', sort:true }, 
    { dataField: 'Kurtosis', text: 'Kurtosis', sort:true }, 
    { dataField: 'Tail ratio', text: 'Tail', sort:true }, 
    { dataField: 'Daily value at risk', text: 'Daily value at risk', sort:true }, 

];
