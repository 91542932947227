export const table_columns = [
{dataField: 'GicSector', text: 'GicSector', sort: true},
{dataField: '2023-02-17', text: '2023-02-17', sort: true},
{dataField: '2023-02-24', text: '2023-02-24', sort: true},
{dataField: '2023-03-03', text: '2023-03-03', sort: true},
{dataField: '2023-03-10', text: '2023-03-10', sort: true},
{dataField: '2023-03-17', text: '2023-03-17', sort: true},
{dataField: '2023-03-24', text: '2023-03-24', sort: true},
{dataField: '2023-03-31', text: '2023-03-31', sort: true},
{dataField: '2023-04-06', text: '2023-04-06', sort: true},
{dataField: '2023-04-14', text: '2023-04-14', sort: true},
{dataField: '2023-04-21', text: '2023-04-21', sort: true},
{dataField: '2023-04-28', text: '2023-04-28', sort: true},
{dataField: '2023-05-05', text: '2023-05-05', sort: true},
{dataField: '2023-05-12', text: '2023-05-12', sort: true},
{dataField: '2023-05-19', text: '2023-05-19', sort: true},
{dataField: '2023-05-26', text: '2023-05-26', sort: true},
{dataField: '2023-06-02', text: '2023-06-02', sort: true},
{dataField: '2023-06-09', text: '2023-06-09', sort: true},
{dataField: '2023-06-16', text: '2023-06-16', sort: true},
{dataField: '2023-06-23', text: '2023-06-23', sort: true},
{dataField: '2023-06-30', text: '2023-06-30', sort: true} ,
{dataField: '2023-07-07', text: '2023-07-07', sort: true},
{dataField: '2023-07-14', text: '2023-07-14', sort: true},
{dataField: '2023-07-21', text: '2023-07-21', sort: true},
{dataField: '2023-07-28', text: '2023-07-28', sort: true},
];
