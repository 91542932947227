// Display the current exposures

import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link , Switch} from "react-router-dom"

import ReactDOM from 'react-dom';
import Spinner from 'react-bootstrap/Spinner'
import {API_URL_PORTFOLIO_STYLE_ATTR} from './config'
import './App.css';
import axios from 'axios';
import PortFolioForm1 from "./PortfolioForm1";
import ChInput from './ChInput';
import ChTextarea from './ChTextarea';
import ChButton from './ChButton';
import { fundOptions } from './fundList';
import Select from 'react-select';

import Chart_Column_bar from './Chart_Column_bar';
import Chart_Horizon_bar_sort from './Chart_Horizon_bar_sort';
import Chart_Horizon_bar_multi4 from './Chart_Column_bar_multi4';
import Chart_Scatter from './Chart_Scatter';
import Chart_StockLine from './Chart_StockLine';

import {columns} from './styleAttributionTableColumns';
// import {columnsMth} from './tickerModelsTableColumnsMth';

import BootstrapTable from 'react-bootstrap-table-next';

import Footer from './Footer';

const defaultSorted = [{
    dataField: 'year',
    order: 'desc'
  }];


class PortfolioStyleAttribution extends React.Component {
    constructor(props) {
        super(props);
        // var options = {
        //     chart: {
        //         type: "pie"
        //     },
        //     series: [
        //         {
        //             name : "sector",
        //             data : this.state.data
        //         }
        //     ]
        // }

        this.state = {
            msg: "Style Attribution Analysis",
            is_feasible: true,
            is_data_ok: true,
            not_feasible_msg : "",
            ticker_r: "",
            weight_r: [],
            // optimizer: [
            //     {
            //         id: 0,
            //         title: "mean_variance",
            //         selected: false,
            //         key: 'optimizer'
            //     },
            //     {
            //         id: 1,
            //         title: "cVar",
            //         selected: false,
            //         key: 'optimizer'
            //     }
            // ],
            optimizer: "euqal_weight",
            start_date: '2003-01-02',
            end_date: '2022-12-31',
            is_updated: false,
            loading: 0, // 0 : not started, 1: loading, 2: done
            categories: null,
            data: null,
            categories2: null,
            data2: null,
            categories3: null,
            data3: null,
            categories4: null,
            data4: null,
            categories5: null,
            data5: null,
            categories6: null,
            data6: null,
            categories7: null,
            data7: null,
            categories8: null,
            data8: null,
            perf_port: null,
            series_for_scatter: null,
            categories_weight: null,
            data_weight: null,
            valid_ticker: true,
            valid_startdate :true,
            valid_enddate: true,
            valid_date: true,
            //valid_date: false,
            //ticker_wrong : false
            no_data_tickers: [],
            date_prob_tockers: [],
            is_505_error: false,
            selectedFund: {value: 'ABALX', label: 'ABALX', color: '#00B8D9'},
            status_table: null,
            large_value: null,
            large_growth: null,
            mid_value: null,
            mid_growth: null,
            categories: null,

        };

        console.log("this.state.is_updated: ", this.state.is_updated);

        this.handleClick = this.handleClick.bind(this);
        this.handleInput = this.handleInput.bind(this);
        // this.handleInputDate = this.handleInputDate.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleFund= this.handleFund.bind(this);
        // this.handleStateChange = this.handleStateChange.bind(this);
        this.handleInputStartDate = this.handleInputStartDate.bind(this);
        this.handleInputEndDate = this.handleInputEndDate.bind(this);


    }

 
    // shouldComponentUpdate(newProps, newState) {
    //     console.log("About to shouldComponent Updadte");
    //     if (this.state.is_updated === false) {
    //         return true;
    //     }
    //     else {
    //         this.setState( {
    //             is_updated: true
    //         })
    //         console.log("just updated is_update to true and update comp");
    //         return false;
    //     }
    // }

    // handleStateChange(e) {
    //     this.setState( {
    //         data: e
    //     })
    //     console.log("insde handleStateChange");
    //     console.log(this.state.data);

        

    // }


    handleClick(e) {

        e.preventDefault(); 
        // if (!this.state.valid_ticker || !this.state.valid_date) {
        // if (!this.state.valid_ticker) {
            
        //     return;
        // }
        if (!this.state.valid_ticker || !this.state.valid_enddate || !this.state.valid_startdate) {
            
            return;
        }
        var lst_no_data_tickers = [];
        var lst_date_prob_tockers= [];
        var x;
        let userData = this.state.ticker_r;
        var tickers = [];
        this.setState( 
            prevState => ( {
                loading: 1,
                no_data_tickers: [],
                date_prob_tockers: []
            })
        );
        //var self = this;
        // axios.get function will lose the context of 'this'. So, copy it into
        // var self == this. then use self when update the state lilke 
        // "self.setState( {  .... } );". this properly point this component.
        // axios.post("http://192.168.1.12:5000/api/portfolio_analytic_1", {
        axios.post(`${API_URL_PORTFOLIO_STYLE_ATTR}`, {
                ticker: this.state.selectedFund,
                start_date: this.state.start_date,
                end_date: this.state.end_date
            })
            //.then(function(response) {
            .then( (response) => {
                console.log(response);
                console.log("here is the length of data");
                console.log(response.data.length);
                
                
                    this.setState (
                        prevState => ( {
                            loading: 2,
                            status_table: response.data['status_table'],
                            is_data_ok: true ,
                            is_505_error: false,
                            large_value: response.data['large_value'][1]['data'],
                            large_growth: response.data['large_growth'][1]['data'],
                            mid_value: response.data['mid_value'][1]['data'],
                            mid_growth: response.data['mid_growth'][1]['data'],
                            categories3: response.data['mid_growth'][0]['categories'],

                        }),
                        () => console.log(this.state.perf_port)
                    );

                // if (this.state.is_feable == 'infeasible') {
                //     this.setState (
                //         prevState => ( {
                //             is_feasible: false,
                //             not_feasible_msg: "Optimization is not feasible"
                //         }),
                //         () => console.log(this.state)
                //     );
                // }


                
                //console.log(self.options)

                // self.setState( {
                //     data : response.data,
                //     is_updated: true
                // });
                console.log("inside call back");
                console.log(this.state.data);
                //console.log(self.state.is_updated);

                // self.forceUpdate(); // not working
                
                //self.handleStateChange(response.data);
                    
            })
            .catch(function (error) {
                
                console.log(error);
                console.log("EEEEEEEERRRRRRORRR");
                this.setState (
                    prevState => ( {
                        loading: 2,
                        is_505_error: true
                    }),
                    () => console.log(this.state.is_505_error)
                );
            })
            .then(function() {
                console.log('Fetch done');
            });
    }

    handleInput(e) {
        let value = e.target.value;

        this.setState (
            prevState => ( {
                ticker_r: value,
                valid_ticker: value.trim().split(' ').length > 0
            }),
            () => console.log(this.state.valid_ticker)
        );

        //e.preventDefault();
    }

    // handleInputDate(e) {
    //     let value = e.target.value;
        
    //     this.setState( 
    //         prevState => ( {
    //             start_date: value,
    //             valid_date:  (Date.parse(value) >= Date.parse("2003-01-02")) && (Date.parse(value) <= Date.parse("2023-03-23") ),
    //             date_prob_tockers: []
    //         }),
    //         () => console.log(this.state.start_date)
    //     );
    // }
    handleInputStartDate(e) {
        let value = e.target.value;
        
        this.setState( 
            prevState => ( {
                start_date: value,
                valid_startdate: ( Date.parse(value) >= Date.parse("2011-01-02") )&& (Date.parse(value) <= Date.parse("2023-03-30") ),
                date_prob_tockers: []
            }),
            () => console.log(this.state.start_date)
        );
    }
    handleInputEndDate(e) {
        let value = e.target.value;
        
        this.setState( 
            prevState => ( {
                end_date: value,
                valid_enddate: ( Date.parse(value) > Date.parse(this.state.start_date) + 300000000) && ( Date.parse(value) <= Date.parse("2023-03-23") ),
                date_prob_tockers: []
            }),
            () => console.log(this.state.end_date), console.log(this.state.valid_enddate)
        );
    }
    handleFund = selectedFund=>{
        this.setState(
            {selectedFund: selectedFund},
            () => console.log('Options selected:', this.state.selectedFund)

        );
    };
    handleSelect(e) {
        let value = e.target.value;
        
        this.setState( 
            prevState => ( {
                optimizer: value
            }),
            () => console.log(this.state.optimizer)
        );
        if (value === "MV_FN") {
            this.setState( 
                prevState => ( {
                    msg: "Mean Variance with Factor Neutal Portfolio using Fama-French facotrs : CMA, HML, MOM, RMW, SMB "
                }),
                () => console.log(this.state.msg)
            );
        } else if (value === "EW") {
            this.setState( 
                prevState => ( {
                    msg: "Simple Equal Weight "
                }),
                () => console.log(this.state.msg)
            ); 
        } else if (value === "MV") {
            this.setState( 
                prevState => ( {
                    msg: "Mean Variance without constraints"
                }),
                () => console.log(this.state.msg)
            ); 

        }

    }
    // shouldComponentUpdate(newProps, newState) {
    //     console.log("shouldComponentUpdate: Should component update?");
    //     console.log(this.state.data);
    //     console.log(newState.data);
    //     if (this.state.data !== newState.data) {
    //       console.log("shouldComponentUpdate: Component should update!");
    //       return true;
    //     } else {
    //       //ReactDOM.unmountComponentAtNode(destination);
    //       console.log("shouldComponentUpdate: Component should not update!");
    //       return false;
    //     }
    // }


    render() {
        <Router>
            <Switch>
                <Route  path="/portfolioform1" component={PortFolioForm1} />
            </Switch>
        </Router>
        const is_505_error =this.state.is_505_error;
        console.log("is_505_error:", is_505_error);

        //const {showChart} = this.state.data;
        if (this.state.loading === 2) {  // data ready and display
            if (is_505_error){
                return ( 
                    <div id="pf1"><h1>Style Attribution Analysis</h1>
                    <div class="alert alert-secondary" role="alert">
                        <p>To identify the styles that can be associated with the major risk factors in a portfolio.</p>
                        
                        <h5>Universe</h5>
                                <p>Mutual Funds</p>
                                <p>ABALX, AGTHX, FXAIX, VBTLX, VFFSX, VFIAX, VGTSX, VIIIX, VINIX, VMFXX, VSMPX, VTBIX, VTBNX, VTSAX</p>
                                <p>(more to be added)</p>
                            <h5>Style Factors</h5>
                            <ul>
                                <li>Russell 1000 value: Mid Cap, Value (RLV)</li>
                                <li>Russell 1000 growth: Mid Cap, Growth (RLG)</li>
                                <li>Russell 2000 value: Small Cap, Value (RUT)</li>
                                <li>Russell 2000 growth: Small Cap, Growth (RUO)</li>
                            </ul>

                    </div>
                    <div className="form1_input">
                        <form onSubmit={this.handleClick}>
                        <label>Select Fund</label>
                            <Select
                                // defaultValue={this.state.selectedModel}
                                // defaultValue= {[ modelOptions[0], modelOptions[1],
                                //         modelOptions[2], modelOptions[3],
                                //         modelOptions[4], modelOptions[5] ]}
                                // isMulti
                                name="funds"
                                options={fundOptions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                allowSelectAll={false}
                                isSearchable
                                onChange = {this.handleFund}
                                value = {this.state.selectedFund}
                                // value = {[ modelOptions[0], modelOptions[1],
                                //     modelOptions[2], modelOptions[3],
                                //     modelOptions[4], modelOptions[5] , modelOptions[6]]}
                            />
                            
                            <ChInput  
                                inputType={"date"}
                                title={"Start"}
                                name={"start_name"}
                                value = {this.state.start_date}
                                placeholder="Input date" 
                                min = {"2003-01-02"}
                                max = {"2023-03-23"}
                                handleChange={this.handleInputStartDate}
                            />{" "}
                            {this.state.valid_startdate ? null : <div class="alert alert-danger" role="alert">
                                                            {"Enter the valid Range between 2003-01-02 and 2023-03-23"}
                                                            </div>
                            }
                            {/* {this.state.date_prob_tockers==0 ? null : <div class="alert alert-danger" role="alert">
                                                            {"Data for " + this.state.date_prob_tockers +  " Not Available on the date you selected. Please select different date."}
                                                            </div>
                            } */}
                            <ChInput  
                                inputType={"date"}
                                title={"End"}
                                name={"end_name"}
                                value = {this.state.end_date}
                                placeholder="Input date" 

                                handleChange={this.handleInputEndDate}
                            />{" "}
                            {this.state.valid_enddate ? null : <div class="alert alert-danger" role="alert">
                                                            {"Enter the valid Range between 2003-01-02 and 2023-03-23"}
                                                            </div>
                            }
                            {/* {this.state.date_prob_tockers==0 ? null : <div class="alert alert-danger" role="alert">
                                                            {"Data for " + this.state.date_prob_tockers +  " Not Available on the date you selected. Please select different date."}
                                                            </div>
                            } */} 
    
                                <ChButton
                                    action={this.handleClick}
                                    type={"primary"}
                                    title={"Submit"}
                                    style={buttonStyle}
                                />{" "}


                                <div class="alert alert-primary" role="alert">
                                {this.state.msg}
                                </div>
                                
                            </form>


                        </div>
                        <div>
                            {/* <iframe src="error500.html" frameborder="0" scrolling="no" onload="resizeIframe(this)" ></iframe> */}
                            <iframe src="error500.html" frameborder="0" scrolling="no" height="600" width="800" ></iframe>

                        </div> 
                        <Footer />
                        {/* <footer class="mastfoot mt-auto">
                        <div class="inner">
                            <p>© 2023 Copyright Chris IJ Hwang</p>
                        </div>
                        <p>DISCLAIMER: The information in this site is for the purpose of research and backtest only. I don't promise any future profits and don't take responsibility for any trading losses.
                        </p>
                        </footer>  */}
                    </div>
                ); // return
            }// is_505_error if end
            else {// is_505_error else start
                return ( 
                    <div id="pf1"><h1>Style Attribution Analysis</h1>
                    <div class="alert alert-secondary" role="alert">
                        <p>To identify the styles that can be associated with the major risk factors in a portfolio.</p>
                        
                        <h5>Universe</h5>
                                <p>Mutual Funds</p>
                                <p>ABALX, AGTHX, FXAIX, VBTLX, VFFSX, VFIAX, VGTSX, VIIIX, VINIX, VMFXX, VSMPX, VTBIX, VTBNX, VTSAX</p>
                                <p>(more to be added)</p>
                            <h5>Style Factors</h5>
                            <ul>
                                <li>Russell 1000 value: Mid Cap, Value (RLV)</li>
                                <li>Russell 1000 growth: Mid Cap, Growth (RLG)</li>
                                <li>Russell 2000 value: Small Cap, Value (RUT)</li>
                                <li>Russell 2000 growth: Small Cap, Growth (RUO)</li>
                            </ul>

                    </div>
                    <div className="form1_input">
                        <form onSubmit={this.handleClick}>
                        <label>Select Fund</label>
                            <Select
                                // defaultValue={this.state.selectedModel}
                                // defaultValue= {[ modelOptions[0], modelOptions[1],
                                //         modelOptions[2], modelOptions[3],
                                //         modelOptions[4], modelOptions[5] ]}
                                // isMulti
                                name="funds"
                                options={fundOptions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                allowSelectAll={false}
                                isSearchable
                                onChange = {this.handleFund}
                                value = {this.state.selectedFund}
                                // value = {[ modelOptions[0], modelOptions[1],
                                //     modelOptions[2], modelOptions[3],
                                //     modelOptions[4], modelOptions[5] , modelOptions[6]]}
                            />
                            
                            <ChInput  
                                inputType={"date"}
                                title={"Start"}
                                name={"start_name"}
                                value = {this.state.start_date}
                                placeholder="Input date" 
                                min = {"2003-01-02"}
                                max = {"2023-03-23"}
                                handleChange={this.handleInputStartDate}
                            />{" "}
                            {this.state.valid_startdate ? null : <div class="alert alert-danger" role="alert">
                                                            {"Enter the valid Range between 2003-01-02 and 2023-03-23"}
                                                            </div>
                            }
                            {/* {this.state.date_prob_tockers==0 ? null : <div class="alert alert-danger" role="alert">
                                                            {"Data for " + this.state.date_prob_tockers +  " Not Available on the date you selected. Please select different date."}
                                                            </div>
                            } */}
                            <ChInput  
                                inputType={"date"}
                                title={"End"}
                                name={"end_name"}
                                value = {this.state.end_date}
                                placeholder="Input date" 

                                handleChange={this.handleInputEndDate}
                            />{" "}
                            {this.state.valid_enddate ? null : <div class="alert alert-danger" role="alert">
                                                            {"Enter the valid Range between 2003-01-02 and 2023-03-23"}
                                                            </div>
                            }
                            {/* {this.state.date_prob_tockers==0 ? null : <div class="alert alert-danger" role="alert">
                                                            {"Data for " + this.state.date_prob_tockers +  " Not Available on the date you selected. Please select different date."}
                                                            </div>
                            } */} 
    
                                <ChButton
                                    action={this.handleClick}
                                    type={"primary"}
                                    title={"Submit"}
                                    style={buttonStyle}
                                />{" "}


                                <div class="alert alert-primary" role="alert">
                                {this.state.msg}
                                </div>
                                
                            </form>

                            {/* <button className='button' onClick={this.handleClick}>Test button</button> */}
                            {/* <div>{this.state.data}</div> */}
                            {/* <div>{this.state.data}</div> */}
                            {/* {this.state.is_updated ? "not updated yet" : "updated>>>>>>" } */}

                            <h3>Style Attribution</h3>
                            <BootstrapTable 
                                keyField='model' 
                                data={ this.state.status_table } 
                                columns={ columns} 
                                striped
                                hover
                                defaultSorted={ defaultSorted } 
                            />

                        </div>
                        {console.log(this.state.data)}
                        
                       
                        <br>
                        </br>
                        <hr></hr>
                        <div className="row">
                            <br></br>
                            {/* <h3>Style Attribution Chart</h3> */}
                                <br></br>
                                <div  className="col" id="chart3">
                                    <Chart_Horizon_bar_multi4 renderTo='chart3' categories={this.state.categories3}  
                                        title_text='Large Value' 
                                        title_text2='Large Growth' 
                                        title_text3='Mid Value' 
                                        title_text4='Mid Growth' 
                                        all_title_text = "Chart"
                                        data = {this.state.large_value}
                                        data2 = {this.state.large_growth}
                                        data3= {this.state.mid_value}
                                        data4= {this.state.mid_growth}
                                        
                                        /> 
                                </div>
                        </div>

                        <div>
                            <br></br>
                            <hr></hr>

                        </div>
                        <Footer />
                        {/* <footer class="mastfoot mt-auto">
                        <div class="inner">
                            <p>© 2023 Copyright Chris IJ Hwang</p>
                        </div>
                        <p>DISCLAIMER: The information in this site is for the purpose of research and backtest only. I don't promise any future profits and don't take responsibility for any trading losses.
                        </p>
                        </footer>  */}
                    </div>

                ); // return

            } // is_505_error else end

        } else if(this.state.loading === 1) { // loading
            return (
                <div id="pf1"><h1>Style Attribution Analysis</h1>
                    <div class="alert alert-secondary" role="alert">
                        <p>To identify the styles that can be associated with the major risk factors in a portfolio.</p>
                        
                        <h5>Universe</h5>
                                <p>Mutual Funds</p>
                                <p>ABALX, AGTHX, FXAIX, VBTLX, VFFSX, VFIAX, VGTSX, VIIIX, VINIX, VMFXX, VSMPX, VTBIX, VTBNX, VTSAX</p>
                                <p>(more to be added)</p>
                            <h5>Style Factors</h5>
                            <ul>
                                <li>Russell 1000 value: Mid Cap, Value (RLV)</li>
                                <li>Russell 1000 growth: Mid Cap, Growth (RLG)</li>
                                <li>Russell 2000 value: Small Cap, Value (RUT)</li>
                                <li>Russell 2000 growth: Small Cap, Growth (RUO)</li>
                            </ul>

                    </div>
                    <div className="form1_input">
                        <form onSubmit={this.handleClick}>
                        <label>Select Fund</label>
                            <Select
                                // defaultValue={this.state.selectedModel}
                                // defaultValue= {[ modelOptions[0], modelOptions[1],
                                //         modelOptions[2], modelOptions[3],
                                //         modelOptions[4], modelOptions[5] ]}
                                // isMulti
                                name="funds"
                                options={fundOptions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                allowSelectAll={false}
                                isSearchable
                                onChange = {this.handleFund}
                                value = {this.state.selectedFund}
                                // value = {[ modelOptions[0], modelOptions[1],
                                //     modelOptions[2], modelOptions[3],
                                //     modelOptions[4], modelOptions[5] , modelOptions[6]]}
                            />
                            
                            <ChInput  
                                inputType={"date"}
                                title={"Start"}
                                name={"start_name"}
                                value = {this.state.start_date}
                                placeholder="Input date" 
                                min = {"2003-01-02"}
                                max = {"2023-3-23"}
                                handleChange={this.handleInputStartDate}
                            />{" "}
                            {this.state.valid_startdate ? null : <div class="alert alert-danger" role="alert">
                                                            {"Enter the valid Range between 2003-01-02 and 2023-03-23"}
                                                            </div>
                            }
                            {/* {this.state.date_prob_tockers==0 ? null : <div class="alert alert-danger" role="alert">
                                                            {"Data for " + this.state.date_prob_tockers +  " Not Available on the date you selected. Please select different date."}
                                                            </div>
                            } */}
                            <ChInput  
                                inputType={"date"}
                                title={"End"}
                                name={"end_name"}
                                value = {this.state.end_date}
                                placeholder="Input date" 

                                handleChange={this.handleInputEndDate}
                            />{" "}
                            {this.state.valid_enddate ? null : <div class="alert alert-danger" role="alert">
                                                            {"Enter the valid Range between 2003-01-02 and 2023-03-23"}
                                                            </div>
                            }
                            {/* {this.state.date_prob_tockers==0 ? null : <div class="alert alert-danger" role="alert">
                                                            {"Data for " + this.state.date_prob_tockers +  " Not Available on the date you selected. Please select different date."}
                                                            </div>
                            } */} 
                            <ChButton
                                action={this.handleClick}
                                type={"primary"}
                                title={"Submit"}
                                style={buttonStyle}
                            />{" "}

                            <div class="alert alert-primary" role="alert">
                            {this.state.msg}
                            </div>
                            
                        </form>
                    </div>
                    <div align="center">
                        <br/>
                        <br/>
                        <br/>
                        <br/>

                        <Spinner animation="border" variant="success" >
                            
                        </Spinner>
                        <br/>
                        <br/>
                        <br/>
                        <br/>

                    </div>
                </div>
                
            );
        }
         else { // not started
            return ( 
                <div id="pf1"><h1>Style Attribution Analysis</h1>
                    <div class="alert alert-secondary" role="alert">
                        <p>To identify the styles that can be associated with the major risk factors in a portfolio.</p>
                        
                        <h5>Universe</h5>
                                <p>Mutual Funds</p>
                                <p>ABALX, AGTHX, FXAIX, VBTLX, VFFSX, VFIAX, VGTSX, VIIIX, VINIX, VMFXX, VSMPX, VTBIX, VTBNX, VTSAX</p>
                                <p>(more to be added)</p>
                            <h5>Style Factors</h5>
                            <ul>
                                <li>Russell 1000 value: Mid Cap, Value (RLV)</li>
                                <li>Russell 1000 growth: Mid Cap, Growth (RLG)</li>
                                <li>Russell 2000 value: Small Cap, Value (RUT)</li>
                                <li>Russell 2000 growth: Small Cap, Growth (RUO)</li>
                            </ul>

                    </div>
                    <div className="form1_input">
                        <form onSubmit={this.handleClick}>
                        <label>Select Fund</label>
                            <Select
                                // defaultValue={this.state.selectedModel}
                                // defaultValue= {[ modelOptions[0], modelOptions[1],
                                //         modelOptions[2], modelOptions[3],
                                //         modelOptions[4], modelOptions[5] ]}
                                // isMulti
                                name="funds"
                                options={fundOptions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                allowSelectAll={false}
                                isSearchable
                                onChange = {this.handleFund}
                                value = {this.state.selectedFund}
                                // value = {[ modelOptions[0], modelOptions[1],
                                //     modelOptions[2], modelOptions[3],
                                //     modelOptions[4], modelOptions[5] , modelOptions[6]]}
                            />
                            
                            <ChInput  
                                inputType={"date"}
                                title={"Start"}
                                name={"start_name"}
                                value = {this.state.start_date}
                                placeholder="Input date" 
                                min = {"2003-01-02"}
                                max = {"2023-03-23"}
                                handleChange={this.handleInputStartDate}
                            />{" "}
                            {this.state.valid_startdate ? null : <div class="alert alert-danger" role="alert">
                                                            {"Enter the valid Range between 2003-01-02 and 2023-03-23"}
                                                            </div>
                            }
                            {/* {this.state.date_prob_tockers==0 ? null : <div class="alert alert-danger" role="alert">
                                                            {"Data for " + this.state.date_prob_tockers +  " Not Available on the date you selected. Please select different date."}
                                                            </div>
                            } */}
                            <ChInput  
                                inputType={"date"}
                                title={"End"}
                                name={"end_name"}
                                value = {this.state.end_date}
                                placeholder="Input date" 

                                handleChange={this.handleInputEndDate}
                            />{" "}
                            {this.state.valid_enddate ? null : <div class="alert alert-danger" role="alert">
                                                            {"Enter the valid Range between 2003-01-02 and 2023-03-23"}
                                                            </div>
                            }
                            {/* {this.state.date_prob_tockers==0 ? null : <div class="alert alert-danger" role="alert">
                                                            {"Data for " + this.state.date_prob_tockers +  " Not Available on the date you selected. Please select different date."}
                                                            </div>
                            } */} 
                            <ChButton
                                action={this.handleClick}
                                type={"primary"}
                                title={"Submit"}
                                style={buttonStyle}
                            />{" "}

                            <div class="alert alert-primary" role="alert">
                            {this.state.msg}
                            </div>
                            
                        </form>
                    </div>
                    <Footer />
                    {/* <footer class="mastfoot mt-auto">
                    <div class="inner">
                        <p>© 2023 Copyright Chris IJ Hwang</p>
                    </div>
                    <p>DISCLAIMER: The information in this site is for the purpose of research and backtest only. I don't promise any future profits and don't take responsibility for any trading losses.
                    </p>
                    </footer>  */}
                </div>
            );
        }
    }
}

const buttonStyle = {
    margin: "10px 10px 10px 10px"
 };


export default PortfolioStyleAttribution;