import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Spinner from 'react-bootstrap/Spinner'
import {API_URL_DLFM} from './config'
import './App.css';
import axios from 'axios';

import ChInput from './ChInput';
import ChButton from './ChButton';

// import Chart_Donut from './Chart_Donut';
// import Chart_Donut2 from './Chart_Donut2';
import Chart_Column_bar from './Chart_Column_bar';
import Chart_Horizon_bar_sort from './Chart_Horizon_bar_sort';
import Chart_Horizon_bar_multi from './Chart_Horizon_bar_multi';
import Chart_Scatter from './Chart_Scatter';
import Chart_StockLine2 from './Chart_StockLine2';
import Chart_BoxPlot from './Chart_BoxPlot';

import Footer from './Footer';

class Dlfm extends React.Component {
    constructor(props) {
        super(props);
        // var options = {
        //     chart: {
        //         type: "pie"
        //     },
        //     series: [
        //         {
        //             name : "sector",
        //             data : this.state.data
        //         }
        //     ]
        // }

        this.state = {
            msg: "Simple Equal Weight",
            is_feasible: true,
            is_data_ok: true,
            not_feasible_msg : "",
            // optimizer: [
            //     {
            //         id: 0,
            //         title: "mean_variance",
            //         selected: false,
            //         key: 'optimizer'
            //     },
            //     {
            //         id: 1,
            //         title: "cVar",
            //         selected: false,
            //         key: 'optimizer'
            //     }
            // ],
            period: "mth",
            is_updated: false,
            loading: 0, // 0 : not started, 1: loading, 2: done
            categories: null,
            data: null,
            categories2: null,
            data2: null,
            categories3: null,
            data3: null,
            categories4: null,
            data4: null,
            categories5: null,
            data5: null,
            categories6: null,
            data6: null,
            categories7: null,
            data7: null,
            categories8: null,
            data8: null,
            perf_1: null,
            nn_sensitivity: null,
            ols_sensitivity: null,
            series_for_scatter: null,
            categories_weight: null,
            data_weight: null,
            valid_ticker: true,
            valid_date: true,
            //valid_date: false,
            //ticker_wrong : false
            no_data_tickers: [],
            date_prob_tockers: []
            

        };

        // console.log("this.state.is_updated: ", this.state.is_updated);

        this.handleClick = this.handleClick.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.handleInputDate = this.handleInputDate.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        // this.handleStateChange = this.handleStateChange.bind(this);
        


    }

 
    // shouldComponentUpdate(newProps, newState) {
    //     console.log("About to shouldComponent Updadte");
    //     if (this.state.is_updated === false) {
    //         return true;
    //     }
    //     else {
    //         this.setState( {
    //             is_updated: true
    //         })
    //         console.log("just updated is_update to true and update comp");
    //         return false;
    //     }
    // }

    // handleStateChange(e) {
    //     this.setState( {
    //         data: e
    //     })
    //     console.log("insde handleStateChange");
    //     console.log(this.state.data);

        

    // }


    handleClick(e) {
        var dt = {
                
            };
        e.preventDefault(); 
        // if (!this.state.valid_ticker || !this.state.valid_date) {
        // if (!this.state.valid_ticker) {
            
        //     return;
        // }
        var lst_no_data_tickers = [];
        var lst_date_prob_tockers= [];
        var x;
        // for (x of this.state.ticker_r.trim().split(" ")) {
        //     if (!(x  in dt)){
        //         console.log(x);
        //         lst_no_data_tickers[lst_no_data_tickers.length] = x;
        //     }else {
        //         if (Date.parse(dt[x][0]) >= Date.parse(this.state.start_date)) {
        //             console.log(x);
        //             lst_date_prob_tockers[lst_date_prob_tockers.length] = x;
        //         }
        //     }
        // }

        // if ( (lst_no_data_tickers.length !=0) || ( lst_date_prob_tockers.length !=0)) {
        //     console.log(lst_no_data_tickers);
        //     console.log(lst_date_prob_tockers);
        //     this.setState( 
        //         prevState => ( {
        //             no_data_tickers: lst_no_data_tickers,
        //             date_prob_tockers:lst_date_prob_tockers 
        //         })
        //     );
        //     return ;
        // }
        // } else {
        //     this.setState( 
        //         prevState => ( {
        //             loading: 1,
        //             ticker_wrong: false
        //         })
        //     );
        // }
        let userData = this.state.ticker_r;
        var tickers = [];
        this.setState( 
            prevState => ( {
                loading: 1
                // no_data_tickers: [],
                // date_prob_tockers: []
            })
        );
        //var self = this;
        // axios.get function will lose the context of 'this'. So, copy it into
        // var self == this. then use self when update the state lilke 
        // "self.setState( {  .... } );". this properly point this component.
        // axios.post("http://192.168.1.12:5000/api/portfolio_analytic_1", {
        axios.post(`${API_URL_DLFM}`, {
                period: this.state.period
            })
            //.then(function(response) {
            .then( (response) => {
                // console.log(response);
                // console.log("here is the length of data");
                // console.log(response.data.length);
                
                if  (response.data.length === 2){
                    this.setState (
                        prevState => ( {
                            loading: 2,
                            // msg: response.data[0]['msg'],
                            is_data_ok: response.data[1]['is_data_ok'],
                            categories: null,
                            data: null,
                            categories2: null,
                            data2: null,
                            categories3: null,
                            data3: null,
                            categories4: null,
                            data4: null,
                            categories5: null,
                            data5: null,
                            categories6: null,
                            data6: null,
                            categories7: null,
                            data7: null,
                            categories8: null,
                            data8: null,
                            series_for_scatter: null,
                            categories_weight: null,
                            data_weight: null,
                            perf_1: null
                        }),
                        // () => console.log(this.state)
                    );
                }
                else {
                    this.setState (
                        prevState => ( {
                            loading: 2,
                            // categories: response.data[0][0]['categories'],
                            // data: response.data[0][1]['data'],
                            // categories2: response.data[1][0]['categories'],
                            // data2: response.data[1][1]['data'],
                            // categories3: response.data[2][0]['categories'],
                            // data3: response.data[2][1]['data'],
                            // categories4: response.data[3][0]['categories'],
                            // data4: response.data[3][1]['data'],
                            // categories5: response.data[4][0]['categories'],
                            // data5: response.data[4][1]['data'],
                            // categories6: response.data[5][0]['categories'],
                            // data6: response.data[5][1]['data'],
                            // categories7: response.data[6][0]['categories'],
                            // data7: response.data[6][1]['data'],
                            // categories8: response.data[7][0]['categories'],
                            // data8: response.data[7][1]['data'],
                            // series_for_scatter: response.data[8],
                            // is_feasible: response.data[9]['is_feasible'],
                            // categories_weight: response.data[10][0]['categories'],
                            // data_weight: response.data[10][1]['data'],
                            // perf_1: response.data[0],
                            perf_1: response.data['perf_1'][0],
                            perf_train: response.data['perf_train'][0],
                            nn_sensitivity: response.data['nn_obj'],
                            ols_sensitivity: response.data['ols_obj'],
                            is_data_ok: true

                        })
                        // () => console.log(this.state.nn_sensitivity),
                        // () => console.log(this.state.ols_sensitivity)
                    );
                }
                // if (this.state.is_feable == 'infeasible') {
                //     this.setState (
                //         prevState => ( {
                //             is_feasible: false,
                //             not_feasible_msg: "Optimization is not feasible"
                //         }),
                //         () => console.log(this.state)
                //     );
                // }


                
                //console.log(self.options)

                // self.setState( {
                //     data : response.data,
                //     is_updated: true
                // });
                // console.log("inside call back");
                // console.log("perf_1");
                // console.log(this.state.perf_1);
                // console.log("nn_sensitivity");
                // console.log(this.state.nn_sensitivity);
                // console.log("ols_sensitivity");
                // console.log(this.state.ols_sensitivity);
                //console.log(self.state.is_updated);

                // self.forceUpdate(); // not working
                
                //self.handleStateChange(response.data);
                    
            })
            .catch(function (error) {
                
                console.log(error);
            })
            .then(function() {
                console.log('Fetch done');
            });
    }

    handleInput(e) {
        let value = e.target.value;

        this.setState (
            prevState => ( {
                ticker_r: value,
                valid_ticker: value.trim().split(' ').length > 1
            }),
            () => console.log(this.state.ticker_r)
        );

        //e.preventDefault();
    }

    handleInputDate(e) {
        let value = e.target.value;
        
        this.setState( 
            prevState => ( {
                start_date: value,
                valid_date:  (Date.parse(value) >= Date.parse("2011-01-02")) && (Date.parse(value) <= Date.parse("2022-05-31") ),
                date_prob_tockers: []
            }),
            () => console.log(this.state.start_date)
        );
    }

    handleSelect(e) {
        let value = e.target.value;
        
        this.setState( 
            prevState => ( {
                period: value
            }),
            () => console.log(this.state.period)
        );
        // if (value === "MV_FN") {
        //     this.setState( 
        //         prevState => ( {
        //             msg: "Mean Variance with Factor Neutal Portfolio using Fama-French facotrs : CMA, HML, MOM, RMW, SMB "
        //         }),
        //         () => console.log(this.state.msg)
        //     );
        // } else if (value === "EW") {
        //     this.setState( 
        //         prevState => ( {
        //             msg: "Simple Equal Weight "
        //         }),
        //         () => console.log(this.state.msg)
        //     ); 
        // } else if (value === "MV") {
        //     this.setState( 
        //         prevState => ( {
        //             msg: "Mean Variance without constraints"
        //         }),
        //         () => console.log(this.state.msg)
        //     ); 

        // }

    }
    // shouldComponentUpdate(newProps, newState) {
    //     console.log("shouldComponentUpdate: Should component update?");
    //     console.log(this.state.data);
    //     console.log(newState.data);
    //     if (this.state.data !== newState.data) {
    //       console.log("shouldComponentUpdate: Component should update!");
    //       return true;
    //     } else {
    //       //ReactDOM.unmountComponentAtNode(destination);
    //       console.log("shouldComponentUpdate: Component should not update!");
    //       return false;
    //     }
    // }


    render() {
        //const {showChart} = this.state.data;
        if (this.state.loading === 2) {  // data ready and display

            return ( 
                <div id="pf1"><h1>Deep Learning Factor Model</h1>
                    <div class="alert alert-secondary" role="alert">
                    <h5>Overview</h5>
                    <p>A Deep Learning Framework for cross-sectional fundamental(Barra style) factor modeling.</p>
                    <p>Two different models(Linear and Non-linear) are used for computing the factor betas</p>
                    <ul>
                        <li>Model 1: Linear Regression</li>
                        <li>Model 2: Deep Learning </li>
                    </ul>
                    <h5>Universe</h5>
                        <p>1500+ stocks in U.S. market</p>
                        <p>from 2011-1-1 to 2022-5-31</p>
                        <h5>Factors</h5>
                        <p>Fundamental factors: </p>
                            <ul>
                                <li>MC: Log (Market Capitalization)</li>
                                <li>E_P: Earning to Price</li>
                                <li>B_P: Book to Price</li>
                                <li>DIV: Dividend Yield</li>
                                <li>EV: Enterprise Value = Market Cap + LT Debt + max(ST Debt-Cash, 0)</li>
                                <li>S_EV: Sale to Enterprise Value</li>
                                <li>EB_EV: EBIDTA to EV</li>
                                <li>more to be added </li>
                            </ul>

                        <h5>Beta</h5>
                        <p>250-day lookback period</p>

                        <p>Deep Fundamental Factor Models - Matthew F. Dixon, Nicholas G. Polson</p>

                    </div>
                    <div className="form1_input">
                        <form onSubmit={this.handleClick}>
                           
                            

                            <label>
                            Select 
                            <select value={this.state.period} onChange={this.handleSelect}>
                            <option value="mth">Monthly</option>
                                {/* <option value="wk">Weekly</option> */}
                            </select>
                            </label>
                            <ChButton
                                action={this.handleClick}
                                type={"primary"}
                                title={"Submit"}
                                style={buttonStyle}
                            />{" "}
                            <div class="alert alert-primary" role="alert">
                            {this.state.msg}
                            </div>
                            {this.state.is_feasible ? null : <div class="alert alert-danger" role="alert">
                                                            {"The optimization is not feasible!"}
                                                            </div> 
                            }
                            {this.state.is_data_ok ? null : <div class="alert alert-danger" role="alert">
                                                            {/* {this.state.msg} */}
                                                            {"There is no data available. Please check tickers and/or date."}
                                                            </div> 
                            }
                        </form>

                        {/* <button className='button' onClick={this.handleClick}>Test button</button> */}
                        {/* <div>{this.state.data}</div> */}
                        {/* <div>{this.state.data}</div> */}
                        {/* {this.state.is_updated ? "not updated yet" : "updated>>>>>>" } */}

                    </div>
                    {console.log(this.state.data)}
                    {/* <Chart_Donut2 renderTo='chart1' title_text='Factor Exposure' data = {this.state.data}/> 
                    
                    <Chart_Donut2 renderTo='chart2' title_text='Sector Exposure' data = {this.state.data2}/>  */}

                    <br>
                    </br>

                        <h3>Performance Comparison</h3>
                    <div className="row">
                        <br></br>
                        <br></br>
                        <div className="col" id="chart1">
                            <Chart_StockLine2  title_text={"In Sample" } 
                                data = {this.state.perf_train}/>
                        </div>
                        <div className="col" id="chart2">
                            <Chart_StockLine2  title_text={"Out of Sample" } 
                                data = {this.state.perf_1}/>
                        </div>
                    </div>

                        <h3>Factor Sensitivity</h3>
                    <div classsName="row">
                        <div className="col" id="chart3">
                            <Chart_BoxPlot  title_text={"Neural Net" } 
                                data = {this.state.nn_sensitivity} xlabel={"Factors"}/>
                        </div>
                        <div className="col" id="chart4">
                            <Chart_BoxPlot 
                                title_text={"Linear Regression"} 
                                data = {this.state.ols_sensitivity}  
                                xlabel={"Factors"}/> 

                        </div>
                        {/* <div className="col" id="chart3">
                            <Chart_BoxPlottest  title_text={"Neural Net" } 
                                data = {this.state.nn_sensitivity} xlabel={"Factors"}/>
                        </div>  */}



                    </div>
                    <Footer />
                    {/* <footer class="mastfoot mt-auto">
                            <div class="inner">
                                <p>© 2023 Copyright Chris IJ Hwang</p>
                            </div>
                            <p>DISCLAIMER: The information in this site is for the purpose of research and backtest only. We don't promise any future profits and don't take responsibility for any trading losses.
                            </p>
            </footer> */}

                    
                </div>
            );

        } else if(this.state.loading === 1) { // loading
            return (
                <div id="pf1"><h1>Deep Learning Factor Model</h1>
                    <div class="alert alert-secondary" role="alert">
                    <h5>Overview</h5>
                    <p>A Deep Learning Framework for cross-sectional fundamental(Barra style) factor modeling.</p>
                    <p>Two different models(Linear and Non-linear) are used for computing the factor betas</p>
                    <ul>
                        <li>Model 1: Linear Regression</li>
                        <li>Model 2: Deep Learning </li>
                    </ul>
                    <h5>Universe</h5>
                        <p>1500+ stocks in U.S. market</p>
                        <p>from 2011-1-1 to 2022-5-31</p>
                        <h5>Factors</h5>
                        <p>Fundamental factors: </p>
                            <ul>
                                <li>MC: Log (Market Capitalization)</li>
                                <li>E_P: Earning to Price</li>
                                <li>B_P: Book to Price</li>
                                <li>DIV: Dividend Yield</li>
                                <li>EV: Enterprise Value = Market Cap + LT Debt + max(ST Debt-Cash, 0)</li>
                                <li>S_EV: Sale to Enterprise Value</li>
                                <li>EB_EV: EBIDTA to EV</li>
                                <li>more to be added </li>
                            </ul>

                        <h5>Beta</h5>
                        <p>250-day lookback period</p>

                        <p>Deep Fundamental Factor Models - Matthew F. Dixon, Nicholas G. Polson</p>

                    </div>
                    <div className="form1_input">
                        <form onSubmit={this.handleClick}>
                            
                            <label>
                            Select 
                            <select value={this.state.period} onChange={this.handleSelect}>
                            <option value="mth">Monthly</option>
                                {/* <option value="wk">Weekly</option> */}
                            </select>
                            </label>
                            <ChButton
                                action={this.handleClick}
                                type={"primary"}
                                title={"Submit"}
                                style={buttonStyle}
                            />{" "}
                            <div class="alert alert-primary" role="alert">
                            {this.state.msg}
                            </div>
                        </form>
                    </div>
                    <div align="center">
                        <br/>
                        <br/>
                        <br/>
                        <br/>

                        <Spinner animation="border" variant="success" >
                            
                        </Spinner>
                        <br/>
                        <br/>
                        <br/>
                        <br/>

                    </div>
                    <Footer />
                    {/* <footer class="mastfoot mt-auto">
                            <div class="inner">
                                <p>© 2023 Copyright Chris IJ Hwang</p>
                            </div>
                            <p>DISCLAIMER: The information in this site is for the purpose of research and backtest only. We don't promise any future profits and don't take responsibility for any trading losses.
                            </p>
            </footer> */}
                </div>
                
            );
        }
         else { // not started
            return ( 
                <div id="pf1"><h1>Deep Learning Factor Models</h1>
                    <div class="alert alert-secondary" role="alert">
                    <h5>Overview</h5>
                    <p>A Deep Learning Framework for cross-sectional fundamental(Barra style) factor modeling.</p>
                    <p>Two different models(Linear and Non-linear) are used for computing the factor betas</p>
                    <ul>
                        <li>Model 1: Linear Regression</li>
                        <li>Model 2: Deep Learning </li>
                    </ul>
                    <h5>Universe</h5>
                        <p>1500+ stocks in U.S. market</p>
                        <p>from 2011-1-1 to 2022-5-31</p>
                        <h5>Factors</h5>
                        <p>Fundamental factors: </p>
                            <ul>
                                <li>MC: Log (Market Capitalization)</li>
                                <li>E_P: Earning to Price</li>
                                <li>B_P: Book to Price</li>
                                <li>DIV: Dividend Yield</li>
                                <li>EV: Enterprise Value = Market Cap + LT Debt + max(ST Debt-Cash, 0)</li>
                                <li>S_EV: Sale to Enterprise Value</li>
                                <li>EB_EV: EBIDTA to EV</li>
                                <li>more to be added </li>
                            </ul>

                        <h5>Beta</h5>
                        <p>250-day lookback period</p>

                        <p>Deep Fundamental Factor Models - Matthew F. Dixon, Nicholas G. Polson</p>

                    </div>
                    <div className="form1_input">
                        <form onSubmit={this.handleClick}>
                           
                            <label>
                            Select 
                            <select value={this.state.period} onChange={this.handleSelect}>
                                <option value="mth">Monthly</option>
                                {/* <option value="wk">Weekly</option> */}
                            </select>
                            </label>
                            <ChButton
                                action={this.handleClick}
                                type={"primary"}
                                title={"Submit"}
                                style={buttonStyle}
                            />{" "}

                            <div class="alert alert-primary" role="alert">
                            {this.state.msg}
                            </div>
                            
                        </form>
                    </div>
                    <Footer />
                    {/* <footer class="mastfoot mt-auto">
                            <div class="inner">
                                <p>© 2023 Copyright Chris IJ Hwang</p>
                            </div>
                            <p>DISCLAIMER: The information in this site is for the purpose of research and backtest only. We don't promise any future profits and don't take responsibility for any trading losses.
                            </p>
            </footer> */}
                </div>
            );
        }
    }
}

const buttonStyle = {
    margin: "10px 10px 10px 10px"
 };

export default Dlfm;