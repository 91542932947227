import React from 'react';
import ReactDOM from 'react-dom';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import './index.css';
// import App from './App';

// import App4 from './App4';
// import TestApp from './TestApp';
// import TestBokeh from './TestBokeh';
// import TestReactVis from './TestReactVis';
// import Portfolio1 from './Portfolio1';
// import {Myfunc,
//   Myfunc1,
//   Hello,
//   Myfunc3,
//   App0,
//   App2,
//   App3,
//   App4,
//   App5,
//   App6,
//   App7} from './App';

import * as serviceWorker from './serviceWorker';


// import Square from './Square';
// import Label from './Label';
// import Card from './Card';
// import LightningCounter from './LightningCounter';
// import LightningCounterDisplay from './LightningCounterDisplay';
// import Circle from './Circle';
// import CounterParent from './CounterParent';
// import Chart1 from './Chart1';
// import Form1 from './Form1';
// import PortfolioForm1 from './PortfolioForm1';
// import PortfolioForm2 from './PortfolioForm2';

// import Chart_Donut from './Chart_Donut';
// import Chart_Donut2 from './Chart_Donut2';
// import Chart_Column_bar from './Chart_Column_bar';
// import Chart_Horizon_bar_sort from './Chart_Horizon_bar_sort';
// import Chart_Horizon_bar_multi from './Chart_Horizon_bar_multi';
import Main from './Main';
import Sidebar from './Sidebar';

ReactDOM.render (
    <Main />,
  document.getElementById("root")
)
// ReactDOM.render (
//   <Portfolio1 />,
//   document.getElementById("portfolio1")
// );





// ReactDOM.render(
//   <Chart_Donut />,
//   document.getElementById('chart1')
// );



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
