import React, {Component} from 'react';
import Spinner from 'react-bootstrap/Spinner';
import {API_URL_DASHBOARD1} from './config';
import axios from 'axios';
import {columns} from './predTableColumns';
import {dcolumns} from './predDailyTableColumns';

import Chart_StockLine from './Chart_StockLine_intraday';
import BootstrapTable from 'react-bootstrap-table-next';
import {Alert, Container, Row, Col} from 'react-bootstrap';

const defaultSorted = [{
    dataField : 'name',
    order: 'desc'
}];


class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: 1, // 1: loading, 2: done
            data : null,
            predData: null,
            ticker_names: ["btc", "eth", "etc", "bch", "bsv", "ltc", "doge"],
            period: 'H',
            num_of_period: 1,
            prev_date : null,
            pred_date : null,
        }
    }
    componentDidMount() {
        axios.post(`${API_URL_DASHBOARD1}`, {
            tickers: ["btc", "eth", "etc", "bch", "bsv", "ltc", "doge"],
            period: this.state.period,
            num_of_period: this.state.num_of_period
        })
        .then( (response) => {
            console.log(response);
            this.setState(
                prevState => ( {
                    loading: 2,
                    data: response.data['resdata'],
                    ticker_names:response.data['ticker_names'],
                    predData: response.data['predData'],
                    dpredData: response.data['dpredData'],
                    pred_price_date: response.data['pred_price_date'],
                    pred_price_date_only: response.data['pred_price_date_only'],
                    pred_price_hr_only: response.data['pred_price_hr_only'],
                }),
                () => console.log(this.state)
            );
        })
        .catch( function(error) {
            console.log(error);
        })
        .then( function() {
            console.log("Fetch done");
        });
    }
    
    render() {
        if (this.state.loading == 2) {

            return (
                <Container>
                        <h1>Crypto Price Predictions</h1>


                    <Row>
                        <br/>
                        {/* <div class="alert alert-secondary" role="alert">
                            <h5>Available Cryptos</h5>
                            <p>BTC, ETH, ETC, BCH, BSV, LTC, DOGE</p>

                            <p>From 2021-1-22</p>
                        </div> */}
                        <Alert variant='primary'> <h5>Available Cryptos</h5>
                            <p>BTC, ETH, ETC, BCH, BSV, LTC, DOGE</p>

                            <p>From 2021-1-22</p>
                        </Alert>
                    </Row>
                    <Row>
                        <h4>Predicted Price at {this.state.pred_price_hr_only}</h4>
                            <BootstrapTable 
                                keyField='model' 
                                data={ this.state.predData } 
                                columns={ columns } 
                                striped
                                hover
                                defaultSorted={ defaultSorted } 
                            />
                    </Row> 
                    <Row>
                        <h4>Predicted Price on {this.state.pred_price_date_only}</h4>
                            <BootstrapTable 
                                keyField='ticker' 
                                data={ this.state.dpredData } 
                                columns={ dcolumns } 
                                striped
                                hover
                                defaultSorted={ defaultSorted } 
                            />
                    </Row>
                    <Row>
                        <div>
                            {this.state.data.map((dd, index) =>
                                
                                //<Chart_StockLine key={index} title_text={"ticker : " + index} 
                                // <Chart_StockLine key={index} title_text={"ticker : " + this.state.ticker_r} 
                                <Chart_StockLine key={index} title_text={" " + this.state.ticker_names[index]} 
                                data = {dd}/>,
                                // <Chart_StockLine  title_text={"title_text"} 
                                // data = {dd[0]}/>
                                )
                                
                            }

                            {/* <Chart_StockLine  title_text={this.state.groupname} 
                                    data = {this.state.data}/>  */}
                        </div>
                    </Row>
                    <Row>
                    
                    <footer class="mastfoot mt-auto">
                            <div class="inner">
                                <p>© 2021 Copyright JK Blue</p>
                            </div>
                            <p>DISCLAIMER: The information in this site is for the purpose of research and backtest only. JK Blue LLC doesn't promise any future profits and doesn't take responsibility for any trading losses.
                            </p>
                    </footer>
                    </Row>
                </Container>

            );
        }else {//loading
            return (
                <Container>
                        <h1>Crypto Price Predictions</h1>

                    <Row>
                            <br/>
                            {/* <div class="alert alert-secondary" role="alert">
                            <h5>Available Cryptos</h5>
                                <p>BTC, ETH, ETC, BCH, BSV, LTC, DOGE</p>
                                <p>From 2021-1-22</p>
                            </div> */}
                            <Alert  variant='primary'> <h5>Available Cryptos</h5>
                                <p>BTC, ETH, ETC, BCH, BSV, LTC, DOGE</p>

                                <p>From 2021-1-22</p>
                            </Alert>
                    </Row>
                    <Row>
                        <div align="center">
                            <br/>
                            <br/>
                            <br/>
    
                            <Spinner animation="border" variant="success" >
                                
                            </Spinner>
                            <br/>
                            <br/>
                            <br/>

                        </div>
                    </Row>
                    <Row>

                        <footer class="mastfoot mt-auto">
                            <div class="inner">
                                <p>© 2021 Copyright JK Blue</p>
                            </div>
                            <p>DISCLAIMER: The information in this site is for the purpose of research and backtest only. JK Blue LLC doesn't promise any future profits and doesn't take responsibility for any trading losses.
                            </p>
                        </footer>
                    </Row>
                </Container>

            );

        }
    }
}


export default Dashboard 
