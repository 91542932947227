export const dailycolumns = [
    { dataField: 'ps',  text: 'P' , sort:true }, 
    { dataField: 'ds',  text: 'D' , sort:true }, 
    { dataField: 'qs',  text: 'Q' , sort:true }, 
    { dataField: 'lookback', text: 'lookback', sort:true }, 
    { dataField: 'day_4~', text: 'day_4~', sort:true }, 
    { dataField: 'day_8~4', text: 'day_8~4', sort:true }, 
    { dataField: 'day_12~8', text: 'day_12~8', sort:true }, 
    { dataField: 'day_16~12', text: 'day_16~12', sort:true }, 
    { dataField: 'day_12~', text: 'day_12~', sort:true }, 
    { dataField: 'day_24~20', text: 'day_24~20', sort:true }, 
    { dataField: 'pred_ret', text: 'Prediction', sort:true }, 

];
