export const columns = [
    { dataField: 'ticker',  text: 'Ticker' , sort:true }, 
    { dataField: 'prev_price',  text: 'From' , sort:true }, 
    { dataField: 'pred_price',  text: 'To' , sort:true }, 
    { dataField: 'ret', text: 'Exp Return (%)', sort:true }, 
    { dataField: '1wk', text: '1wk HR(%)', sort:true }, 
    { dataField: '2wk', text: '2wk HR(%)', sort:true }, 
    { dataField: '3wk', text: '3wk HR(%)', sort:true }, 

];
