import React, { Component } from 'react';

import './App.css';
import axios from 'axios';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

class Chart_Column_bar_multi extends React.Component {

    constructor(props) {
        super(props);
        

        this.state = {
            chart: {
                renderTo: this.props.renderTo,
                type: "column"
                // ,
                // events: {
                //     load: function() {
                //         var points = this.series[0].points,
                //         chart = this,
                //         newPoints = [];

                //         Highcharts.each(points, function(point, i) {
                //         point.update({
                //             name: categories[i]
                //         }, false);
                //         newPoints.push({
                //             x: point.x,
                //             y: point.y,
                //             name: point.name
                //         });
                //         });
                //         chart.redraw();

                //         // Sorting A - Z
                //         $('#sort1').on('click', function() {
                //         newPoints.sort(function(a, b) {
                //             if (a.name < b.name)
                //             return -1;
                //             if (a.name > b.name)
                //             return 1;
                //             return 0;
                //         });

                //         Highcharts.each(newPoints, function(el, i) {
                //             el.x = i;
                //         });

                //         chart.series[0].setData(newPoints, true, false, false);
                //         });

                //         // Sorting min - max
                //         $('#sort2').on('click', function() {
                //         newPoints.sort(function(a, b) {
                //             return a.y - b.y
                //         });

                //         Highcharts.each(newPoints, function(el, i) {
                //             el.x = i;
                //         });

                //         chart.series[0].setData(newPoints, true, false, false);
                //         });

                //         // Sorting max - min
                //         $('#sort3').on('click', function() {
                //         newPoints.sort(function(a, b) {
                //             return b.y - a.y
                //         });

                //         Highcharts.each(newPoints, function(el, i) {
                //             el.x = i;
                //         });

                //         chart.series[0].setData(newPoints, true, false, false);
                //         });
                //     }
                // }
            },
            title: {
                text: this.props.all_title_text
            },
            xAxis: {
                categories: this.props.categories
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled:true,
                        format: '{point.name}: {point.y:.4f} %'
                    }
                }
            },
            tooltip: {
                headerFormat: '<b>{series.name}</b><br>',
                pointFormat: '{point.y:,.4f} '
            },
            series: [
                {
                    name : this.props.title_text,
                    data : this.props.data
                    
                },
                {
                    name : this.props.title_text2,
                    data : this.props.data2
                    
                }
            ]
        }
        console.log(this.props.data);

    }

    

    // shouldComponentUpdate(newProps, newState) {
    //     console.log("shouldComponentUpdate: Should component update?");
    //     console.log(this.state.series);
    //     console.log(newState.series);
    //     if (this.state.series !== newState.series) {
    //       console.log("shouldComponentUpdate: Component should update!");
    //       return true;
    //     } else {
    //       //ReactDOM.unmountComponentAtNode(destination);
    //       console.log("shouldComponentUpdate: Component should not update!");
    //       return false;
    //     }
    // }

    render() {
        return (
            <div >
                <HighchartsReact 
                highcharts={Highcharts} 
                options={this.state} />
            </div>

        )
    } 
}
export default Chart_Column_bar_multi;