export const fundOptions = [
    {value: 'ABALX', label: 'ABALX', color: '#00B8D9'},
    {value: 'AGTHX', label: 'AGTHX', color: '#00B8D9'},
    {value: 'FXAIX', label: 'FXAIX', color: '#00B8D9'},
    {value: 'VBTLX', label: 'VBTLX', color: '#00B8D9'},
    {value: 'VFFSX', label: 'VFFSX', color: '#00B8D9'},
    {value: 'VFIAX', label: 'VFIAX', color: '#00B8D9'},
    {value: 'VGTSX', label: 'VGTSX', color: '#00B8D9'},
    {value: 'VIIIX', label: 'VIIIX', color: '#00B8D9'},
    {value: 'VINIX', label: 'VINIX', color: '#00B8D9'},
    {value: 'VMFXX', label: 'VMFXX', color: '#00B8D9'},
    {value: 'VSMPX', label: 'VSMPX', color: '#00B8D9'},
    {value: 'VTBIX', label: 'VTBIX', color: '#00B8D9'},
    {value: 'VTBNX', label: 'VTBNX', color: '#00B8D9'},
    {value: 'VTSAX', label: 'VTSAX', color: '#00B8D9'}
  ];