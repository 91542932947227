import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Spinner from 'react-bootstrap/Spinner'

import './App.css';
import axios from 'axios';

import ChInput from './ChInput';
import ChButton from './ChButton';
import {API_URL_HCA} from './config'
// import Chart_Donut from './Chart_Donut';
// import Chart_Donut2 from './Chart_Donut2';
// import Chart_Column_bar from './Chart_Column_bar';
// import Chart_Horizon_bar_sort from './Chart_Horizon_bar_sort';
// import Chart_Horizon_bar_multi from './Chart_Horizon_bar_multi';
// import Chart_Scatter from './Chart_Scatter';
import Chart_StockLine from './Chart_StockLine';

class HCAForm1 extends React.Component {
    constructor(props) {
        super(props);
        // var options = {
        //     chart: {
        //         type: "pie"
        //     },
        //     series: [
        //         {
        //             name : "sector",
        //             data : this.state.data
        //         }
        //     ]
        // }

        this.state = {
            msg: "Simple Equal Weight",
            is_feasible: true,
            not_feasible_msg : "",
            ticker_r: 'MMM ALK ABT ABBV ACN ATVI AAP AMZN AES LNT AMG AFL APD ALB ARE AMT MO ADM APC ANDV T',            
            optimizer: "euqal_weight",
            start_date: '2018-01-02',
            end_date: '2019-01-02',
            is_updated: false,
            loading: 0, // 0 : not started, 1: loading, 2: done
            groupname: null,
            data: null,
            valid_ticker: true,
            valid_enddate: true,
            valid_startdate: true,
            no_data_tickers: [],
            date_prob_tockers: []
        };

        console.log("this.state.is_updated: ", this.state.is_updated);

        this.handleClick = this.handleClick.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.handleInputStartDate = this.handleInputStartDate.bind(this);
        this.handleInputEndDate = this.handleInputEndDate.bind(this);
        // this.handleSelect = this.handleSelect.bind(this);
        // this.handleStateChange = this.handleStateChange.bind(this);
    }

 
    // shouldComponentUpdate(newProps, newState) {
    //     console.log("About to shouldComponent Updadte");
    //     if (this.state.is_updated === false) {
    //         return true;
    //     }
    //     else {
    //         this.setState( {
    //             is_updated: true
    //         })
    //         console.log("just updated is_update to true and update comp");
    //         return false;
    //     }
    // }

    // handleStateChange(e) {
    //     this.setState( {
    //         data: e
    //     })
    //     console.log("insde handleStateChange");
    //     console.log(this.state.data);
    // }


    handleClick(e) {
        var dt = {
            'A': ['2010-01-02', '2019-08-27'],
             'AAL': ['2010-01-02', '2019-08-27'],
             'AAP': ['2010-01-02', '2019-08-27'],
             'AAPL': ['2010-01-02', '2019-08-27'],
             'ABBV': ['2014-01-02', '2019-08-27'],
             'ABC': ['2010-01-02', '2019-08-27'],
             'ABMD': ['2010-01-02', '2019-08-27'],
             'ABT': ['2010-01-02', '2019-08-27'],
             'ACN': ['2010-01-02', '2019-08-27'],
             'ADBE': ['2010-01-02', '2019-08-27'],
             'ADI': ['2010-01-02', '2019-08-27'],
             'ADM': ['2010-01-02', '2019-08-27'],
             'ADP': ['2010-01-02', '2019-08-27'],
             'ADS': ['2010-01-02', '2019-08-27'],
             'ADSK': ['2010-01-02', '2019-08-27'],
             'AEE': ['2019-01-12', '2019-08-27'],
             'AEP': ['2010-01-02', '2019-08-27'],
             'AES': ['2010-01-02', '2019-08-27'],
             'AET': ['2010-01-02', '2019-08-27'],
             'AFL': ['2010-01-02', '2019-08-27'],
             'AGN': ['2010-01-02', '2019-08-27'],
             'AIG': ['2010-01-02', '2019-08-27'],
             'AIV': ['2010-01-02', '2019-08-27'],
             'AIZ': ['2010-01-02', '2019-08-27'],
             'AJG': ['2010-01-02', '2019-08-27'],
             'AKAM': ['2010-01-02', '2019-08-27'],
             'ALB': ['2010-01-02', '2019-08-27'],
             'ALGN': ['2010-01-02', '2019-08-27'],
             'ALK': ['2010-01-02', '2019-08-27'],
             'ALL': ['2010-01-02', '2019-08-27'],
             'ALLE': ['2014-11-18', '2019-08-27'],
             'ALXN': ['2010-01-02', '2019-08-27'],
             'AMAT': ['2010-01-02', '2019-08-27'],
             'AMD': ['2010-01-02', '2019-08-27'],
             'AME': ['2010-01-02', '2019-08-27'],
             'AMG': ['2010-01-02', '2019-08-27'],
             'AMGN': ['2010-01-02', '2019-08-27'],
             'AMP': ['2019-01-12', '2019-08-27'],
             'AMT': ['2010-01-02', '2019-08-27'],
             'AMZN': ['2010-01-02', '2019-08-27'],
             'ANDV': ['2010-01-02', '2019-08-27'],
             'ANET': ['2015-06-06', '2019-08-27'],
             'ANSS': ['2010-01-02', '2019-08-27'],
             'ANTM': ['2010-01-02', '2019-08-27'],
             'AON': ['2010-01-02', '2019-08-27'],
             'AOS': ['2010-01-02', '2019-08-27'],
             'APA': ['2010-01-02', '2019-08-27'],
             'APC': ['2010-01-02', '2019-08-27'],
             'APD': ['2010-01-02', '2019-08-27'],
             'APH': ['2010-01-02', '2019-08-27'],
             'APTV': ['2012-11-17', '2019-08-02'],
             'ARE': ['2010-01-02', '2019-08-27'],
             'ARNC': ['2010-01-02', '2019-08-27'],
             'ATVI': ['2010-01-02', '2019-08-27'],
             'AVB': ['2010-01-02', '2019-08-27'],
             'AVGO': ['2010-08-06', '2019-08-27'],
             'AVY': ['2010-01-02', '2019-08-27'],
             'AWK': ['2010-01-02', '2019-08-27'],
             'AXP': ['2010-01-02', '2019-08-27'],
             'AZO': ['2010-01-02', '2019-08-27'],
             'BA': ['2010-01-02', '2019-08-27'],
             'BAC': ['2010-01-02', '2019-08-27'],
             'BAX': ['2010-01-02', '2019-08-27'],
             'BBT': ['2010-01-02', '2019-08-27'],
             'BBY': ['2010-01-02', '2019-08-27'],
             'BDX': ['2010-01-02', '2019-08-27'],
             'BEN': ['2010-01-02', '2019-08-27'],
             'BHF': ['2010-01-02', '2019-08-27'],
             'BHGE': ['2010-01-02', '2019-08-27'],
             'BIIB': ['2010-01-02', '2019-08-27'],
             'BK': ['2010-01-02', '2019-08-27'],
             'BKNG': ['2010-01-02', '2019-08-27'],
             'BLK': ['2010-01-02', '2019-08-27'],
             'BLL': ['2010-01-02', '2019-08-27'],
             'BMY': ['2010-01-02', '2019-08-27'],
             'BR': ['2010-01-02', '2019-08-27'],
             'BSX': ['2010-01-02', '2019-08-27'],
             'BWA': ['2010-01-02', '2019-08-27'],
             'BXP': ['2010-01-02', '2019-08-27'],
             'C': ['2010-01-02', '2019-08-27'],
             'CA': ['2010-01-02', '2019-08-27'],
             'CAG': ['2010-01-02', '2019-08-27'],
             'CAH': ['2010-01-02', '2019-08-27'],
             'CAT': ['2010-01-02', '2019-08-27'],
             'CB': ['2010-01-02', '2019-08-27'],
             'CBOE': ['2011-06-15', '2019-08-27'],
             'CBRE': ['2019-01-16', '2019-08-27'],
             'CBS': ['2010-01-02', '2019-08-27'],
             'CCI': ['2010-01-02', '2019-08-27'],
             'CCL': ['2010-01-02', '2019-08-27'],
             'CDNS': ['2010-01-02', '2019-08-27'],
             'CELG': ['2010-01-02', '2019-08-27'],
             'CERN': ['2010-01-02', '2019-08-27'],
             'CF': ['2010-01-02', '2019-08-27'],
             'CFG': ['2015-09-24', '2019-08-27'],
             'CHD': ['2010-01-02', '2019-08-27'],
             'CHRW': ['2010-01-02', '2019-08-27'],
             'CHTR': ['2011-01-05', '2019-08-27'],
             'CI': ['2010-01-02', '2019-08-27'],
             'CINF': ['2010-01-02', '2019-08-27'],
             'CL': ['2010-01-02', '2019-08-27'],
             'CLX': ['2010-01-02', '2019-08-27'],
             'CMA': ['2010-01-02', '2019-08-27'],
             'CMCSA': ['2010-01-02', '2019-08-27'],
             'CME': ['2010-01-02', '2019-08-27'],
             'CMG': ['2010-01-02', '2019-08-27'],
             'CMI': ['2010-01-02', '2019-08-27'],
             'CMS': ['2010-01-02', '2019-08-27'],
             'CNC': ['2010-01-02', '2019-08-27'],
             'CNP': ['2010-01-02', '2019-08-27'],
             'COF': ['2010-01-02', '2019-08-27'],
             'COG': ['2010-01-02', '2019-08-27'],
             'COL': ['2010-01-02', '2019-08-27'],
             'COO': ['2010-01-02', '2019-08-27'],
             'COP': ['2010-01-02', '2019-08-27'],
             'COST': ['2010-01-02', '2019-08-27'],
             'COTY': ['2014-06-13', '2019-08-27'],
             'CPB': ['2010-01-02', '2019-08-27'],
             'CPRT': ['2010-01-02', '2019-08-27'],
             'CRM': ['2010-01-02', '2019-08-27'],
             'CSCO': ['2010-01-02', '2019-08-27'],
             'CSX': ['2010-01-02', '2019-08-27'],
             'CTAS': ['2010-01-02', '2019-08-27'],
             'CTL': ['2010-01-02', '2019-08-27'],
             'CTSH': ['2010-01-02', '2019-08-27'],
             'CTXS': ['2010-01-02', '2019-08-27'],
             'CVS': ['2010-01-02', '2019-08-27'],
             'CVX': ['2010-01-02', '2019-08-27'],
             'CXO': ['2010-01-02', '2019-08-27'],
             'D': ['2010-01-02', '2019-08-27'],
             'DAL': ['2010-01-02', '2019-08-27'],
             'DE': ['2010-01-02', '2019-08-27'],
             'DFS': ['2010-01-02', '2019-08-27'],
             'DG': ['2010-11-13', '2019-08-27'],
             'DGX': ['2019-01-16', '2019-08-27'],
             'DHI': ['2010-01-02', '2019-08-27'],
             'DHR': ['2010-01-02', '2019-08-27'],
             'DIS': ['2010-01-02', '2019-08-27'],
             'DISCA': ['2010-01-02', '2019-08-27'],
             'DISCK': ['2010-01-02', '2019-08-27'],
             'DISH': ['2010-01-02', '2019-08-27'],
             'DLR': ['2010-01-02', '2019-08-27'],
             'DLTR': ['2010-01-02', '2019-08-27'],
             'DOV': ['2010-01-02', '2019-08-27'],
             'DRE': ['2010-01-02', '2019-08-27'],
             'DRI': ['2010-01-02', '2019-08-27'],
             'DTE': ['2010-01-02', '2019-08-27'],
             'DUK': ['2010-01-02', '2019-08-27'],
             'DVA': ['2010-01-02', '2019-08-27'],
             'DVN': ['2010-01-02', '2019-08-27'],
             'DWDP': ['2010-01-02', '2019-08-27'],
             'DXC': ['2010-01-02', '2019-08-21'],
             'EA': ['2010-01-02', '2019-08-27'],
             'EBAY': ['2010-01-02', '2019-08-27'],
             'ECL': ['2010-01-02', '2019-08-27'],
             'ED': ['2010-01-02', '2019-08-27'],
             'EFX': ['2010-01-02', '2019-08-27'],
             'EIX': ['2010-01-02', '2019-08-27'],
             'EL': ['2010-01-02', '2019-08-27'],
             'EMN': ['2010-01-02', '2019-08-27'],
             'EMR': ['2010-01-02', '2019-08-27'],
             'EOG': ['2010-01-02', '2019-08-27'],
             'EQIX': ['2010-01-02', '2019-08-27'],
             'EQR': ['2010-01-02', '2019-08-27'],
             'EQT': ['2010-01-02', '2019-08-27'],
             'ES': ['2010-01-02', '2019-08-27'],
             'ESRX': ['2010-01-02', '2019-08-27'],
             'ESS': ['2010-01-02', '2019-08-27'],
             'ETFC': ['2010-01-02', '2019-08-27'],
             'ETN': ['2010-01-02', '2019-08-27'],
             'ETR': ['2010-01-02', '2019-08-27'],
             'EVHC': ['2014-08-14', '2019-08-27'],
             'EVRG': ['2019-05-24', '2019-08-27'],
             'EW': ['2010-01-02', '2019-08-27'],
             'EXC': ['2010-01-02', '2019-08-27'],
             'EXPD': ['2010-01-02', '2019-08-27'],
             'EXPE': ['2010-01-02', '2019-08-27'],
             'EXR': ['2010-01-02', '2019-08-27'],
             'F': ['2010-01-02', '2019-08-27'],
             'FAST': ['2010-01-02', '2019-08-27'],
             'FB': ['2013-05-18', '2019-08-27'],
             'FBHS': ['2012-09-16', '2019-08-27'],
             'FCX': ['2010-01-02', '2019-08-27'],
             'FDX': ['2010-01-02', '2019-08-27'],
             'FE': ['2010-01-02', '2019-08-27'],
             'FFIV': ['2010-01-02', '2019-08-27'],
             'FIS': ['2010-01-02', '2019-08-27'],
             'FISV': ['2010-01-02', '2019-08-27'],
             'FITB': ['2010-01-02', '2019-08-27'],
             'FL': ['2010-01-02', '2019-08-27'],
             'FLIR': ['2010-01-02', '2019-08-27'],
             'FLR': ['2010-01-02', '2019-08-27'],
             'FLS': ['2010-01-02', '2019-08-27'],
             'FLT': ['2011-12-15', '2019-08-27'],
             'FMC': ['2010-01-02', '2019-08-27'],
             'FOX': ['2010-01-02', '2019-08-27'],
             'FOXA': ['2010-01-02', '2019-08-27'],
             'FRT': ['2010-01-02', '2019-08-27'],
             'FTI': ['2010-01-02', '2019-08-27'],
             'FTV': ['2017-07-05', '2019-08-27'],
             'GD': ['2010-01-02', '2019-08-27'],
             'GE': ['2010-01-02', '2019-08-27'],
             'GILD': ['2010-01-02', '2019-08-27'],
             'GIS': ['2010-01-02', '2019-08-27'],
             'GLW': ['2010-01-02', '2019-08-27'],
             'GM': ['2011-11-18', '2019-08-27'],
             'GOOG': ['2010-01-02', '2019-08-27'],
             'GOOGL': ['2010-01-02', '2019-08-27'],
             'GPC': ['2010-01-02', '2019-08-27'],
             'GPN': ['2010-01-02', '2019-08-27'],
             'GPS': ['2019-01-12', '2019-08-27'],
             'GRMN': ['2010-01-02', '2019-08-27'],
             'GS': ['2010-01-02', '2019-08-27'],
             'GT': ['2010-01-02', '2019-08-27'],
             'GWW': ['2010-01-02', '2019-08-27'],
             'HAL': ['2010-01-02', '2019-08-27'],
             'HAS': ['2010-01-02', '2019-08-27'],
             'HBAN': ['2010-01-02', '2019-08-27'],
             'HBI': ['2010-01-02', '2019-08-27'],
             'HCA': ['2012-03-10', '2019-08-27'],
             'HCP': ['2010-01-02', '2019-08-27'],
             'HD': ['2010-01-02', '2019-08-27'],
             'HES': ['2010-01-02', '2019-08-27'],
             'HFC': ['2010-01-02', '2019-08-27'],
             'HIG': ['2010-01-02', '2019-08-27'],
             'HII': ['2012-03-22', '2019-08-27'],
             'HLT': ['2014-12-12', '2019-08-27'],
             'HOG': ['2010-01-02', '2019-08-27'],
             'HOLX': ['2010-01-02', '2019-08-27'],
             'HON': ['2010-01-02', '2019-08-27'],
             'HP': ['2010-01-02', '2019-08-27'],
             'HPE': ['2016-10-19', '2019-08-27'],
             'HPQ': ['2010-01-02', '2019-08-27'],
             'HRB': ['2010-01-02', '2019-08-27'],
             'HRL': ['2010-01-02', '2019-08-27'],
             'HRS': ['2010-01-02', '2019-08-27'],
             'HSIC': ['2010-01-02', '2019-08-27'],
             'HST': ['2010-01-02', '2019-08-27'],
             'HSY': ['2010-01-02', '2019-08-27'],
             'HUM': ['2010-01-02', '2019-08-27'],
             'IBM': ['2010-01-02', '2019-08-27'],
             'ICE': ['2010-01-02', '2019-08-27'],
             'IDXX': ['2010-01-02', '2019-08-27'],
             'IFF': ['2010-01-02', '2019-08-27'],
             'ILMN': ['2010-01-02', '2019-08-27'],
             'INCY': ['2010-01-02', '2019-08-27'],
             'INFO': ['2015-06-19', '2019-08-27'],
             'INTC': ['2010-01-02', '2019-08-27'],
             'INTU': ['2010-01-02', '2019-08-27'],
             'IP': ['2010-01-02', '2019-08-27'],
             'IPG': ['2010-01-02', '2019-08-27'],
             'IPGP': ['2010-01-02', '2019-08-27'],
             'IQV': ['2014-05-09', '2019-08-27'],
             'IR': ['2010-01-02', '2019-08-27'],
             'IRM': ['2010-01-02', '2019-08-27'],
             'ISRG': ['2010-01-02', '2019-08-27'],
             'IT': ['2010-01-02', '2019-08-27'],
             'ITW': ['2010-01-02', '2019-08-27'],
             'IVZ': ['2010-01-02', '2019-08-27'],
             'JBHT': ['2010-01-02', '2019-08-27'],
             'JCI': ['2010-01-02', '2019-08-27'],
             'JEC': ['2010-01-02', '2019-08-27'],
             'JEF': ['2010-01-02', '2019-08-27'],
             'JNJ': ['2010-01-02', '2019-08-27'],
             'JNPR': ['2010-01-02', '2019-08-27'],
             'JPM': ['2010-01-02', '2019-08-27'],
             'JWN': ['2010-01-02', '2019-08-27'],
             'K': ['2010-01-02', '2019-08-27'],
             'KEY': ['2010-01-02', '2019-08-27'],
             'KHC': ['2016-07-06', '2019-08-27'],
             'KIM': ['2010-01-02', '2019-08-27'],
             'KLAC': ['2010-01-02', '2019-08-27'],
             'KMB': ['2010-01-02', '2019-08-27'],
             'KMI': ['2012-02-11', '2019-08-27'],
             'KMX': ['2010-01-02', '2019-08-27'],
             'KO': ['2010-01-02', '2019-08-27'],
             'KORS': ['2012-12-15', '2019-08-27'],
             'KR': ['2010-01-02', '2019-08-27'],
             'KSS': ['2010-01-02', '2019-08-27'],
             'KSU': ['2010-01-02', '2019-08-27'],
             'L': ['2010-01-02', '2019-08-27'],
             'LB': ['2010-01-02', '2019-08-27'],
             'LEG': ['2010-01-02', '2019-08-27'],
             'LEN': ['2010-01-02', '2019-08-27'],
             'LH': ['2010-01-02', '2019-08-27'],
             'LKQ': ['2010-01-02', '2019-08-27'],
             'LLL': ['2010-01-02', '2019-08-27'],
             'LLY': ['2010-01-02', '2019-08-27'],
             'LMT': ['2010-01-02', '2019-08-27'],
             'LNC': ['2010-01-02', '2019-08-27'],
             'LNT': ['2010-01-02', '2019-08-27'],
             'LOW': ['2010-01-02', '2019-08-27'],
             'LRCX': ['2010-01-02', '2019-08-27'],
             'LUV': ['2010-01-02', '2019-08-27'],
             'LYB': ['2011-04-28', '2019-08-27'],
             'M': ['2010-01-02', '2019-08-27'],
             'MA': ['2010-01-02', '2019-08-27'],
             'MAA': ['2010-01-02', '2019-08-27'],
             'MAC': ['2010-01-02', '2019-08-27'],
             'MAR': ['2010-01-02', '2019-08-27'],
             'MAS': ['2010-01-02', '2019-08-27'],
             'MAT': ['2010-01-02', '2019-08-27'],
             'MCD': ['2010-01-02', '2019-08-27'],
             'MCHP': ['2010-01-02', '2019-08-27'],
             'MCK': ['2010-01-02', '2019-08-27'],
             'MCO': ['2010-01-02', '2019-08-27'],
             'MDLZ': ['2010-01-02', '2019-08-27'],
             'MDT': ['2010-01-02', '2019-08-27'],
             'MET': ['2010-01-02', '2019-08-27'],
             'MGM': ['2010-01-02', '2019-08-27'],
             'MHK': ['2010-01-02', '2019-08-27'],
             'MKC': ['2010-01-02', '2019-08-27'],
             'MLM': ['2010-01-02', '2019-08-27'],
             'MMC': ['2010-01-02', '2019-08-27'],
             'MMM': ['2010-01-02', '2019-08-27'],
             'MNST': ['2010-01-02', '2019-08-27'],
             'MO': ['2010-01-02', '2019-08-27'],
             'MOS': ['2010-01-02', '2019-08-27'],
             'MPC': ['2012-06-24', '2019-08-27'],
             'MRK': ['2010-01-02', '2019-08-27'],
             'MRO': ['2010-01-02', '2019-08-27'],
             'MS': ['2010-01-02', '2019-08-27'],
             'MSCI': ['2010-01-02', '2019-08-27'],
             'MSFT': ['2010-01-02', '2019-08-27'],
             'MSI': ['2010-01-02', '2019-08-27'],
             'MTB': ['2010-01-02', '2019-08-27'],
             'MTD': ['2010-01-02', '2019-08-27'],
             'MU': ['2010-01-02', '2019-08-27'],
             'MYL': ['2010-01-02', '2019-08-27'],
             'NBL': ['2010-01-02', '2019-08-27'],
             'NCLH': ['2014-01-18', '2019-08-27'],
             'NDAQ': ['2010-01-02', '2019-08-27'],
             'NEE': ['2010-01-02', '2019-08-27'],
             'NEM': ['2010-01-02', '2019-08-27'],
             'NFLX': ['2010-01-02', '2019-08-27'],
             'NFX': ['2010-01-02', '2019-08-27'],
             'NI': ['2010-01-02', '2019-08-27'],
             'NKE': ['2010-01-02', '2019-08-27'],
             'NKTR': ['2010-01-02', '2019-08-27'],
             'NLSN': ['2012-01-27', '2019-08-27'],
             'NOC': ['2010-01-02', '2019-08-27'],
             'NOV': ['2010-01-02', '2019-08-27'],
             'NRG': ['2010-01-02', '2019-08-27'],
             'NSC': ['2010-01-02', '2019-08-27'],
             'NTAP': ['2010-01-02', '2019-08-27'],
             'NTRS': ['2010-01-02', '2019-08-27'],
             'NUE': ['2010-01-02', '2019-08-27'],
             'NVDA': ['2010-01-02', '2019-08-27'],
             'NWL': ['2010-01-02', '2019-08-27'],
             'NWS': ['2014-06-19', '2019-08-27'],
             'NWSA': ['2014-06-19', '2019-08-27'],
             'O': ['2010-01-02', '2019-08-27'],
             'OKE': ['2010-01-02', '2019-08-27'],
             'OMC': ['2010-01-02', '2019-08-27'],
             'ORCL': ['2010-01-02', '2019-08-27'],
             'ORLY': ['2010-01-02', '2019-08-27'],
             'OXY': ['2010-01-02', '2019-08-27'],
             'PAYX': ['2010-01-02', '2019-08-27'],
             'PBCT': ['2010-01-02', '2019-08-27'],
             'PCAR': ['2010-01-02', '2019-08-27'],
             'PCG': ['2010-01-02', '2019-08-27'],
             'PEG': ['2010-01-02', '2019-08-27'],
             'PEP': ['2010-01-02', '2019-08-27'],
             'PFE': ['2010-01-02', '2019-08-27'],
             'PFG': ['2010-01-02', '2019-08-27'],
             'PG': ['2010-01-02', '2019-08-27'],
             'PGR': ['2010-01-02', '2019-08-27'],
             'PH': ['2010-01-02', '2019-08-27'],
             'PHM': ['2010-01-02', '2019-08-27'],
             'PKG': ['2010-01-02', '2019-08-27'],
             'PKI': ['2010-01-02', '2019-08-27'],
             'PLD': ['2010-01-02', '2019-08-27'],
             'PM': ['2010-01-02', '2019-08-27'],
             'PNC': ['2010-01-02', '2019-08-27'],
             'PNR': ['2010-01-02', '2019-08-27'],
             'PNW': ['2010-01-02', '2019-08-27'],
             'PPG': ['2019-01-12', '2019-08-27'],
             'PPL': ['2019-01-16', '2019-08-27'],
             'PRGO': ['2010-01-02', '2019-08-27'],
             'PRU': ['2010-01-02', '2019-08-27'],
             'PSA': ['2010-01-02', '2019-08-27'],
             'PSX': ['2013-04-12', '2019-08-27'],
             'PVH': ['2010-01-02', '2019-08-27'],
             'PWR': ['2010-01-02', '2019-08-27'],
             'PX': ['2010-01-02', '2019-08-27'],
             'PXD': ['2010-01-02', '2019-08-27'],
             'PYPL': ['2016-07-06', '2019-08-27'],
             'QCOM': ['2010-01-02', '2019-08-27'],
             'QRVO': ['2016-01-02', '2019-08-27'],
             'RCL': ['2010-01-02', '2019-08-27'],
             'RE': ['2010-01-02', '2019-08-27'],
             'REG': ['2010-01-02', '2019-08-27'],
             'REGN': ['2010-01-02', '2019-08-27'],
             'RF': ['2010-01-02', '2019-08-27'],
             'RHI': ['2010-01-02', '2019-08-27'],
             'RHT': ['2010-01-02', '2019-08-27'],
             'RJF': ['2010-01-02', '2019-08-27'],
             'RL': ['2010-01-02', '2019-08-27'],
             'RMD': ['2010-01-02', '2019-08-27'],
             'ROK': ['2010-01-02', '2019-08-27'],
             'ROP': ['2010-01-02', '2019-08-27'],
             'ROST': ['2010-01-02', '2019-08-27'],
             'RSG': ['2019-01-12', '2019-08-27'],
             'RTN': ['2010-01-02', '2019-08-27'],
             'SBAC': ['2010-01-02', '2019-08-27'],
             'SBUX': ['2010-01-02', '2019-08-27'],
             'SCG': ['2010-01-02', '2019-08-27'],
             'SCHW': ['2010-01-02', '2019-08-27'],
             'SEE': ['2010-01-02', '2019-08-27'],
             'SHW': ['2010-01-02', '2019-08-27'],
             'SIVB': ['2010-01-02', '2019-08-27'],
             'SJM': ['2010-01-02', '2019-08-27'],
             'SLB': ['2010-01-02', '2019-08-27'],
             'SLG': ['2010-01-02', '2019-08-27'],
             'SNA': ['2010-01-02', '2019-08-27'],
             'SNPS': ['2010-01-02', '2019-08-27'],
             'SO': ['2010-01-02', '2019-08-27'],
             'SPG': ['2010-01-02', '2019-08-27'],
             'SPGI': ['2010-01-02', '2019-08-27'],
             'SRCL': ['2010-01-02', '2019-08-27'],
             'SRE': ['2010-01-02', '2019-08-27'],
             'STI': ['2010-01-02', '2019-08-27'],
             'STT': ['2010-01-02', '2019-08-27'],
             'STX': ['2010-01-02', '2019-08-27'],
             'STZ': ['2010-01-02', '2019-08-27'],
             'SWK': ['2010-01-02', '2019-08-27'],
             'SWKS': ['2010-01-02', '2019-08-27'],
             'SYF': ['2015-07-31', '2019-08-27'],
             'SYK': ['2010-01-02', '2019-08-27'],
             'SYMC': ['2010-01-02', '2019-08-27'],
             'SYY': ['2010-01-02', '2019-08-27'],
             'T': ['2010-01-02', '2019-08-27'],
             'TAP': ['2010-01-02', '2019-08-27'],
             'TDG': ['2010-01-02', '2019-08-27'],
             'TEL': ['2010-01-02', '2019-08-27'],
             'TGT': ['2010-01-02', '2019-08-27'],
             'TIF': ['2010-01-02', '2019-08-27'],
             'TJX': ['2010-01-02', '2019-08-27'],
             'TMK': ['2010-01-02', '2019-08-27'],
             'TMO': ['2010-01-02', '2019-08-27'],
             'TPR': ['2010-01-02', '2019-08-27'],
             'TRIP': ['2012-12-07', '2019-08-27'],
             'TROW': ['2010-01-02', '2019-08-27'],
             'TRV': ['2010-01-02', '2019-08-27'],
             'TSCO': ['2010-01-02', '2019-08-27'],
             'TSN': ['2010-01-02', '2019-08-27'],
             'TSS': ['2010-01-02', '2019-08-27'],
             'TTWO': ['2010-01-02', '2019-08-27'],
             'TWTR': ['2014-11-07', '2019-08-27'],
             'TXN': ['2010-01-02', '2019-08-27'],
             'TXT': ['2010-01-02', '2019-08-27'],
             'UA': ['2016-07-01', '2019-08-27'],
             'UAA': ['2010-01-02', '2019-08-27'],
             'UAL': ['2010-01-02', '2019-08-27'],
             'UDR': ['2019-01-16', '2019-08-27'],
             'UHS': ['2010-01-02', '2019-08-27'],
             'ULTA': ['2010-01-02', '2019-08-27'],
             'UNH': ['2010-01-02', '2019-08-27'],
             'UNM': ['2010-01-02', '2019-08-27'],
             'UNP': ['2010-01-02', '2019-08-27'],
             'UPS': ['2010-01-02', '2019-08-27'],
             'URI': ['2010-01-02', '2019-08-27'],
             'USB': ['2010-01-02', '2019-08-27'],
             'UTX': ['2010-01-02', '2019-08-27'],
             'V': ['2010-01-02', '2019-08-27'],
             'VAR': ['2010-01-02', '2019-08-27'],
             'VFC': ['2010-01-02', '2019-08-27'],
             'VIAB': ['2010-01-02', '2019-08-27'],
             'VLO': ['2010-01-02', '2019-08-27'],
             'VMC': ['2010-01-02', '2019-08-27'],
             'VNO': ['2010-01-02', '2019-08-27'],
             'VRSK': ['2010-10-07', '2019-08-27'],
             'VRSN': ['2010-01-02', '2019-08-27'],
             'VRTX': ['2010-01-02', '2019-08-27'],
             'VTR': ['2010-01-02', '2019-08-27'],
             'VZ': ['2010-01-02', '2019-08-27'],
             'WAT': ['2010-01-02', '2019-08-27'],
             'WBA': ['2010-01-02', '2019-08-27'],
             'WDC': ['2010-01-02', '2019-08-27'],
             'WEC': ['2010-01-02', '2019-08-27'],
             'WELL': ['2010-01-02', '2019-08-27'],
             'WFC': ['2010-01-02', '2019-08-27'],
             'WHR': ['2010-01-02', '2019-08-27'],
             'WLTW': ['2010-01-02', '2019-08-27'],
             'WM': ['2010-01-02', '2019-08-27'],
             'WMB': ['2010-01-02', '2019-08-27'],
             'WMT': ['2010-01-02', '2019-08-27'],
             'WRK': ['2016-06-24', '2019-08-27'],
             'WU': ['2010-01-02', '2019-08-27'],
             'WY': ['2010-01-02', '2019-08-27'],
             'WYNN': ['2010-01-02', '2019-08-27'],
             'XEC': ['2010-01-02', '2019-08-27'],
             'XEL': ['2010-01-02', '2019-08-27'],
             'XL': ['2010-01-02', '2019-08-27'],
             'XLNX': ['2010-01-02', '2019-08-27'],
             'XOM': ['2010-01-02', '2019-08-27'],
             'XRAY': ['2010-01-02', '2019-08-27'],
             'XRX': ['2010-01-02', '2019-08-27'],
             'XYL': ['2012-10-13', '2019-08-27'],
             'YUM': ['2010-01-02', '2019-08-27'],
             'ZBH': ['2010-01-02', '2019-08-27'],
             'ZION': ['2010-01-02', '2019-08-27'],
             'ZTS': ['2014-02-01', '2019-08-27'] 
            };
        e.preventDefault(); 
        if (!this.state.valid_ticker || !this.state.valid_enddate || !this.state.valid_startdate) {
            
            return;
        }
        var lst_no_data_tickers = [];
        var lst_date_prob_tockers= []
        var x;
        // for (x of this.state.ticker_r.trim().split(" ")) {
        // for (x of this.state.ticker_r.trim().split(/[ ,]+/)) {
        //     if (!(x  in dt)){
        //         console.log(x);
        //         lst_no_data_tickers[lst_no_data_tickers.length] = x;
        //     }else {
        //         if ( (Date.parse(dt[x][0]) >= Date.parse(this.state.start_date)) || (Date.parse(dt[x][1]) <= Date.parse(this.state.end_date)) )  {
        //             console.log(x);
        //             lst_date_prob_tockers[lst_date_prob_tockers.length] = x;
        //         }
        //     }
        // }

        if ( (lst_no_data_tickers.length !=0) || ( lst_date_prob_tockers.length !=0)) {
            console.log(lst_no_data_tickers);
            console.log(lst_date_prob_tockers);
            this.setState( 
                prevState => ( {
                    no_data_tickers: lst_no_data_tickers,
                    date_prob_tockers:lst_date_prob_tockers 
                })
            );
            return ;
        }

        let userData = this.state.ticker_r;
        var tickers = [];
        this.setState( 
            prevState => ( {
                loading: 1,
                no_data_tickers: [],
                date_prob_tockers: []
            })
        );
        //var self = this;
        // axios.get function will lose the context of 'this'. So, copy it into
        // var self == this. then use self when update the state lilke 
        // "self.setState( {  .... } );". this properly point this component.
        //axios.post("http://192.168.1.12:5000/api/hca_1", {
        axios.post(`${API_URL_HCA}`, {
        //axios.post("http://127.0.0.1:5000/api/hca_1", {
                ticker: this.state.ticker_r,
                start_date: this.state.start_date,
                end_date: this.state.end_date
            })
            //.then(function(response) {
            .then( (response) => {
                console.log(response);
                

                this.setState (
                    prevState => ( {
                        loading: 2,
                        //groupname: response.data[0]['name'],
                        data: response.data
                        // categories2: response.data[1][0]['categories'],
                        // data2: response.data[1][1]['data'],
                        // categories3: response.data[2][0]['categories'],
                        // data3: response.data[2][1]['data'],
                        // categories4: response.data[3][0]['categories'],
                        // data4: response.data[3][1]['data'],
                        // categories5: response.data[4][0]['categories'],
                        // data5: response.data[4][1]['data'],
                        // categories6: response.data[5][0]['categories'],
                        // data6: response.data[5][1]['data'],
                        // categories7: response.data[6][0]['categories'],
                        // data7: response.data[6][1]['data'],
                        // categories8: response.data[7][0]['categories'],
                        // data8: response.data[7][1]['data'],
                        // series_for_scatter: response.data[8],
                        // is_feasible: response.data[9]['is_feasible'],
                        // categories_weight: response.data[10][0]['categories'],
                        // data_weight: response.data[10][1]['data'],
                        

                    }),
                    () => console.log(this.state.data)
                );

                // if (this.state.is_feable == 'infeasible') {
                //     this.setState (
                //         prevState => ( {
                //             is_feasible: false,
                //             not_feasible_msg: "Optimization is not feasible"
                //         }),
                //         () => console.log(this.state)
                //     );
                // }


                
                //console.log(self.options)

                // self.setState( {
                //     data : response.data,
                //     is_updated: true
                // });
                console.log("inside call back");
                console.log(this.state.data);
                //console.log(this.state.data[0][0]);
                //console.log(self.state.is_updated);

                // self.forceUpdate(); // not working
                
                //self.handleStateChange(response.data);
                    
            })
            .catch(function (error) {
                console.log(error);
            })
            .then(function() {
                console.log('Fetch done');
            });
    }

    handleInput(e) {
        let value = e.target.value;

        this.setState (
            prevState => ( {
                ticker_r: value,
                valid_ticker: value.trim().split(' ').length > 2
            }),
            () => console.log(this.state.ticker_r)
        );

        //e.preventDefault();
    }

    handleInputStartDate(e) {
        let value = e.target.value;
        const todate = new Date();

        this.setState( 
            prevState => ( {
                start_date: value,
                // valid_startdate: ( Date.parse(value) >= Date.parse("2011-01-02") )&& (Date.parse(value) <= Date.parse("2023-03-30") ),
                valid_startdate: ( Date.parse(value) >= Date.parse("2011-01-02") )&& (Date.parse(value) < todate),
                date_prob_tockers: []
            }),
            () => console.log(this.state.start_date)
        );
    }

    handleInputEndDate(e) {
        let value = e.target.value;
        const todate = new Date();
        this.setState( 
            prevState => ( {
                end_date: value,
                // valid_enddate:  (Date.parse(value) > Date.parse(this.state.start_date) + 300000000 ) && ( Date.parse(value) <= Date.parse("2023-03-30") ),
                valid_enddate:  (Date.parse(value) > Date.parse(this.state.start_date) + 300000000 ) && ( Date.parse(value) < todate ),
                date_prob_tockers: []
            }),
            () => console.log(this.state.end_date)
        );
    }

    // handleSelect(e) {
    //     let value = e.target.value;
        
    //     this.setState( 
    //         prevState => ( {
    //             optimizer: value
    //         }),
    //         () => console.log(this.state.optimizer)
    //     );
    //     if (value == "MV_FN") {
    //         this.setState( 
    //             prevState => ( {
    //                 msg: "Mean Variance with Factor Neutal Portfolio using Fama-French facotrs : CMA, HML, MOM, RMW, SMB "
    //             }),
    //             () => console.log(this.state.msg)
    //         );
    //     } else if (value == "EW") {
    //         this.setState( 
    //             prevState => ( {
    //                 msg: "Simple Equal Weight "
    //             }),
    //             () => console.log(this.state.msg)
    //         ); 
    //     } else if (value == "MV") {
    //         this.setState( 
    //             prevState => ( {
    //                 msg: "Mean Variance without constraints"
    //             }),
    //             () => console.log(this.state.msg)
    //         ); 

    //     }

    // }
    // shouldComponentUpdate(newProps, newState) {
    //     console.log("shouldComponentUpdate: Should component update?");
    //     console.log(this.state.data);
    //     console.log(newState.data);
    //     if (this.state.data !== newState.data) {
    //       console.log("shouldComponentUpdate: Component should update!");
    //       return true;
    //     } else {
    //       //ReactDOM.unmountComponentAtNode(destination);
    //       console.log("shouldComponentUpdate: Component should not update!");
    //       return false;
    //     }
    // }


    render() {
        //const {showChart} = this.state.data;
        if (this.state.loading === 2) {  // data ready and display

            return ( 
                <div id="pf1"><h1>Tickers Clustering using Hierarchical Clustering</h1>
                    <div class="alert alert-secondary" role="alert">
                    <h5>Overview</h5>
                    <p>Portfolio construction using Herarchical Risk Parity approach</p>
                    <p>Steps</p>
                    <ul>
                        <li>Stage 1: Tree Clustering</li>
                        <li>Stage 2: Quasi-Diagonalization (to be added)</li>
                        <li>Stage 3: Recursive Bisection (to be added)</li>
                    </ul>
                    <h5>Universe</h5>
                        <p>2500+ stocks in U.S. market</p>
                        <p>from 2011-1-1 to 2023-3-21</p>

                        <p>Building Diversified Portfolios that Outperform Out-of-Sample - Marcos Lopez de Prado </p>
                    </div>
                    <div className="form1_input">
                        <form onSubmit={this.handleClick}>
                            <ChInput  
                                inputType={"text"}
                                title={"Tickers (separated by space)"}
                                name={"ticker_name"}
                                value = {this.state.ticker_r}
                                placeholder="Enter ticker" 
                                handleChange={this.handleInput}
                                // handlechange={this.handleInput}
                            />{" "}
                            {this.state.valid_ticker ? null : <div class="alert alert-danger" role="alert">
                                                            {"Enter the valid three or more tickers from SP500 "}
                                                            </div>
                            }
                            <ChInput  
                                inputType={"date"}
                                title={"Start Date"}
                                name={"start_name"}
                                value = {this.state.start_date}
                                placeholder="Input date" 
                                min = {"2011-01-02"}
                                max = {"2023-03-30"}
                                handleChange={this.handleInputStartDate}
                                // handlechange={this.handleInputStartDate}
                            />{" "}
                            {this.state.valid_startdate ? null : <div class="alert alert-danger" role="alert">
                                                            {"Enter the valid Start Date"}
                                                            </div>
                            }
                            {/* {this.state.date_prob_tockers==0 ? null : <div class="alert alert-danger" role="alert">
                                                            {"Data for " + this.state.date_prob_tockers +  " Not Available on the date you selected. Please select different start and/or end date."}
                                                            </div>
                            } */}
                            <ChInput  
                                inputType={"date"}
                                title={"End Date"}
                                name={"end_name"}
                                value = {this.state.end_date}
                                placeholder="Input date" 
                                handleChange={this.handleInputEndDate}
                                // handlechange={this.handleInputEndDate}
                            />{" "}
                            {this.state.valid_enddate ? null : <div class="alert alert-danger" role="alert">
                                                            {"Enter the valid End Date"}
                                                            </div>
                            }
                            
                            

                            {/* <label>
                            Select Optimization:
                            <select value={this.state.optimizer} onChange={this.handleSelect}>
                                <option value="EW">Equal Weight</option>
                                <option value="MV_FN">MV w/ Factor Neutral</option>
                            </select>
                            </label> */}
                            <ChButton
                                action={this.handleClick}
                                type={"primary"}
                                title={"Submit"}
                                style={buttonStyle}
                            />{" "}
                            {/* <div class="alert alert-primary" role="alert">
                            {this.state.msg}
                            </div>
                            {this.state.is_feasible ? null : <div class="alert alert-danger" role="alert">
                                                            {"The optimization is not feasible!"}
                                                            </div> 
                            } */}
                        </form>

                        {/* <button className='button' onClick={this.handleClick}>Test button</button> */}
                        {/* <div>{this.state.data}</div> */}
                        {/* <div>{this.state.data}</div> */}
                        {/* {this.state.is_updated ? "not updated yet" : "updated>>>>>>" } */}

                    </div>
                    
                    <div>
                        <h1>Stage 1 Clustering</h1>
                        {this.state.data.map((dd, index) =>
                            
                            <Chart_StockLine key={index} title_text={"group : " + (index+1)} 
                            data = {dd}/>,
                            // <Chart_StockLine  title_text={"title_text"} 
                            // data = {dd[0]}/>
                            )
                            
                        }

                         {/* <Chart_StockLine  title_text={this.state.groupname} 
                                 data = {this.state.data}/>  */}
                    </div>
                    <footer class="mastfoot mt-auto">
                            <div class="inner">
                                <p>© 2023 Copyright Chris IJ Hwang</p>
                            </div>
                            <p>DISCLAIMER: The information in this site is for the purpose of research and backtest only. We don't promise any future profits and don't take responsibility for any trading losses.
                            </p>
            </footer>
                </div>
            );

        } else if(this.state.loading === 1) { // loading
            return (
                <div id="pf1"><h1>Tickers Clustering using Hierarchical Clustering</h1>
                <div class="alert alert-secondary" role="alert">
                <h5>Overview</h5>
                    <p>Portfolio construction using Herarchical Risk Parity approach</p>
                    <p>Steps</p>
                    <ul>
                        <li>Stage 1: Tree Clustering</li>
                        <li>Stage 2: Quasi-Diagonalization (to be added)</li>
                        <li>Stage 3: Recursive Bisection (to be added)</li>
                    </ul>
                    <h5>Universe</h5>
                        <p>2500+ stocks in U.S. market</p>
                        <p>from 2011-1-1 to 2023-3-21</p>

                        <p>Building Diversified Portfolios that Outperform Out-of-Sample - Marcos Lopez de Prado </p>

                    </div>
                    <div className="form1_input">
                        <form onSubmit={this.handleClick}>
                            <ChInput  
                                inputType={"text"}
                                title={"Tickers (separated by space)"}
                                name={"ticker_name"}
                                value = {this.state.ticker_r}
                                placeholder="Enter ticker" 
                               handleChange={this.handleInput}
                                // handlechange={this.handleinput}
                            />{" "}
                            
                            <ChInput  
                                inputType={"date"}
                                title={"Start Date"}
                                name={"start_name"}
                                value = {this.state.start_date}
                                placeholder="Input date" 
                                min = {"2011-01-02"}
                                max = {"2023-03-30"}
                                handleChange={this.handleInputStartDate}
                            />{" "}
                            <ChInput  
                                inputType={"date"}
                                title={"End Date"}
                                name={"end_name"}
                                value = {this.state.end_date}
                                placeholder="Input date" 

                                handleChange={this.handleInputEndDate}
                            />{" "}
                            
                            {/* <label>
                            Select Optimization:
                            <select value={this.state.optimizer} onChange={this.handleSelect}>
                                <option value="EW">Equal Weight</option>
                                <option value="MV_FN">EW w/ Factor Neutral</option>
                            </select>
                            </label> */}
                            <ChButton
                                action={this.handleClick}
                                type={"primary"}
                                title={"Submit"}
                                style={buttonStyle}
                            />{" "}
                            {/* <div class="alert alert-primary" role="alert">
                            {this.state.msg}
                            </div> */}
                        </form>
                    </div>
                    <div align="center">
                        <br/>
                        <br/>
                        <br/>
                        <br/>

                        <Spinner animation="border" variant="success" >
                            
                        </Spinner>
                        <br/>
                        <br/>
                        <br/>
                        <br/>

                    </div>
                    <footer class="mastfoot mt-auto">
                            <div class="inner">
                                <p>© 2023 Copyright Chris IJ Hwang</p>
                            </div>
                            <p>DISCLAIMER: The information in this site is for the purpose of research and backtest only. We don't promise any future profits and don't take responsibility for any trading losses.
                            </p>
            </footer>
                </div>
                
            );
        }
         else { // not started
            return ( 
                <div id="pf1"><h1>Tickers Clustering using Hierarchical Clustering</h1>
                <div class="alert alert-secondary" role="alert">
                <h5>Overview</h5>
                    <p>Portfolio construction using Herarchical Risk Parity approach</p>
                    <p>Steps</p>
                    <ul>
                        <li>Stage 1: Tree Clustering</li>
                        <li>Stage 2: Quasi-Diagonalization (to be added)</li>
                        <li>Stage 3: Recursive Bisection (to be added)</li>
                    </ul>
                    <h5>Universe</h5>
                        <p>2500+ stocks in U.S. market</p>
                        <p>from 2011-1-1 to 2023-3-21</p>

                        <p>Building Diversified Portfolios that Outperform Out-of-Sample - Marcos Lopez de Prado </p>

                    </div>
                    <div className="form1_input">
                        <form onSubmit={this.handleClick}>
                            <ChInput  
                                inputType={"text"}
                                title={"Tickers (separated by space)"}
                                name={"ticker_name"}
                                value = {this.state.ticker_r}
                                placeholder="Enter ticker" 
                                handleChange={this.handleInput}
                                //handlechange={this.handleInput}
                            />{" "}
                            {this.state.valid_ticker ? null : <div class="alert alert-danger" role="alert">
                                                            {"Enter the valid three or more tickers from SP500 "}
                                                            </div>
                            }
                            {/* {this.state.no_data_tickers.length==0 ? null : <div class="alert alert-danger" role="alert">
                                                            {"Data Not available for " + this.state.no_data_tickers + ". Please use different Tickers."}
                                                            </div>
                            } */}
                            <ChInput  
                                inputType={"date"}
                                title={"Start Date"}
                                name={"start_name"}
                                value = {this.state.start_date}
                                placeholder="Input date" 
                                min = {"2011-01-02"}
                                max = {"2023-03-30"}
                                handleChange={this.handleInputStartDate}
                            />{" "}
                            {this.state.valid_startdate ? null : <div class="alert alert-danger" role="alert">
                                                            {"Enter the valid Start Date"}
                                                            </div>
                            }
                            {/* {this.state.date_prob_tockers==0 ? null : <div class="alert alert-danger" role="alert">
                                                            {"Data for " + this.state.date_prob_tockers +  " Not Available on the date you selected. Please select different start and/or end date."}
                                                            </div>
                            } */}
                            <ChInput  
                                inputType={"date"}
                                title={"End Date"}
                                name={"end_name"}
                                value = {this.state.end_date}
                                placeholder="Input date" 
                                handleChange={this.handleInputEndDate}
                            />{" "}
                            {this.state.valid_enddate ? null : <div class="alert alert-danger" role="alert">
                                                            {"Enter the valid End Date"}
                                                            </div>
                            }
                            
                            {/* <label>
                            Select Optimization:
                            <select value={this.state.optimizer} onChange={this.handleSelect}>
                                <option value="EW">Equal Weight</option>
                                <option value="MV_FN">MV w/ Factor Neutral</option>
                            </select>
                            </label> */}
                            {/* {this.state.valid_enddate ? null : <div class="alert alert-danger" role="alert">
                                                            {"Enter the valid End Date"}
                                                            </div>
                            } */}
                            <ChButton
                                action={this.handleClick}
                                type={"primary"}
                                title={"Submit"}
                                style={buttonStyle}
                            />{" "}

                            {/* <div class="alert alert-primary" role="alert">
                            {this.state.msg}
                            </div> */}
                            
                        </form>
                    </div>
                    <footer class="mastfoot mt-auto">
                            <div class="inner">
                                <p>© 2023 Copyright Chris IJ Hwang</p>
                            </div>
                            <p>DISCLAIMER: The information in this site is for the purpose of research and backtest only. We don't promise any future profits and don't take responsibility for any trading losses.
                            </p>
            </footer>
                </div>
            );
        }
    }
}

const buttonStyle = {
    margin: "10px 10px 10px 10px"
 };

export default HCAForm1;