import React, { Component } from 'react';
//https://jsfiddle.net/gh/get/library/pure/highcharts/highcharts/tree/master/samples/highcharts/demo/scatter-jitter 
import './App.css';
import axios from 'axios';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

class Chart_Scatter_Jitter extends React.Component {

    constructor(props) {
        super(props);
        

        this.state = {
            chart: {
                renderTo: this.props.renderTo,
                type: "scatter",
                zoomType: 'xy'
                
            },
            title: {
                text: this.props.title_text 
            },
            xAxis: {
                categories: this.props.categories
                // categories: ['Run 1', 'Run 2', 'Run 3', 'Run 4', 'Run 5']

            },
            yAxis: {
                title: {
                    text: this.props.y_title
                }
            },
            plotOptions: {
                scatter: {
                    showInLegend: false,
                    jitter: {
                        x: 0.3,
                        y: 0
                    },
                    marker: {
                        radius: 2,
                        symbol: 'circle'
                    },
                    tooltip: {
                        pointFormat: '{point.ticker} : {point.y:.4f}'
                    }
                }
            },
            // series: [
            //     {
            //         data : this.props.data
            //         // [ [4, 5], [2, 8] ....] 
            //     }
            // ]
            series: this.props.series

            // series: [{
            //     name: 'Consumer Staple',
            //     data:  [ {'ticker':"AA", "x":0, "y":0.32}, {'ticker':"BB", "x":0, "y":0.32}, ...]
            // }, {
            //     name: 'Run 2',
            //     data: getTestData(1)
            // }, {
            //     name: 'Run 3',
            //     data: getTestData(2)
            // }, {
            //     name: 'Run 4',
            //     data: getTestData(3)
            // }, {
            //     name: 'Run 5',
            //     data: getTestData(4)
            // }]
        }
        console.log(this.props.categories)
        console.log(this.props.series);

    }

    

    // shouldComponentUpdate(newProps, newState) {
    //     console.log("shouldComponentUpdate: Should component update?");
    //     console.log(this.state.series);
    //     console.log(newState.series);
    //     if (this.state.series !== newState.series) {
    //       console.log("shouldComponentUpdate: Component should update!");
    //       return true;
    //     } else {
    //       //ReactDOM.unmountComponentAtNode(destination);
    //       console.log("shouldComponentUpdate: Component should not update!");
    //       return false;
    //     }
    // }

    render() {
        return (
            <div >
                <HighchartsReact 
                highcharts={Highcharts} 
                options={this.state} />
            </div>

        )
    } 
}
export default Chart_Scatter_Jitter;