import React, { Component } from 'react';
//https://www.highcharts.com/demo/box-plot
//https://stackblitz.com/edit/highcharts-boxplot-zones?file=index.js
//https://jsfiddle.net/gh/get/library/pure/highcharts/highcharts/tree/master/samples/highcharts/demo/box-plot
//https://codesandbox.io/s/highcharts-react-simple-chart-forked-uhugsu?file=/demo.jsx
//https://api.highcharts.com/highcharts/plotOptions.boxplot



import './App.css';

import Highcharts from 'highcharts/highstock';
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsReact from 'highcharts-react-official';
HighchartsMore(Highcharts);

class Chart_BoxPlot extends React.Component {

    constructor(props) {
        super(props);
        // console.log(this.props.title_text);
        // console.log(this.props.xlabel);
        // console.log(this.props.data);
        console.log( this.props.data[0]['categories']);
        console.log(this.props.data[1]['data']);

        this.state = {
            chart: {
                type: 'boxplot',
                // inverted: true
               
            },
            title: {
                text: this.props.title_text

            },
            legend: {
                enabled: false
            },
            xAxis: {
                categories: this.props.data[0]['categories'],
                title: {
                    text: this.props.xlabel
                }
            },
            yAxis: {
                title: {
                    text: "Val"
                }
            },
            series: [{
                name: "Obs",
                data: this.props.data[1]['data'],
               
                tooltip: {
                    headerFormat: '<em>Factor {point.key}</em><br/>'
                } 
            }]
            // from reference: https://jsfiddle.net/gh/get/library/pure/highcharts/highcharts/tree/master/samples/highcharts/demo/box-plot
            // chart: {
            //     type: 'boxplot'
            // },
        
            // title: {
            //     text: 'Highcharts Box Plot Example'
            // },
        
            // legend: {
            //     enabled: false
            // },
        
            // xAxis: {
            //     categories: ['2', '3', '4', 'EV'],
            //     title: {
            //         text: 'Experiment No.'
            //     }
            // },
        
            // yAxis: {
            //     title: {
            //         text: 'Observations'
            //     }
            // },
        
            // series: [{
            //     name: 'Observations',
            //     data: [
                    
            //         [733, 853, 939, 980, 1080],
            //         [714, 762, 817, 870, 918],
            //         [724, 802, 806, 871, 950],
            //         [834, 836, 864, 882, 910]
            //     ],
            //     tooltip: {
            //         headerFormat: '<em>Experiment No {point.key}</em><br/>'
            //     }
            // }]
        }
        // console.log(this.state);

    }

    

    // shouldComponentUpdate(newProps, newState) {
    //     console.log("shouldComponentUpdate: Should component update?");
    //     console.log(this.state.series);
    //     console.log(newState.series);
    //     if (this.state.series !== newState.series) {
    //       console.log("shouldComponentUpdate: Component should update!");
    //       return true;
    //     } else {
    //       //ReactDOM.unmountComponentAtNode(destination);
    //       console.log("shouldComponentUpdate: Component should not update!");
    //       return false;
    //     }
    // }

    render() {
        return (
            <div >
                <HighchartsReact 
                highcharts={Highcharts} 
                constructorType={'chart'}
                options={this.state} />
            </div>

        )
    } 
}
export default Chart_BoxPlot;